import { configureStore } from '@reduxjs/toolkit';
import userReducer, { loadUser } from './Slices/AuthSlice';
import usersReducer, { getUsers } from './Slices/usersSlice';
import manuscriptsReducer, { getManuscripts } from './Slices/manuscriptsSlice';
import journalsReducer, { getJournals } from './Slices/journalsSlice';
import { usersApi } from './UsersApi';
import journalReducer, { loadJournal } from './Slices/journalSlice';
import myManuscriptsReducer,{ loadMyManuscripts } from './Slices/myManuscriptsSlice';
import manuscriptReducer, { loadManuscript } from './Slices/manuscriptSlice';


export const store = configureStore({
  reducer: {
    auth:userReducer,
    users:usersReducer,
    manuscripts:manuscriptsReducer,
    journals:journalsReducer,
    myManuscripts:myManuscriptsReducer,
    manuscript:manuscriptReducer,
    journal:journalReducer,
    [usersApi.reducerPath]:usersApi.reducer
  },
  middleware:(getDefaultMiddleware)=>(
    getDefaultMiddleware().concat(usersApi.middleware)
  ),
  devTools: process.env.NODE_ENV !== 'production',
})

store.dispatch(loadManuscript())
store.dispatch(loadUser())
store.dispatch(loadJournal())
store.dispatch(getUsers())
store.dispatch(getJournals())
store.dispatch(getManuscripts())
store.dispatch(loadMyManuscripts())