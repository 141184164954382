import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {jwtDecode} from "jwt-decode";
import axios from 'axios'
import { setAuthHeader } from "../Api";
import { url2 } from "../Api";

const initialState = {
    user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):null,
    token:localStorage.getItem('token')?JSON.parse(localStorage.getItem('token')):null,registerStatus:'',loginStatus:'',
    registerError:'',loginError:'',userLoaded:false,getUserStatus:'',getUserError:''
}

export const registerUser = createAsyncThunk(
    'auth/registerUser',
    async (user,{rejectWithValue})=>{
        try {
            const response = await axios.post(`${url2}/register`,{
            secondaryName:user.secondaryName,
            middleName:user.middleName,
            degree:user.degree,
            nickName:user.nickName,
            phone1:user.phone1,
            phone2:user.phone2,
            confirmedPassword:user.confirmedPassword,
            firstName:user.firstName,
            lastName:user.lastName,
            orcidDef:user.orcidDef,
            logger:user.logger,
            position:user.position,
            institution1:user.institution1,
            department:user.department,
            institution2:user.institution2,
            streetAddress:user.streetAddress,
            city:user.city,
            state:user.state,
            code:user.code,
            country:user.country,
            classification:user.classification,
            userName:user.userName,
            password:user.password,
            fax:user.fax,
            orcid:user.orcid,
            email:user.email,
            title:user.title,
            info:user.info,
            pub:user.currentItem?.pub
            })
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async(user,{rejectWithValue})=>{
        try {
            const response = await axios.post(`${url2}/login`,{
                email:user.email,
                password:user.password,
                pub:user.pub
            })
            localStorage.setItem('user',JSON.stringify(response.data.user))
            localStorage.setItem('token',JSON.stringify(response.data.token))
             return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const getUser = createAsyncThunk(
    'auth/getUser',
    async(id,{rejectWithValue})=>{
        try {
            const response = await axios.get(`${url2}/profile`,setAuthHeader())
            localStorage.setItem('profile',JSON.stringify(response.data))
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        loadUser(state,action){
            const token = action.payload
            const user = action.payload;
            if(token && user){
                return {
                    ...state,
                    user:user,
                    token:token,
                    loadUser:true
                }
            }

        },
        logOut:(state)=>{
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('logger')
            localStorage.removeItem('manuscript-data')
localStorage.removeItem('items')
localStorage.removeItem('saveManuscriptAbstract')
localStorage.removeItem('saveManuscriptTitle')
localStorage.removeItem('level')
localStorage.removeItem('level-status')
localStorage.removeItem('opposers')
localStorage.removeItem('logger')
localStorage.removeItem('type')
localStorage.removeItem('saveManuscriptAuthors')
localStorage.removeItem('saveManuscriptFunding')
localStorage.removeItem('my-manuscripts')

            return {
                ...state,
                user:null,
                token:null,
                loadUser:false
            }
        },
    },
    extraReducers:(builder)=>{
        builder.addCase(registerUser.pending,(state,action)=>{
            return {...state,registerStatus:'pending'}
        })
        builder.addCase(registerUser.fulfilled,(state,action)=>{
            if(action.payload){
                const user = jwtDecode(action.payload)
                return {...state,token:action.payload,user:user,registerStatus:'success'}
            }else{
                return state
            }
        })
        builder.addCase(registerUser.rejected,(state,action)=>{
            return {...state,registerStatus:'rejected',registerError:action.payload}
        })
        builder.addCase(loginUser.pending,(state,action)=>{
            return {...state, loginStatus:'pending'}
        })
        builder.addCase(loginUser.fulfilled,(state,action)=>{
            if(action.payload){
                localStorage.setItem('token',JSON.stringify(action.payload.token))
                localStorage.setItem('user',JSON.stringify(action.payload.user))
                return {...state,token:action.payload.token,user:action.payload.user,loginStatus:'success'}
            }else{
                return state
            }
        })
        builder.addCase(loginUser.rejected,(state,action)=>{
            return {...state,loginStatus:'rejected',loginError:action.payload}
        })
        builder.addCase(getUser.pending,(state,action)=>{
            return {...state,getUserStatus:'pending'}
        })
        builder.addCase(getUser.fulfilled,(state,action)=>{
            if(action.payload){
                // localStorage.setItem('token')
                return {...state,user:jwtDecode(),token:action.payload,getUserStatus:'success'}
            }else{
                return state
            }
        })
        builder.addCase(getUser.rejected,(state,action)=>{
            return {...state,getUserStatus:'rejected',getUserError:action.payload}
        })
    }
})

export const {loadUser, logOut} = authSlice.actions;
export default authSlice.reducer;