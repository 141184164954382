import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { url2 } from "../Api";

const initialState = {
    items:localStorage.getItem('journals')?JSON.parse(localStorage.getItem('journals')):[],
    status:''
}
export const getJournals = createAsyncThunk(
    'journals/getJournals',
    async()=>{
        try {
          const response = await axios.get(`${url2}/books`)  
          localStorage.setItem('journals',JSON.stringify(response.data.resp))
          return response.data.resp
        } catch (error) {
            return 
        }
    }
)

const journalsSlice = createSlice({
    name:'journals',
    initialState,
    reducers:{
        loadJournals(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:{
        [getJournals.pending]:(state,action)=>{state.status = 'pending'},
        [getJournals.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getJournals.rejected]:(state,action)=>{state.status='rejected'}
}
})

export const{loadJournals} = journalsSlice.actions;
export default journalsSlice.reducer;