import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { url2 } from "../Api";

const initialState = {
    items:[],
    status:[]
}
export const getManuscripts = createAsyncThunk(
    'manuscripts/getManuscripts',
    async()=>{
        try {
          const response = await axios.get(`${url2}/manuscripts`)  
          return response.data.resp
        } catch (error) {
            return 
        }
    }
)
const manuscriptsSlice = createSlice({
    name:'manuscripts',
    initialState,
    reducers:{},
    extraReducers:{
        [getManuscripts.pending]:(state,action)=>{state.status = 'pending'},
        [getManuscripts.fulfilled]:(state,action)=>{state.items=action.payload;state.status='success'},
        [getManuscripts.rejected]:(state,action)=>{state.status='rejected'}
}
})

export default manuscriptsSlice.reducer;