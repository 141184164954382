import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './AltContactInfo.css'
import siteInfo from '../../../shopping/images/springer-ecomm-header-logo.png'

import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaInfoCircle, FaSpinner, FaTimes } from 'react-icons/fa';
import { url, url2 } from '../../../Redux/Api';
import { EditorialHeader } from './EditorialHeader';


const AltContactInfo = props => {
const[regState, setRegState] = useState({email:'',firstName:'',lastName:''});

const[userName, setUserName] = useState('')
const[password, setPassword] = useState('')
const[fax, setFax] = useState('')
const[orcid, setOrcid] = useState('')
const[email, setEmail] = useState(regState?.email)
const[title, setTitle] = useState('')
const[info, setInfo] = useState('')
const[secondaryName, setSecondaryName] = useState('')
const[middleName, setMiddleName] = useState('')
const[degree, setDegree] = useState('')
const[nickName, setNickName] = useState('')
const[phone1, setPhone1] = useState('')
const[phone2, setPhone2] = useState('')
const[confirmedPassword, setConfirmedPassword] = useState('')
const[firstName, setFirstName] = useState(regState?.firstName)
const[lastName, setLastName] = useState(regState?.lastName)
const[orcidDef, setOrcidDef] = useState(false)
const[logger, setLogger] = useState('');
const[position, setPosition] = useState('');
const[institution1, setInstitution1] = useState('');
const[department, setDepartment] = useState('');
const[institution2, setInstitution2] = useState('');
const[streetAddress, setStreetAddress] = useState('');
const[city, setCity] = useState('');
const[state, setState] = useState('');
const[code, setCode] = useState('');
const[country, setCountry] = useState('');
const[newState, setNewState] = useState({status:'',data:''});
const[loading, setLoading] = useState(false);
const[error, setError] = useState({err:'',hint:''});
// const journal = {number:11524,overview1:'The Journal of Urban Health is the premier and authoritative source of rigorous analyses to advance the health and well-being of people in cities. The Journal provides a platform for interdisciplinary exploration of the evidence base for the broader determinants of health and health inequities needed to strengthen policies, programs, and governance for urban health.',overview2:'The Journal publishes original data, case studies, commentaries, book reviews, executive summaries of selected reports, and proceedings from important global meetings. It welcomes submissions presenting new analytic methods, including systems science approaches to urban problem solving. Finally, the Journal provides a forum linking scholars, practitioners, civil society, and policy makers from the multiple sectors that can influence the health of urban populations.'}
const navigate = useNavigate();

useEffect(()=>{
setRegState(JSON.parse(localStorage.getItem('RegState')))
},[])
const {pub} = useParams();
const[currentItem, setCurrentItem] = useState(null)

async function name() {
    await fetch(`${url2}/books`,{
        mode:'cors',
        method:'get',
        headers:{
            'Content-Type':'application/json'
        }
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            const currentPub = JSON.parse(localStorage.getItem('currentPub'))?JSON.parse(localStorage.getItem('currentPub')):resp.resp[0]?.pub
            const book = resp.resp.filter(item=>item.pub===currentPub.toString())
            setCurrentItem(book[0])
        }
    }).catch(err=>setError(err.message))
}
name();

const handleLevelTwo = ()=>{
    if(firstName?.length>1&&lastName?.length>1){
      localStorage.setItem('regState',JSON.stringify({
        secondaryName,middleName,degree,nickName,phone1,phone2,confirmedPassword,firstName,lastName,
            orcidDef,logger,position,institution1,department,institution2,streetAddress,city,state,code,country,classification,
            userName,password,fax,orcid,email,title,info
        }))
      navigate(`/murh/${currentItem?.title}/default.aspx-3`)
    }
  }
  const [selectedOne, setSelectedOne] = useState(Date);
  const [selectedTwo, setSelectedTwo] = useState(Date);
let displayOne = null
  
  let footerOne = '';
  let footerTwo = '';
  if (selectedOne) {
    footerTwo = <p>{format(selectedOne, 'PP')}.</p>;
  }
  if (selectedTwo) {
    footerTwo = <p>{format(selectedTwo, 'PP')}.</p>;
  }
  const[dateOne, setDateOne] = useState(false)
  const[dateTwo, setDateTwo] = useState(false)

const handleRegistration = async (e)=>{
    e.preventDefault()
    setLoading(true)
    await fetch(`${url}/register`,{
        mode:'cors',
        method:'Post',
        headers:{
            'Content-Type':'application/json',
        },
        body:JSON.stringify({
            secondaryName,middleName,degree,nickName,phone1,phone2,confirmedPassword,firstName,lastName,
            orcidDef,logger,position,institution1,department,institution2,streetAddress,city,state,code,country,classification,
            userName,password,fax,orcid,email,title,info})
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            setError({err:"",hint:""})
            newState({status:'success',data:resp.resp})
            localStorage.setItem('user',JSON.stringify(resp?.user))
            setLoading(false)
            return navigate('/login')
        }else if(resp.err){
            setLoading(false)
            newState({status:'failed',data:resp.err})
            return setError({err:resp.err,hint:resp.hint})
        }
    }).catch(err=>{setLoading(false);setError({err:err.message})})
}
    const[open, setOpen] = useState(false);
    const[classification,setClassification] = useState([])
    useEffect(()=>{
        localStorage.setItem('classification',JSON.stringify(classification))
    },[classification])

return(
<>
<div className='alt-contact-info-main'>
<EditorialHeader/>
    <div className='editorial-main-register-site-body-top'>
        <h2 className='editorial-main-register-site-text-title'>Alternate Contact Information</h2>
        <p className='editorial-main-register-site-text-content'>Below you  may enter alternate contact information. You must enter a time period for which this contact information is valid. Required fields have an asterisk next to the label</p>
    </div>
    <div className='editorial-main-register-form'>
    <div className='choose-register-method-top'>
    <span className='main-register-text-method-top'>Insert Special Character</span>
    </div>
    <div className='choose-register-method'>
    <span className='main-register-text-method'>Alternate Contact Informationis Valid</span>
    </div>
    <div className='main-register-input-inner-wrapper'>
        
    <div className='main-register-input-and-text-wrapper'>
    <input type='text' value={selectedOne} className='date-inpute' />
    <div className='main-register-input-wrapper'><div className='main-register-text-input-wrapper'><span className='main-register-required-text'>From</span>
    </div>
    <select onClick={()=>setDateOne(true)}>
        <option></option>
    </select>
    <div className='date-wrapper'>
    {dateOne&&<DayPicker
      mode="single"
      selected={selectedOne}
      onSelect={setSelectedOne}
      onDayMouseLeave={()=>{if(selectedOne.length>0){setDateOne(false)}}}
    />}
    </div>
        </div>
    <div className='main-register-input-wrapper'>
    <input type='text' value={selectedTwo} className='date-inpute' />
        <div className='main-register-text-input-wrapper'><span className='main-register-required-text'>To</span></div>
    
    <select onClick={()=>setDateTwo(true)}>
        <option></option>
    </select>
    <div className='date-wrapper'>
    {
        dateTwo&&footerOne.length<1&&<DayPicker
        mode="single"
        selected={selectedTwo}
        onSelect={setSelectedTwo}
        onDayMouseLeave={()=>{if(selectedTwo.length>0){setDateTwo(false)}}}
      />
    }
    </div>
    </div>
    </div>
    </div>
</div>
<div className='editorial-main-register-form'>
    <div className='choose-register-method'>
    <span className='main-register-text-method'>Personal information</span>
    </div>
    <div className='choose-register-method-top'>
    <span className='main-register-text-method-top'>Insert Special Character</span>
    </div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Title *</span></div><input type='text' onChange={(e)=>setTitle(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Given/First Name *</span></div><input type='text' value={regState?.firstName} onChange={(e)=>setFirstName(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Secondary Given/First Name </span></div><input type='text' onChange={(e)=>setSecondaryName(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Middle Name *</span></div><input type='text' onChange={(e)=>setMiddleName(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Family/Last Name *</span></div><input type='text' value={regState?.lastName} onChange={(e)=>setLastName(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Secondary Family/Last Name</span></div><input type='text' onChange={(e)=>setFirstName(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Degree</span></div><input type='text' onChange={(e)=>setDegree(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>(Ph.D., M.D., etc.)</span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Preferred Name </span></div><input type='text' onChange={(e)=>setNickName(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>(nickname)</span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Primary Phone</span></div><input type='text' onChange={(e)=>setPhone1(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>(including country code)</span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Primary Phone</span></div><input type='text' onChange={(e)=>setPhone2(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>(including country code)</span></div>
    <div className='main-register-inner-form'>
        <div className='main-register-inner-form-radios-container'>
        <span className='main-register-inner-form-radios-text'>Secondary Phone is for</span>
        </div>
        <div className='main-register-inner-form-radios-items-container'>
        <div className='phone-type-wrapper'>
<span className='phone-type-text'>  Mobile</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Beeper</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Home</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Work</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Admin.</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Asst.</span><input type='radio'/>
</div>
        </div>
    
    </div>
<div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Fax Number</span></div><input type='text' onChange={(e)=>setFax(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>(including country code)</span></div>
<div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>E-mail Address*</span></div><input type='email' value={regState?.email} onChange={(e)=>setEmail(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>(including country code)</span></div>
<p className='main-register-info-personal'>
    If entering more than one e-mail address, use a semi-colon between each address (e.g., joe@thejournal.com;joe@yahoo.com) Entering a second e-mail address from a different e-mail provider decreases the chance that SPAM filters will trap e-mails sent to you from online systems. Read More.
    </p>
    <div className='main-register-inner-form'>
        <div className='main-register-inner-form-radios-container'>
        <span className='main-register-inner-form-radios-text'>Preferred Contact Method  *</span>
        </div>
        <div className='main-register-inner-form-radios-items-container'>
        <div className='phone-type-wrapper'>
<span className='phone-type-text'>  E-mail</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Fax</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Post Mail</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Telephone</span><input type='radio'/>
</div>
        </div>
    
    </div>
<div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Orcid</span></div><input type='text' onChange={(e)=>setOrcid(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>Fetch/Register</span></div>
<a href='https://info.orcid.org/what-is-orcid/' className='what-is-orcid' onMouseEnter={()=>setOrcidDef(true)} onMouseLeave={()=>setOrcidDef(false)}>What is Orcid?</a>
{orcidDef&&<span className='ordic-display'>
    An ORCID iD is an Alphanumeric code that uniquely identifies an academic author. It is a 16-digits number, in the format 0000-0000-0000-000x. Click for more information.
    </span>}
</div>
    <div className='editorial-main-register-form'>
    <div className='choose-register-method'>
    <span className='main-register-text-method'>Areas of Interest or Expertise</span>
    </div>
    <p className='main-register-form-info-box'>
    Please indicate your areas of expertise either by selecting from the pre-defined list using the "Select Personal Classifications" button or by adding your own keywords individually using the "New Keyword" field and associated "Add" button.
    </p>
    {/* </div> */}
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Personal Classifications  *</span></div><span className='main-register-inner-form-right'>(None Selected)
</span></div>
<button className='main-register-btn' onClick={(e)=>{e.preventDefault(); setOpen(true)}}>Select Personal Classifications</button>

<span className='classifications'>Select {classification?.length+1}+ Classifications</span>
</div>
<div className='editorial-main-register-form'>
    <div className='choose-register-method'>
    <span className='main-register-text-method'>Institution Related Information</span>
    </div>
    <div className='choose-register-method-top'>
    <span className='main-register-text-method-top'>Insert Special Character</span>
    </div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Position</span></div><input type='text' onChange={(e)=>setPosition(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Institution *</span></div><input type='text' onChange={(e)=>setInstitution1(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>Start typing to display potentially matching institutions. <FaInfoCircle className='position-icon' onMouseEnter={()=>setInfo(true)} onMouseLeave={()=>setInfo(false)} /></span></div>
    {
        info&&<div className='institution-display'>
<h3 className='institution-display-title'>Select an institution from the list</h3>
<p className='institution-display-instruction'>Click an institution in the list to select it. It the correct institution cannot be found in the list, try the following to show different results:</p>
<ul>
    <li  className='institution-display-list'>Type the English variant of the institution, city, or country</li>
    <li  className='institution-display-list'>Type a different identifier, e.g., acity</li>
    <li  className='institution-display-list'>Type the full name rather than an acronym</li>
</ul>
        </div>
    }
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Department</span></div><input type='text' onChange={(e)=>setDepartment(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Secondary Institution </span></div><input type='text' onChange={(e)=>setInstitution2(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Street Address</span></div><input type='text' onChange={(e)=>setStreetAddress(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'></span></div><input type='text' className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'></span></div><input type='text' className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'> </span></div><input type='text' className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'></span></div><input type='text' className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'></span></div><input type='text' className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'></span></div><input type='text' className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>City</span></div><input type='text' onChange={(e)=>setCity(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>State or Province</span></div><input type='text' onChange={(e)=>setState(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Zip or Postal Code </span></div><input type='text' onChange={(e)=>setCode(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Country or Region  * </span></div><input type='text' onChange={(e)=>setCountry(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    
    <div className='main-register-inner-form'>
        <div className='main-register-inner-form-radios-container'>
        <span className='main-register-inner-form-radios-text'>Address is for  *</span>
        </div>
        <div className='main-register-inner-form-radios-items-container'>
        <div className='phone-type-wrapper'>
<span className='phone-type-text'>  Work</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Home</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Other</span><input type='radio'/>
</div>
            </div>
    
    </div>
    <div className='main-register-inner-form'>
        <div className='main-register-inner-form-radios-container'>
        <span className='main-register-inner-form-radios-text'>Available as a Reviewer?*</span>
        </div>
        <div className='main-register-inner-form-radios-items-container'>
        <div className='phone-type-wrapper'>
<span className='phone-type-text'>  Yes</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  No</span><input type='radio'/>
</div>
            </div>
    
    </div>
</div>
{
    error.err?.length>0||error.err?.length>0&&<>
    <span>{error.err?.length>0&&error.err}</span>
    <span>{error.hint?.length>0&&error.hint}</span>
    </>
  }
{
    loading?<FaSpinner />:<button className='main-register-btn' onClick={handleLevelTwo}>Continue {'>>'}</button>
}
    </div>
</>
)
};

AltContactInfo.propTypes = {};

export { AltContactInfo };