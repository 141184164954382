import React, {useEffect, useInsertionEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import './MainRegister.css'
import siteInfo from '../../../shopping/images/springer-ecomm-header-logo.png'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaInfoCircle, FaSpinner, FaTimes } from 'react-icons/fa';
import { url, url2 } from '../../../Redux/Api';
import { useSelector } from 'react-redux';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { EditorialHeader } from './EditorialHeader';

const UpdateInfo = props => {
const regState = JSON.parse(localStorage.getItem('regState'))
const[userName, setUserName] = useState('')
const[password, setPassword] = useState('')
const[fax, setFax] = useState('')
const[orcid, setOrcid] = useState('')
const[email, setEmail] = useState(regState?.email)
const[title, setTitle] = useState('')
const[info, setInfo] = useState('')
const[secondaryName, setSecondaryName] = useState('')
const[middleName, setMiddleName] = useState('')
const[degree, setDegree] = useState('')
const[nickName, setNickName] = useState('')
const[phone1, setPhone1] = useState('')
const[phone2, setPhone2] = useState('')
const[confirmedPassword, setConfirmedPassword] = useState('')
const[firstName, setFirstName] = useState(regState?.firstName)
const[lastName, setLastName] = useState(regState?.lastName)
const[orcidDef, setOrcidDef] = useState(false)
const[logger, setLogger] = useState('');
const[position, setPosition] = useState('');
const[institution1, setInstitution1] = useState(null);
const[department, setDepartment] = useState('');
const[institution2, setInstitution2] = useState(null);
const[streetAddress, setStreetAddress] = useState('');
const[city, setCity] = useState('');
const[state, setState] = useState('');
const[code, setCode] = useState('');
const[country, setCountry] = useState(null);
const[newState, setNewState] = useState({status:'',data:''});
const[loading, setLoading] = useState(false);
const[error, setError] = useState({err:'',hint:''});
const[passEq, setPassEq] = useState(false);
const[institutions, setInstitutions] = useState([]);
const navigate = useNavigate();

const currentItem = useSelector(state=>state.journal.journal)

  const options = useMemo(() => countryList().getData(), [])

  const changeHandler = value => {
    setCountry(value)
  }
const[show,setShow] = useState('form');

const comparePasswords = ()=>{
 if(password.length>0&&confirmedPassword.length>0){
    if(password===confirmedPassword){
        if(open){
            setShow('specifications')
        }else{
            setShow('form')
        }
    }else{
        setShow('pass-eq')
    }
 }
}

useEffect(()=>{
    async function fetchData(){
        await fetch(`${url2}/institutions`,{
            method:'get',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            }
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                return setInstitutions(resp.resp)
            }else if(resp.err){
                return setNewState({status:'failed',data:resp.err})
            }
        }).catch(err=> setNewState({status:'failed',data:err.message}))
    }
    fetchData()
},[])
const[searchedInstitutions, setSearchedInstitutions] = useState([]);
const[institutionSearch, setInstitutionSearch] = useState('');

useEffect(()=>{
    const val = institutions.filter(item=>item?.name?.toLowerCase().includes(institutionSearch?.toLowerCase()))
setSearchedInstitutions(val);
},[institutionSearch,institutions])
const handleRegistration = ()=>{
 localStorage.setItem('regState',JSON.stringify({
    nickName,secondaryName,middleName,degree,nickName,phone1,phone2,confirmedPassword,firstName,lastName,
    orcidDef,logger,position,institution1,department,institution2,streetAddress,city,state,code,country,classification,
    userName,password,fax,orcid,email,title,info,pub:currentItem?.pub
 }))
 return navigate(`/${currentItem?.acronym?.toLowerCase()}/default.aspx2/checked`);
}

const[open, setOpen] = useState(false);
const[classification,setClassification] = useState([]);

useEffect(()=>{
        localStorage.setItem('classification',JSON.stringify(classification))
},[classification])

return(
<>
<div className={show==='specifications'?'editorial-register-modal':'editorial-register-modal-hidden'}>
<EditorialHeader/>
    <span onClick={()=>setOpen(false)} className='go-back'>{'<<'} Go back to continue</span>
    <select value={classification} onChange={e=>classification.push(e.target.value)} className='classification-select'>
<option value='psychology'>Psychology</option>
<option value='public-health'>public health</option>
<option value='environmental-health'>environmental health</option>
<option value='public-policy'>public policy</option>
<option value='other'>Other</option>
    </select>
</div>
    <div className={show==='form'?'register-main':'editorial-register-main-hidden'} >
    <div className='register-site-body-left'>
    <h1 className='editorial-register-site-body-left-title'>Update details</h1>
   <p className='editorial-register-site-body-left-content'>
   {/* To register to use the Editorial Manager system, please enter the requested information. Required fields have a * next to the label. Upon successful registration, you will be sent an e-mail with instructions to verify your registration. */}
   </p>
    </div>
        <div className='main-register-site-body-container'>
        <div className='editorial-main-register-site-body'>
    <div className='editorial-main-register-form' style={{paddingTop:'30px'}}>
    <div className='choose-register-method-top'>
    <span className='main-register-text-method-top'>Insert Special Character</span>
    </div>
    <div className='choose-register-method'>
    <span className='main-register-text-method'>Login details</span>
    </div>
    <div className='main-register-info-inner-wrapper'>
    <p className='main-register-form-info'>
    The username you choose must be unique within the system.
If the one you choose is already in use, you will be asked for another.
    </p>
    </div>
    <div className='main-register-input-inner-wrapper'>
        
    <div className='main-register-input-and-text-wrapper'>
    
    <div className='main-register-input-wrapper'><div className='main-register-text-input-wrapper'><span className='main-register-required-text'>Enter preferred user name*</span></div><input required type='text' onChange={(e)=>setUserName(e.target.value)} className='main-editorial-login-input' /></div>
    <div className='main-register-input-wrapper'><div className='main-register-text-input-wrapper'><span className='main-register-required-text'>Password *</span></div><input required type='password' onChange={(e)=>setPassword(e.target.value)} className='main-editorial-login-input' /></div>
    <div className='main-register-input-wrapper'><div className='main-register-text-input-wrapper'><span className='main-register-required-text'>Re-type Password *</span></div><input required type='password' onBlur={comparePasswords} onChange={(e)=>setConfirmedPassword(e.target.value)} className='main-editorial-login-input' /></div>
    </div>
    </div>
</div>
</div>
<div className='editorial-main-register-site-body'>
    
    <div className='editorial-main-register-form' style={{paddingTop:'30px'}}>
    <div className='choose-register-method'>
    <span className='main-register-text-method'>Personal information</span>
    </div>
    <div className='choose-register-method-top'>
    <span className='main-register-text-method-top'>Insert Special Character</span>
    </div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Title *</span></div><input type='text' required onChange={(e)=>setTitle(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Given/First Name *</span></div><input required type='text' value={regState?.firstName} onChange={(e)=>setFirstName(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>Middle Name </span></div><input type='text' onChange={(e)=>setMiddleName(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Family/Last Name *</span></div><input required type='text' value={regState?.lastName} onChange={(e)=>setLastName(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    {/* <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Secondary Given/First Name </span></div><input type='text' onChange={(e)=>setSecondaryName(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div> */}
    
    
    {/* <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Secondary Family/Last Name</span></div><input type='text' onChange={(e)=>setFirstName(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div> */}
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>Degree</span></div><input type='text' onChange={(e)=>setDegree(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>(Ph.D., M.D., etc.)</span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>Preferred Name </span></div><input type='text' onChange={(e)=>setNickName(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>(nickname)</span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>Telephone Phone Number</span></div><input type='text' onChange={(e)=>setPhone1(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>(including country code)</span></div>
    {/* <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>Primary Phone</span></div><input type='text' onChange={(e)=>setPhone2(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>(including country code)</span></div> */}
    <div className='main-register-inner-form'>
        <div className='main-register-inner-form-radios-container'>
        <span className='main-register-inner-form-radios-text'>Secondary Phone is for</span>
        </div>
        <div className='main-register-inner-form-radios-items-container'>
        <div className='phone-type-wrapper'>
<span className='phone-type-text'>  Mobile</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Beeper</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Home</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Work</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Admin.</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Asst.</span><input type='radio'/>
</div>
        </div>
    
    </div>
<div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>Fax Number</span></div><input type='text' onChange={(e)=>setFax(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>(including country code)</span></div>
<div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>E-mail Address*</span></div><input type='email' value={regState?.email} onChange={(e)=>setEmail(e.target.value)} className='main-register-inner-form-middle' required/><span className='main-register-inner-form-right'></span></div>
<p className='main-register-info-personal'>
    If entering more than one e-mail address, use a semi-colon between each address (e.g., joe@thejournal.com;joe@yahoo.com) Entering a second e-mail address from a different e-mail provider decreases the chance that SPAM filters will trap e-mails sent to you from online systems. Read More.
    </p>
    {/* <div className='main-register-inner-form'>
        <div className='main-register-inner-form-radios-container'>
        <span className='main-register-inner-form-radios-text'>Preferred Contact Method  *</span>
        </div>
        <div className='main-register-inner-form-radios-items-container'>
        <div className='phone-type-wrapper'>
<span className='phone-type-text'>  E-mail</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Fax</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Post Mail</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Telephone</span><input type='radio'/>
</div>
        </div>
    
    </div> */}
<div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>Orcid</span></div><input type='text' onChange={(e)=>setOrcid(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'>Fetch/Register</span></div>
<a href='https://info.orcid.org/what-is-orcid/' className='what-is-orcid' onMouseEnter={()=>setOrcidDef(true)} onMouseLeave={()=>setOrcidDef(false)}>What is Orcid?</a>
{orcidDef&&<span className='ordic-display'>
    An ORCID iD is an Alphanumeric code that uniquely identifies an academic author. It is a 16-digits number, in the format 0000-0000-0000-000x. Click for more information.
    </span>}
</div>
<div className='editorial-register-site-body-right'></div>
</div>
<div className='editorial-main-register-site-body'>
    
    <div className='editorial-main-register-form' style={{paddingTop:'30px'}}>
    <div className='choose-register-method'>
    <span className='main-register-text-method'>Institution Related Information</span>
    </div>
    <div className='choose-register-method-top'>
    <span className='main-register-text-method-top'>Insert Special Character</span>
    </div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>Position</span></div><input type='text' onChange={(e)=>setPosition(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Institution *</span></div>
    <input type='text' placeholder={institution1&&institution1} onChange={(e)=>setInstitutionSearch(e.target.value)} className='main-register-inner-form-middle' />
    <select type='text' onChange={(e)=>setInstitution1(e.target.value)} className='main-register-inner-form-middle'>
        {searchedInstitutions.length>0&&
            searchedInstitutions.map((item,index)=>{
                return(
                    <option className='institution-option' style={{color:'green'}} key={index}>{`${item?.name},${item?.address},${item?.country}`}</option>
                )
            })
        }
    </select>
    <span className='main-register-inner-form-right'>Start typing to display potentially matching institutions. <FaInfoCircle className='position-icon' onMouseEnter={()=>setInfo(true)} onMouseLeave={()=>setInfo(false)} /></span></div>
    
    {
        info&&<div className='institution-display'>
<h3 className='institution-display-title'>Select an institution from the list</h3>
<p className='institution-display-instruction'>Click an institution in the list to select it. It the correct institution cannot be found in the list, try the following to show different results:</p>
<ul>
    <li  className='institution-display-list'>Type the English variant of the institution, city, or country</li>
    <li  className='institution-display-list'>Type a different identifier, e.g., acity</li>
    <li  className='institution-display-list'>Type the full name rather than an acronym</li>
</ul>
        </div>
    }
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>Department</span></div><input type='text' onChange={(e)=>setDepartment(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    {/* <input type='text' placeholder={institution2&&institution2} onChange={(e)=>setInstitutionSearch(e.target.value)} className='main-register-inner-form-middle' />
    <select type='text' onChange={(e)=>setInstitution2(e.target.value)} className='main-register-inner-form-middle'>
        {searchedInstitutions.length>0&&
            searchedInstitutions.map((item,index)=>{
                return(
                    <option className='institution-option' style={{color:'green'}} key={index}>{`${item?.name},${item?.address},${item?.country}`}</option>
                )
            })
        }
    </select> */}
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>Street Address</span></div><input type='text' onChange={(e)=>setStreetAddress(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'></span></div><input type='text' className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'></span></div><input type='text' className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'> </span></div><input type='text' className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'></span></div><input type='text' className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'></span></div><input type='text' className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'></span></div><input type='text' className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>City</span></div><input type='text' onChange={(e)=>setCity(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>State or Province</span></div><input type='text' onChange={(e)=>setState(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-not-required-text'>Zip or Postal Code </span></div><input type='text' onChange={(e)=>setCode(e.target.value)} className='main-register-inner-form-middle' /><span className='main-register-inner-form-right'></span></div>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Country or Region  * </span></div>
    
    {/* {!country&&<input type='text' placeholder='Please select from the list below' className='main-register-inner-form-middle' /> } */}
    

   <Select options={options} value={country} onChange={changeHandler} className='main-register-inner-form-middle-country'/>
    {/* <span className='main-register-inner-form-right'></span> */}
    </div>
    
    <div className='main-register-inner-form'>
        <div className='main-register-inner-form-radios-container'>
        <span className='main-register-inner-form-radios-text'>Address is for  *</span>
        </div>
        <div className='main-register-inner-form-radios-items-container'>
        <div className='phone-type-wrapper'>
<span className='phone-type-text'>  Work</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Home</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  Other</span><input type='radio'/>
</div>
            </div>
    
    </div>
    <div className='main-register-inner-form'>
        <div className='main-register-inner-form-radios-container'>
        <span className='main-register-inner-form-radios-text'>Available as a Reviewer?*</span>
        </div>
        <div className='main-register-inner-form-radios-items-container'>
        <div className='phone-type-wrapper'>
<span className='phone-type-text'>  Yes</span><input type='radio'/>
</div>
<div className='phone-type-wrapper'>
<span className='phone-type-text'>  No</span><input type='radio'/>
</div>
            </div>
    
    </div>
</div>

</div>
{/* <div className='editorial-main-register-site-body'>
    
    <form className='editorial-main-register-form' style={{paddingTop:'30px'}}>
    <div className='choose-register-method'>
    <span className='main-register-text-method'>Areas of Interest or Expertise</span>
    </div>
    <p className='main-register-form-info-box'>
    Please indicate your areas of expertise either by selecting from the pre-defined list using the "Select Personal Classifications" button or by adding your own keywords individually using the "New Keyword" field and associated "Add" button.
    </p>
    <div className='main-register-inner-form'><div className='main-register-inner-form-left'><span className='main-register-required-text'>Personal Classifications  *</span></div><span className='main-register-inner-form-right'>
        {
            classification?.length<1?'(None Selected)':`${classification?.length+1} Selected`
        }
        
</span></div>
<button className='main-register-btn' onClick={(e)=>{e.preventDefault(); setOpen(true)}}>Select Personal Classifications</button>
{
    newState.status.length>0&&<span className={newState.status==='reg-success'?'sucess':'reg-error'}>{newState.data}</span>
  }
<span className='classifications'>Select {classification?.length+1}+ Classifications</span>
{
    error.err?.length>0||error.err?.length>0&&<>
    <span>{error.err?.length>0&&error.err}</span>
    <span>{error.hint?.length>0&&error.hint}</span>
    </>
  }
</form>

</div> */}

{
    loading?<FaSpinner />:<button className='register-btn' onClick={handleRegistration}>Continue {'>>'}</button>
}
        </div>
    </div>
    <div className={show==='pass-eq'?'password-formatting-modal':'editorial-register-modal-hidden'} >
<h1 className='password-formatting-title'>Password Formatting Violation</h1>
<div className='password-formatting-bottom-container'>
    <p className='password-formatting-bottom-content'>Password cannot be the same as the username.</p>
    <p className='password-formatting-bottom-content'>Password cannot be the same as the user's first or last name.</p>
    <p className='password-formatting-bottom-content-required'>*Passwords must be a minimum of <b>10</b> characters.</p>
    <p className='password-formatting-bottom-content-required'>*Passwords must be alpha-numeric (i.e., must contain both letters and numbers.)</p>
    <p className='password-formatting-bottom-content'>Passwords must be mixed case (i.e., must contain both upper and lower case letters.)</p>
</div>
<button onClick={()=>setShow('form')} className='password-formatting-btn'>Ok</button>
    </div>
</>)
};

UpdateInfo.propTypes = {};

export { UpdateInfo };