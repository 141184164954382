import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items:localStorage.getItem('my-manuscripts')?JSON.parse(localStorage.getItem('my-manuscripts')):[],
    status:''
}

const myManuscriptsSlice = createSlice({
    name:'myManuscripts',
    initialState,
    reducers:{
        loadMyManuscripts(state,action){
            const item = action.payload;
            if(item?.lenght>0){
                localStorage.setItem('myManuscripts',JSON.stringify(item))
                return{
                    ...state,items:item
                }
            }

        }
    },
    extraReducers:{}
})

export const{loadMyManuscripts} = myManuscriptsSlice.actions
export default myManuscriptsSlice.reducer;
