import React, {useEffect, useState} from 'react';
import PDFMerger from 'pdf-merger-js/browser';
import PropTypes from 'prop-types';
import {PDFDocument ,rgb, StandardFonts} from 'pdf-lib'
import { Link, useNavigate, useParams } from 'react-router-dom';
import './SubmissionOne.css'
import { FaArrowLeft, FaArrowRight, FaBars, FaCheck, FaCheckCircle, FaEdit, FaFilm, FaInfoCircle, FaPen, FaPlay, FaRegEdit, FaRegSave, FaSave, FaSpinner, FaTimes, FaTrash, FaUndo, FaUser, FaUserEdit } from 'react-icons/fa';
import moment from 'moment/moment';
import { setAuthHeader, url2 } from '../../../Redux/Api';
import edit from '../images/edit.PNG'
import { useDispatch, useSelector } from 'react-redux';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { EditorialHeader } from './EditorialHeader';
import { getManuscripts } from '../../../Redux/Slices/manuscriptsSlice';
import axios from 'axios';

const SubmissionOne = ({location}) => {
    const dispatch = useDispatch()
    const[completed, setCompleted] = useState(false);
    const[loading, setLoading] = useState(false);
    const user = useSelector(state=>state.auth.user)
    const[openNewReviewer,setOpenNewReviewer] = useState(false);
    const[showUserCard, setShowUserCard] = useState(false);
    const[manuscriptOpen1, setManuscriptOpen1] = useState(false);
    const[manuscriptOpen2, setManuscriptOpen2] = useState(false);
    const[manuscriptOpen3, setManuscriptOpen3] = useState(false);
    const[manuscriptOpen4, setManuscriptOpen4] = useState(false);
    const[answerRequired, setAnswerRequired] = useState('');
    const[response, setResponse] = useState('');
    const[agreement, setAgreement] = useState(false);
    const[policy, setPolicy] = useState('');
    const[searchedInstitutions, setSearchedInstitutions] = useState([]);
    const[institutionSearch, setInstitutionSearch] = useState('');
    const currentItem = useSelector(state=>state.journal.journal)
    const[institutions, setInstitutions] = useState([]);

    // PDF-Lib starts
    const [pdfInfo, setPdfInfo] = useState([]);
  const[data, setData] = useState('');
  const[allFiles, setAllFiles] = useState([]);
  const [mergedPdfUrl, setMergedPdfUrl] = useState();
  const[filePath, setFilePath] = useState('');
  const[title, setTitle] = useState('')
  const[description, setDescription] = useState('')
  const[send, setSend] = useState(false)
  const[nexted, setNexted] = useState(false)
  const[exportingFile, setExportingFile] = useState([])
  const[exportable, setExportAble] = useState()

useEffect(()=>{
  if(filePath.length>0){
    modifyPdf();
  }
},[send])
useEffect(()=>{
    const docUrl = URL.createObjectURL(new Blob([exportingFile], {type: 'application/pdf'}))
    let a = exportingFile.toString('base64')
    setExportAble(docUrl)
},[exportingFile]);

const modifyPdf = async () => {
    const pdfDoc = await PDFDocument.load(mergedPdfUrl);
    const pdfBytes = await pdfDoc.save();
    const docUrl = URL.createObjectURL(new Blob([pdfBytes], {type: 'application/pdf'}));
    // const unit8arrURL = new Uint8Array( await pdfBytes.arrayBuffer());
    setPdfInfo(docUrl);
    setExportAble(mergedPdfUrl)
setExportingFile(pdfBytes)
};

// PDF-Lib ends
useEffect(()=>{
    const val = institutions.filter(item=>item?.name?.toLowerCase().includes(institutionSearch?.toLowerCase()))
    setSearchedInstitutions(val);
},[institutionSearch,institutions]);

    useEffect(()=>{
        async function fetchData(){
            await fetch(`${url2}/institutions`,{
                method:'get',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    return setInstitutions(resp.resp)
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }
            }).catch(err=> setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    },[])
    
const[items,setItems] = useState(JSON.parse(localStorage.getItem('items')));
const[marked, setMarked] = useState([]);
const[selected, setSelected] = useState(JSON.parse(localStorage.getItem('type'))||"");
const[manuscriptData, setManuscriptData] = useState(localStorage.getItem('manuscript-data')||{type:'',files:[],reviews:[],information:{answer:'',terms:'',reason:''},manuscript:{title:'',abstract:'',authors:[],funding:''}});
const[orcidDisplay, setOrcidDisplay] = useState(false);
const[typeLevel, setTypeLevel] = useState(false);
const[showMore, setShowMore] = useState(false);
const[level, setLevel] = useState(localStorage.getItem('level')?JSON.parse(localStorage.getItem('level')):'article-selection');
const[titlePage, setTitlePage] = useState("Title Page");
const[titlePageDescription, setTitlePageDescription] = useState("Title Page");
const[error, setError] = useState({err:'',hint:''});

const[manuscriptTitle, setManuscriptTitle] = useState(localStorage.getItem('saveManuscriptTitle')?JSON.parse(localStorage.getItem('saveManuscriptTitle')):'')
const[manuscriptAbstract, setManuscriptAbstract] = useState(localStorage.getItem('saveManuscriptAbstract')?JSON.parse(localStorage.getItem('saveManuscriptAbstract')):'')
const[manuscriptAuthors, setManuscriptAuthors] = useState(localStorage.getItem('saveManuscriptAuthors')?JSON.parse(localStorage.getItem('saveManuscriptAuthors')):'')
const[manuscriptFunding, setManuscriptFunding] = useState(localStorage.getItem('saveManuscriptFunding')?JSON.parse(localStorage.getItem('saveManuscriptFunding')):'')

const navigate = useNavigate();

useEffect(()=>{
setItems(JSON.parse(localStorage.getItem('items')))
},[titlePage,titlePageDescription])

const sortItems = ()=>{
    const ar= items.sort()
    setItems(ar)
    localStorage.setItem('items',JSON.stringify(ar))
}

useEffect(()=>{
    if(localStorage.getItem('items')===undefined){
        localStorage.removeItem('items')
    }else if(localStorage.getItem('items')){
        setItems(JSON.parse(localStorage.getItem('items')))
    }
},[titlePage,titlePageDescription]);

const handleSaveManuscript = ()=>{
    localStorage.setItem('manuscript-data',{manuscriptTitle,manuscriptAbstract,manuscriptAuthors,manuscriptFunding})
}
useEffect(()=>{
    setManuscriptData({title:manuscriptTitle,abstract:manuscriptAbstract,authors:manuscriptAuthors,funding:manuscriptFunding})
},[manuscriptTitle,manuscriptAbstract,manuscriptAuthors,manuscriptFunding]);

useEffect(()=>{
localStorage.setItem('type',JSON.stringify(selected))
},[selected])

useEffect(()=>{
    if(selected==='none'||selected===''){
        setOrcidDisplay(false)
    }else{
        setOrcidDisplay(true)
    }
},[selected])
    
function handleRemoveItem(){
    let x;
    if(marked.length>0){
        marked.forEach(i=>{
             x = items.filter(a=>a!==i)
        })
        setItems(x)
        return localStorage.setItem('items',JSON.stringify(x))
    }
}

function handelSelect(e){
    e.preventDefault()
      setTypeLevel(true);
      setLevel('file-attachment');
      setLevelData('file-attachment')
      localStorage.setItem('level',JSON.stringify('file-attachment'))
}
    const handleFile = (e)=>{
        if(selected.length>0){
            uploadHandler(e)
        }
}

const[fileAttached, setFileAttached] = useState(false);
const[reviewerOpen, setReviewerOpen] = useState(true);

const handleFileAttached= ()=>{
    setFileAttached(true);
    setLevel('review-preferences');
    localStorage.setItem('level',JSON.stringify('review-preferences'))
}
const[opposeDisplay,setOpposeDisplay] = useState('')
const[firstName,setFirstName] = useState('');
const[middleName,setMiddleName] = useState('');
const[lastName,setLastName] = useState('');
const[degree,setDegree] = useState('');
const[position,setPosition] = useState('');
const[institution,setInstitution] = useState('');
const[deparment,setDeparment] = useState('');
const[email,setEmail] = useState('');
const[reason,setReason] = useState('');
const[deleting, setDeleting] = useState({logger:'',data:null})
const[opposers, setOpposers] = useState(localStorage.getItem('opposers')?JSON.parse(localStorage.getItem('opposers')):[{firstName:'',lastName:'',middleName:'',email:'',position:'',degree:'',institution:'',deparment:'',reason:''}])
const handleDelete = (e)=>{
    let values = opposers.filter(filt=>filt!==e)
    setOpposers(values)
    localStorage.setItem('opposers',JSON.stringify(values));
    setDeleting({status:'',data:null})
}
const toDelete=(e)=>{
    setDeleting({logger:JSON.parse(localStorage.getItem('type')),data:e})
}
const handleOpposeFunc = (e)=>{
if(e==='save'){
    if(firstName.length<1||lastName.length<1){
    
    }else{
        const val = [...opposers,{firstName,lastName,middleName,email,position,degree,institution,deparment,reason}];
        if(val[0].firstName.length<1){
            val.shift()
        setOpposers(val)
        localStorage.setItem('opposers',JSON.stringify(val));
        }else{
        setOpposers(val)
        localStorage.setItem('opposers',JSON.stringify(val));
        }
    setOpenNewReviewer(false)
    }
}else if(e==='save-another'){
    if(firstName.length<1||lastName.length<1){
    
    }else{
        const val = [...opposers,{firstName,lastName,middleName,email,position,degree,institution,deparment,reason}];
        if(val[0].firstName.length<1){
            val.shift()
        setOpposers(val)
        localStorage.setItem('opposers',JSON.stringify(val));
        console.log('The vals',val)
        }else{
        setOpposers(val)
        localStorage.setItem('opposers',JSON.stringify(val));
        console.log('The vals',val)
        }
    }
}else if(e==='cancel'){
    setOpenNewReviewer(false)
}else if(e==='clear'){
    setOpposers(opposers[opposers.length-1]={firstName:'',lastName:'',middleName:'',email:'',position:'',degree:'',institution:'',deparment:'',reason:''})
}
}
const[levelStatusState,setLevelStatusState] = useState([]);
const[levelData, setLevelData] = useState('');
const[imagePreview,setImagePreview] = useState('');
const[image, setImage] = useState('');
const[imageData, setImageData] = useState('');
const[goodimage, setGoodImage] = useState('');

useEffect(()=>{
    localStorage.setItem('level-status',levelStatusState);
},[levelStatusState])

useEffect(()=>{
    const val = localStorage.getItem('level-status')?localStorage?.getItem('level-status'):levelStatusState;
setLevelStatusState(val)
},[levelData])

const levels = ['article-selection','file-attachment','review-preferences','additional-information','manuscript-data'];
const[incomplete, setIncomplete] = useState('');

const[confirmedOpposers, setConfirmedOpposers] = useState(false);

useEffect(()=>{
    let currentState = levelStatusState;    
    if(levelData!==level){
        if(levelData==='article-selection'){
          if(levelData==='file-attachment'){
            if(items.length<1){
                currentState.push(levelData)
               return setLevelStatusState(currentState)
                }else{
                return setLevelStatusState(currentState)
            }
          }else if(levelData==='review-preferences'){
            if(!confirmedOpposers){
                currentState.push(levelData)
            }else{
                return
            }
          }else if(levelData==='additional-information'){
            if(response.length<1||answerRequired.length<1||policy.length<1||answerRequired.length<1){
                currentState.push(levelData)
               return setLevelStatusState(currentState)
        }else{
                return setLevelStatusState(currentState)
        }
          }else if(levelData==='manuscript-data'){
            if(manuscriptData?.title.length<1){
                currentState.push(levelData)
          setLevelStatusState(currentState)
        }else{
                return
            }
          }
          return setLevelStatusState(currentState)
        }
          setLevelStatusState(currentState)
        
          if(currentState.includes(levelData)){
              setIncomplete(levelData)
          }
    }

},[levelData])

const handleLevelStatus = (e)=>{
    setLevelData(e)
    setLevel(e);
    localStorage.setItem('level',JSON.stringify(e))
    if(levelStatusState.length>0){
        let a = levelStatusState;
        a.push(e)
        setLevelStatusState(a)
    }else{
        let b = levelStatusState;
        b.push(e)
        setLevelStatusState(b)
    }
}

const levelControl = (e)=>{
    if(levelStatusState.includes(e.level)&&e.level !=='article-selection'){
        return 'incomplete-level'
    }else if(levelStatusState.includes(e.level)&&!e.state){
        return 'incomplete-level'
    }else if(!levelStatusState.includes(e.level)&&level===e.level){
        return 'current-level'
    }else if(e.state&&level!==e.level){
        return 'complete-level'
    }else{
        return 'default-level'
    }
}

const[newState, setNewState] = useState({status:'',data:''});

async function handlePDF(e){
    const doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: 'a4'
      });

      //copyright footer--- Powered by Editorial Manager® and ProduXion Manager® from Aries Systems Corporation
      //Title
      const titleText = currentItem?.title;
      const titlePadding = 20
      const titleWidth = doc.getTextWidth(titleText);
      const titleAlign = (doc.internal.pageSize.width/2)-(titleWidth/2)
      doc.setFontSize(18);
      doc.setTextColor('rgb(8, 59, 89)');
      doc.text(titleText, titleAlign, titlePadding);

      //Article Title
      const articleTitleText = e.manuscript.title;
      const articleTitlePadding = 28
      const articleTitleWidth = doc.getTextWidth(articleTitleText);
      const articleTitleAlign = (doc.internal.pageSize.width/2)-(articleTitleWidth/2)
      doc.setFontSize(16);
      doc.setTextColor('#003153');
      doc.text(articleTitleText, articleTitleAlign, articleTitlePadding);
      //File Type
      const fileTypeText = `--${titlePage}--`;
      const fileTypePadding = 36
      const fileTypeWidth = doc.getTextWidth(fileTypeText);
      const fileTypeAlign = (doc.internal.pageSize.width/2)-(fileTypeWidth/2)
      doc.setFontSize(18)
      doc.text(fileTypeText, fileTypeAlign, fileTypePadding);
      autoTable(
        doc,{margin:30,
        body:[['Manuscript Number:',''],['Full Title:',e.manuscript.title],['Article Type:',e.type],['Funding Information:',e.manuscript.funding],
        ['Corresponding Author:',`${user?.firstName} ${user?.lastName} ${user?.institution1&&user?.institution1}`],['Corresponding Author Secondary Information:',manuscriptAuthors],['Corresponding Author\'s Institution:',`${user?.institution1&&user?.institution1}`],
        ['Corresponding Author\'s Secondary Institution:',`${user?.institution2?user?.institution2:''}`],['First Author:',`${user?.firstName} ${user?.lastName}`],['First Author Secondary Information:',''],['Order of Authors: ',`${user?.firstName} ${user?.lastName}${manuscriptAuthors.length>0&&`, ${manuscriptAuthors},`}`],
        ['Order of Authors Secondary Information:',`${user?.firstName} ${user?.lastName}, ${e.manuscript.authors}`],['Author Comments: ',e.information.answer]]})
        doc.addPage()
        //Manuscript Title
const manuscriptTitleText = `${manuscriptTitle}`;
const manuscriptTitleTextPadding = 20
const manuscriptTitleTextWidth = doc.getTextWidth(manuscriptTitleText);
const manuscriptTitleTextAlign = (doc.internal.pageSize.width/2)-(manuscriptTitleTextWidth/2)
doc.setFontSize(18)
        doc.text(manuscriptTitleText, manuscriptTitleTextAlign, manuscriptTitleTextPadding);
//manuscript Authors
doc.setFontSize(10)
doc.text(manuscriptAuthors, 10,35);
doc.text(manuscriptFunding, 10,40);
        const conv = doc.output('arraybuffer');
        setData(conv);
        setAllFiles([...allFiles,conv]);
        setNexted(true);
        setLoading(false);
}

useEffect(()=>{
console.log('Allfiles',allFiles.length,allFiles)
},[allFiles])

const[bigAsbFile,setBigAsbFile] = useState(false);
const[bigItemFile,setBigItemFile] = useState(false);

const handleClearBigAbs = ()=>{
    setMergedPdfUrl()
    setAllFiles([])
}
const handleClearBigItem = ()=>{
    setMergedPdfUrl()
    setAllFiles([])
}

const saveManuscriptAbstract = async(e)=>{
    e.preventDefault()
    let a = e.target.files[0]
    setFilePath(a.name)
    const unit8 = new Uint8Array(await a.arrayBuffer());
    setData(unit8)
    setAllFiles([...allFiles,unit8])
    const fileData = e.target.files[0]
   if(fileData){
    if(fileData){
     if(fileData.size>20000000){
       setNewState({status:'failed',data:`Sorry, ${fileData.size/1000}mb is high. Max file file size is 20MB`})
       setBigAsbFile(true)
     }else{
        setBigAsbFile(false)
        }
  }
   }
  }
const uploadHandler = async(e)=>{
    e.preventDefault()
    const fileData = e.target.files[0]
    setAllFiles([...allFiles,fileData])
    const base64 = await Converter(fileData)
    setImagePreview(base64)
    setImage(fileData)
    setImageData(base64)
    const imageData = new FormData()
            imageData.append('file',fileData);
    if(fileData){
     if(fileData.size>20000000){
        setBigItemFile(true)
      return setNewState({status:'failed',data:`Sorry, ${fileData.size/1000}mb is high. Max file file size is 20MB`})
     }else{
        if(selected.length>0){
            const imageData = new FormData()
            imageData.append('file',fileData);
            const val = {title:titlePage,description:titlePageDescription,file:fileData};
            // const val = {title:titlePage,description:titlePageDescription,file:{lastModified:e.target.files[0].lastModified,size:e.target.files[0].size,name:e.target.files[0].name,url:""}};
            let itms = localStorage.getItem('items')&&JSON.parse(localStorage.getItem('items'))
           if(itms?.length>0){
            setItems([...itms,val])
            return  localStorage.setItem('items',JSON.stringify([...itms,val]));
           }else{
            let a = []
            a.push(val)
            setItems(a)
            return  localStorage.setItem('items',JSON.stringify(a));
           }
        }
    //   const extensions = ['image/png','image/jpg','image/jpeg','image/ico','image/jfif','image/gif',"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    // ]
    //   const extension = ['png','jpg','jpeg','ico','jfif','gif']
      
    //   const checkExtension = extensions.indexOf(fileData.type)
    //  if(checkExtension<0){
    //     setNewState({status:'failed',data:`Unsupported file. Try any of these ${extension}.`})
    //  }else{
    //   setGoodImage(true)
    //  }
     }
  }
  }

const Converter = (e)=>{
    return new Promise((resolve,reject)=>{
      const reader = new FileReader()
      reader.readAsDataURL(e)
      reader.onload = ()=>{
        resolve(reader.result)
      }
      reader.onerror = (error)=>{
        reject(error)
      }
    })
  }
  const AbstractConverter = (e)=>{
    return new Promise((resolve,reject)=>{
      const reader = new FileReader()
      reader.readAsDataURL(e)
      reader.onload = ()=>{
        resolve(reader.result)
      }
      reader.onerror = (error)=>{
        reject(error)
      }
    })
  }
const markedhandler = (e)=>{
   if(marked.length>0){
    if(marked.indexOf(e)>0){
    const mark = marked.filter(it=>it!==e)
    return setMarked(mark)
    }else{
        return setMarked([...marked,e])
    }
   }
   return setMarked([...marked,e])
}
const[manuscriptIdInit, setManuscriptIdInit] = useState(JSON.parse(localStorage.getItem('manuscriptIdInit'))||'');

useEffect(()=>{
    const timed = setInterval(() => {
        if(selected.length>0&&manuscriptIdInit.length<1){
        const time = new Date()
           setManuscriptIdInit (`${currentItem?.acronym.toLowerCase()+time.getTime()}`)
           localStorage.setItem('manuscriptIdInit',JSON.stringify(`${currentItem?.acronym.toLowerCase()+time.getTime()}`))
        }
    }, 10000);
    return ()=>clearTimeout(timed)
},[completed,manuscriptIdInit])
const[sentPartial,setSentPartial] = useState(false);

useEffect(()=>{
    const data = {type:selected,files:items,reviews:opposers,information:{answer:answerRequired,agreement,response,policy},manuscriptIdInit:manuscriptIdInit,
    manuscript:{title:manuscriptTitle,abstract:pdfInfo,authors:manuscriptAuthors,funding:manuscriptFunding},pub:currentItem?.pub}
    localStorage.setItem('manuscript-data',JSON.stringify(data));
    const timed = setInterval(() => {
        if(selected.length>0&&manuscriptIdInit.length<1){
        const time = new Date()
           setManuscriptIdInit (`${currentItem?.acronym.toLowerCase()+time.getTime()}`)
           localStorage.setItem('manuscriptIdInit',JSON.stringify(`${currentItem?.acronym.toLowerCase()+time.getTime()}`))
        //    if(!sentPartial){
        //     fetch(`${url2}/partial-submission`,{
        //         method:'post',
        //         headers:{
        //             "Content-Type":"application/json",
        //             "Authorization":"Bearer "+JSON.parse(localStorage.getItem("token"))
        //         },body:JSON.stringify({
        //             data:JSON.parse(localStorage.getItem('manuscript-data'))
        //         })
        //        }).then(res=>res.json())
        //        .then(resp=>{
        //         if(resp.resp){
        //             return setSentPartial(true)
        //         }
        //        })
        //    }else{
        //     fetch(`${url2}/partial-submission-update`,{
        //         method:'post',
        //         headers:{
        //             "Content-Type":"application/json",
        //             "Authorization":"Bearer "+JSON.parse(localStorage.getItem("token"))
        //         },body:JSON.stringify({
        //             data:JSON.parse(localStorage.getItem('manuscript-data'))
        //         })
        //        }).then(res=>res.json())
        //        .then(resp=>{
        //         if(resp.resp){
        //             return 
        //         }
        //        })
        //    }
          
        }
    }, 80000);
    return ()=>clearTimeout(timed)
},[completed,selected,items,titlePage,titlePageDescription,policy,answerRequired,agreement])
const[exporting,setExporting] = useState(null)

const handleSubmitManuscript = async(e)=>{
    e.preventDefault()
    setLoading(true)
    const data = {type:selected,files:items,reviews:opposers,information:{answer:answerRequired,agreement,response,policy},manuscriptIdInit:manuscriptIdInit,upload:exportable,
    manuscript:{title:manuscriptTitle,abstract:exportable,authors:manuscriptAuthors,funding:manuscriptFunding},pub:currentItem?.pub}
    localStorage.setItem('manuscript-data',JSON.stringify(data));
    setExporting(data);
    handlePDF(data)
}

const[loadingManuscript,setLoadingManuscript] = useState(false);

const createManuscript = async()=>{
    setLoadingManuscript(true)
    // let a = exportable.toString('base64');
    const data = {data:exporting,upload:exportable}
    await axios.post(`${url2}/create-manuscript`,data,setAuthHeader()
    ).then(res=>res.json())
    .then(resp=>{
        if(resp.data.resp){
            dispatch(getManuscripts())
            setLoadingManuscript(false)
            return navigate(`/${currentItem?.acronym?.toLowerCase()}/confirmation`)
        }else if(resp.data.err){
            setLoadingManuscript(false)
            setNewState({status:'failed',data:resp.data.err})
        }else{
            setLoadingManuscript(false)
            setNewState({status:'failed',data:'Something went wrong'});
        }
    }).catch(err=>{setNewState({status:'failed',data:err.message});setLoadingManuscript(false)})
}
const closer = ()=>{
    if(showUserCard){
       return setShowUserCard(false)
    }
}

useEffect(()=>{
    const timed = setInterval(() => {
        if(manuscriptAbstract&&manuscriptAbstract.length>0){
            return localStorage.setItem('saveManuscriptAbstract',JSON.stringify(manuscriptAbstract))        
        }
    }, 1000);
    return ()=>clearTimeout(timed)
},[manuscriptAbstract])

const saveManuscriptAuthors = (e)=>{
    setManuscriptAuthors(e.target.value)
}
useEffect(()=>{
    const timed = setInterval(() => {
        if(manuscriptAuthors&&manuscriptAuthors.length>0){
            return localStorage.setItem('saveManuscriptAuthors',JSON.stringify(manuscriptAuthors))        
        }
    }, 1000);
    return ()=>clearTimeout(timed)
},[manuscriptAuthors])

const saveManuscriptFunding = (e)=>{
    setManuscriptFunding(e.target.value)
}
useEffect(()=>{
    const timed = setInterval(() => {
        if(manuscriptFunding&&manuscriptFunding.length>0){
            return localStorage.setItem('saveManuscriptFunding',JSON.stringify(manuscriptFunding))        
        }
    }, 1000);
    return ()=>clearTimeout(timed)
},[manuscriptFunding])

const saveManuscriptTitle = (e)=>{
    setManuscriptTitle(e.target.value)
}
useEffect(()=>{
    const timed = setInterval(() => {
        if(manuscriptTitle&&manuscriptTitle.length>0){
            return localStorage.setItem('saveManuscriptTitle',JSON.stringify(manuscriptTitle));
        }
    }, 1000);
    return ()=>clearTimeout(timed)
},[manuscriptTitle])
useEffect(() => {
    let revFiles = allFiles.reverse()
    if(revFiles.length>0&&revFiles !==undefined){
        const render = async () => {
            const merger = new PDFMerger();
      
            for(const file of revFiles) {
              await merger.add(file);
            }
      
            await merger.setMetadata({
              producer: "pdf-merger-js based script"
            });
      
            const mergedPdf = await merger.saveAsBlob();
            const unit8arrURL = new Uint8Array( await mergedPdf.arrayBuffer());
            setData(unit8arrURL)
            return setMergedPdfUrl(unit8arrURL);
          };
      
          render().catch((err) => {
            throw err;
          });
      
          setMergedPdfUrl();
    }else{
        return
    }
  }, [allFiles, setMergedPdfUrl]);
return <>
<div className='submission-main-container'>
<EditorialHeader/>
    {openNewReviewer?
    <div className='modal-main'>
<div className='modal-container'>
    <div className='modal-top'>
    <span className='modal-top-left-item'>Add new reviewer</span>
    <span className='modal-top-right-item' onClick={()=>setOpenNewReviewer(false)}>
    <FaTimes />
    </span>
    </div>
    <div className='modal-middle'>
    <div className='modal-middle-left' onMouseLeave={()=>setOpposeDisplay('')}>
<FaSave onMouseEnter={()=>setOpposeDisplay('save')} onClick={()=>handleOpposeFunc('save')} style={{cursor:'pointer'}}/>
<FaRegSave onMouseEnter={()=>setOpposeDisplay('save-another')} onClick={()=>handleOpposeFunc('save-another')} style={{cursor:'pointer'}}/>
<FaTrash onMouseEnter={()=>setOpposeDisplay('clear')} onClick={()=>handleOpposeFunc('clear')} style={{cursor:'pointer'}}/>
<FaUndo onMouseEnter={()=>setOpposeDisplay('cancel')} onClick={()=>handleOpposeFunc('cancel')} style={{cursor:'pointer'}}/>
    </div>
    {
            opposeDisplay.length>0&&
            <p className='modal-info-display'>
                {opposeDisplay==='save'?'Save This Reviewer':
                opposeDisplay==='save-another'?'Save This Reviewer and Add Another':
                opposeDisplay==='clear'?'Clear Form':
                opposeDisplay==='cancel'?'Cancel':null
                }
            </p>
    }
    
    <div className='modal-middle-right'>
        <span className='submision-text-content'>Insert Special Characters</span>
    </div>
    </div>
    <div className='modal-form'>
<div className='modal-form-inner'>
    <span className='modal-caption' style={{color:'var(--red-color)'}}>Given/First Name *</span>
    <input type='text' className='modal-input'  onChange={e=>setFirstName(e.target.value)}/>
</div>
<div className='modal-form-inner'>
    <span className='modal-caption'>Middle name </span>
    <input type='text' className='modal-input'  onChange={e=>setMiddleName(e.target.value)}/>
</div>
<div className='modal-form-inner'>
    <span className='modal-caption' style={{color:'var(--red-color)'}}>Family/Last Name *</span>
    <input type='text' className='modal-input'  onChange={e=>setLastName(e.target.value)}/>
</div>
<div className='modal-form-inner'>
    <span className='modal-caption'>Degree </span>
    <input type='text' className='modal-input'  onChange={e=>setDegree(e.target.value)}/>
</div>
<div className='modal-form-inner'>
    <span className='modal-caption'>Position </span>
    <input type='text' className='modal-input' onChange={e=>setPosition(e.target.value)}/>
</div>

<div className='modal-form-inner-institution'>
    <span className='modal-caption'>Institution </span>
    <input type='text' placeholder={institution&&institution} onChange={(e)=>setInstitutionSearch(e.target.value)} className='modal-input-institution' />
    <select type='text' onChange={(e)=>setInstitution(e.target.value)} className='modal-input-institution'>
        {searchedInstitutions.length>0&&
            searchedInstitutions.map((item,index)=>{
                return(
                    <option key={index}>{`${item?.name},${item?.address},${item?.country}`}</option>
                )
            })
        }
    </select>
</div>
<div className='modal-form-info-inner'>
    <span className='modal-info-caption'>Start typing to display potentially matching institutions.</span>
    <span className='modal-info-caption'><FaInfoCircle /></span>
</div>
<div className='modal-form-inner'>
    <span className='modal-caption'>Department </span>
    <input type='text' className='modal-input'  onChange={e=>setDeparment(e.target.value)}/>
</div>
<div className='modal-form-inner'>
    <span className='modal-caption'>Email </span>
    <input type='email' className='modal-input'  onChange={e=>setEmail(e.target.value)}/>
</div>
<div className='modal-form-inner-reason'>
    <span className='modal-caption'>Reason </span>
    <textarea type='text' className='modal-textarea'  onChange={e=>setReason(e.target.value)}/>
</div>
    </div>
</div>
    </div>:
    deleting?.data&&<div className='modal-main-2'>
    <div className='modal-container-2'>
        <div className='modal-top'>
        <span className='modal-top-left-item'>Confirm deletion</span>
        <span className='modal-top-right-item'>
        <FaTimes />
        </span>
        </div>
        <div className='modal-middle-2'>
    <p className='modal-middle-2-content'>Are you sure you wish to remove this <span style={{textTransform:'capitalize'}}>{deleting.logger}</span></p>
    <div className='modal-middle-down-2'>
<button className='delete-btn' onClick={()=>handleDelete(deleting.data)}>Ok</button>
<button className='delete-btn' onClick={()=>setDeleting({status:'',data:null})}>Cancel</button>
</div>
        </div>
    </div>
        </div>
    }
     
        <p className='error'>{error.err}</p>
        
        <div className='progress-container'>
                        <div className={levelControl({level:'article-selection',state:selected.length>0?true:false})}>
                                        <div className='level-point' onClick={()=>handleLevelStatus('article-selection')}>.
                                        {selected.length<1?
<svg className='submission-arrow' style={{color:"white",position:"absolute",top:0,left:-5,width:'50px',height:'50px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
<path fill="currentColor" d="M18.414 10.656a2 2 0 0 0-2.828 0L14 12.242V5a2 2 0 0 0-4 0v7.242l-1.586-1.586a2 2 0 1 0-2.828 2.828L12 19.898l6.414-6.414a2 2 0 0 0 0-2.828"/>
</svg>:
<span className='submission-check'><FaCheck /></span>

                                                                    }
                                        </div>
                                        <span className='level-caption'>Article Type Selection</span>
                                    </div>
                                    <div className={typeLevel?'active-connector':'connector'}></div>
                                    <div className={levelControl({level:'file-attachment',state:items?.length>0?true:false})}>
                                                                    <div className='level-point' onClick={()=>handleLevelStatus('file-attachment')}>.
                                                                    {items?.length<1?
<svg className='submission-arrow' style={{color:"white",position:"absolute",top:0,left:-5,width:'50px',height:'50px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
<path fill="currentColor" d="M18.414 10.656a2 2 0 0 0-2.828 0L14 12.242V5a2 2 0 0 0-4 0v7.242l-1.586-1.586a2 2 0 1 0-2.828 2.828L12 19.898l6.414-6.414a2 2 0 0 0 0-2.828"/>
</svg>:
<span className='submission-check'><FaCheck /></span>

                                                                    }
                                                                    </div>
                                                                    <span className='level-caption'>Attach Files</span>
                                    </div>
            
                                    <div className={typeLevel?'active-connector':'connector'}></div>
            
                                    <div className={levelControl({level:'review-preferences',state:confirmedOpposers?true:false})}>
                                                                    <div className='level-point' onClick={()=>handleLevelStatus('review-preferences')}>.
                                                                    {opposers.length<1?
<svg className='submission-arrow' style={{color:"white",position:"absolute",top:0,left:-5,width:'50px',height:'50px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
<path fill="currentColor" d="M18.414 10.656a2 2 0 0 0-2.828 0L14 12.242V5a2 2 0 0 0-4 0v7.242l-1.586-1.586a2 2 0 1 0-2.828 2.828L12 19.898l6.414-6.414a2 2 0 0 0 0-2.828"/>
</svg>:
<span className='submission-check'><FaCheck /></span>

                                                                    }
                                                                    </div>
                                                                    <span className='level-caption'>Review Preferences</span>
                                    </div>
                                    <div className={typeLevel?'active-connector':'connector'}></div>
                                    
            
                                    <div className={levelControl({level:'additional-information',state:answerRequired.length>0?true:false})}>
                                                                    <div className='level-point' onClick={()=>handleLevelStatus('additional-information')}>.
                                                                    {agreement.length<1?
<svg className='submission-arrow' style={{color:"white",position:"absolute",left:15,width:'50px',height:'50px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
<path fill="currentColor" d="M18.414 10.656a2 2 0 0 0-2.828 0L14 12.242V5a2 2 0 0 0-4 0v7.242l-1.586-1.586a2 2 0 1 0-2.828 2.828L12 19.898l6.414-6.414a2 2 0 0 0 0-2.828"/>
</svg>:<span className='submission-check'><FaCheck /></span>
                                                                    }
                                                                    </div>
                                                                    <span className='level-caption'>Additional Information</span>
                                    </div>
                                    <div className={manuscriptData?.title?.length<1?'active-connector':'connector'}></div>
                                    
                                    <div className={levelControl({level:'manuscript-data',state:manuscriptData?.title?.length>0?true:false})}>
                                                                    <div className='level-point' onClick={()=>handleLevelStatus('manuscript-data')}>.
                                                                    {manuscriptData?.title?.length>0?
<svg className='submission-arrow' style={{color:"white",position:"absolute",top:0,left:-5,width:'50px',height:'50px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
<path fill="currentColor" d="M18.414 10.656a2 2 0 0 0-2.828 0L14 12.242V5a2 2 0 0 0-4 0v7.242l-1.586-1.586a2 2 0 1 0-2.828 2.828L12 19.898l6.414-6.414a2 2 0 0 0 0-2.828"/>
</svg>:
<span className='submission-check'><FaCheck /></span>

                                                                    }
                                                                    </div>
                                                                    <span className='level-caption' style={{alignSelf:'flex-end',textAlign:'right'}}>Manuscript Data</span>
                                                                </div>
                        </div>
        {level==='article-selection'?
        <div className='submission-item-body'>
            
            
                                        <div className='submission-auth-main-container'>
                                        <div className="submission-auth-main-inner">
                                        <li>
                                        Choose the article type of your submissiom from the dropdown menu
                                        </li>
                                      
                                        <li>
                                        By submitting a manuscript, the author(s) or rightsholder(s), as the case may be agree(s) to the <a href="">terms and conditions 
                                        </a> of this journal.
                                        </li>
                                        </div>
                                        <div className='submission-auth-container'>
                                            <h1 className='auth-title'>Select Article Type</h1>
                                        <form onSubmit={handelSelect} className="submission-auth-container-form">
                         {!orcidDisplay? <div className='submission-auth-container-selection-container'>
                          <select name="" id="" className='submission-auth-container-selection' onChange={e=>setSelected(e.target.value.toLowerCase())}>
                            <option value='none'>None</option>
                            <option value="review">Original Article</option>
                            <option value="communication">Communication</option>
                            <option value="review">Review</option>
                            <option value="commentry">Commentry</option>
                            <option value="letter">Letter</option>
                            <option value="new & reviews">News & Views</option>
                            <option value="opinion">Opinion</option>
                            <option value="case study">Case Study</option>
                          </select>
                          </div>:
                          <div className='orcid-display'>
                             <select name="" id="" className='submission-auth-container-selection' onChange={e=>setSelected(e.target.value.toLowerCase())}>
                             <option value='none'>None</option>
                            <option value="review">Original Article</option>
                            <option value="communication">Communication</option>
                            <option value="review">Review</option>
                            <option value="commentry">Commentry</option>
                            <option value="letter">Letter</option>
                            <option value="new & reviews">News & Views</option>
                            <option value="opinion">Opinion</option>
                            <option value="case study">Case Study</option>
                          </select>
                          <div className=''></div>
                            <p style={{textTransform:'capitalize',fontSize:'12px',fontWeight:'bold'}}>{selected}</p>
                          </div>
                          }
                          {orcidDisplay?<button type="submit" className='submission-auth-container-btn-active'>
                            Proceed<FaArrowRight /></button>:<button type="submit" className='submission-auth-container-btn' disabled>Proceed
                          <FaArrowRight />
                          </button>}
                        </form>
                                            <hr className='submission-horizontal-line'/>
                                            
                                          
                                        </div>
                                        </div>
                                        
                    </div>:
                    level==='file-attachment'?
                        <div className='submission-item-body'>
            
                                        <div className='submission-auth-main-container'>
                                        <div className="submission-auth-main-inner-2">
                                        <li>
                                        Required For Submission:
                                        </li>
                                      <li className='checked-link'><FaCheck /> Title Page</li>
                                      <li className='blinded-manuscript'>Blinded Manuscript</li>
                                            <li>Please upload your manuscript without any author names placed within the text (Blinded Manuscript). Subsequently, provide any additional items if needed. You may upload these files individually or as a single zip file.</li>
                                            <li>If your data are available online, for example in a repository, you can add a weblink using the “Link(s) to supporting data” option from the dropdown menu, and these will be available to reviewers.</li>
                                            <li>In case of any issues, please use the ‘Contact Us’ link.</li>
                                        <>
                                            <div className='show-container'><span className={!showMore?'show-text-guide-icon-right':'show-text-guide-icon-down'}>
                                               
<i class="fa fa-play"  onClick={()=>setShowMore(!showMore)}/>
                                                </span>
                                                {items?.length>0&&<p  className='show-text-guide' onClick={()=>setShowMore(!showMore)}>Tips for authors submitting full source files in LaTeX:</p>}
                                                </div>
                                            {
                                                showMore&&<>
                                                    <li>All files must be on the same folder level; do not use sub folders</li>
                                                    <li>The main manuscript TeX file must be the first item</li>
                                                    <li>Define the main TeX file as ‘Manuscript’ in the drop down box</li>
                                                    <li>Upload any image files (e.g. EPS, PNG, TiFF, etc.) as figures</li>
                                                    <li>Upload any TeX supporting files and any (journal) specific style files that are referenced in the TeX file and declare them as ’LaTeX Supporting File(s)</li>
                                                    <li>Upload a PDF version of your article as supplementary material for reference</li>
                                                    <li>Ensure special characters (like ä/ö/ü) have been converted to appropriate TeX code</li>
                                                    <li>Do not use own macros or fonts, or .ps and .dvi files</li>
                                                    <li>The TeX files should not have any errors in order for the PDF to be built. Any errors will be shown in the generated PDF. For example: ! LaTeX Error: File ‘trackchanges.sty’ not found.</li>
                                                    <li>For XeLaTeX submissions, add this as the first line in your TeX file: %!TEX TS-program = xelatex</li>
                                                    <li>For further LaTeX tips you may refer to this <a href='https://www.ariessys.com/wp-content/uploads/EM_PM_LaTeX_Guide.pdf'>this help page.</a></li>
                                                </>
                                            }
                                        </>
                                        </div>
                                        <div className='submission-auth-container-2'>
                                            <p className='auth-top-guide'>Insert Special Character</p>
                                        <form onSubmit={handelSelect} className="submission-auth-container-form">
                                            <p className={newState==='success'?'success-message':'error-message'}>{newState.data?.length>0&&newState.data}</p>
                                            {bigAsbFile&&<div className='pdf-container-inner-wrapper'>
                                                {bigAsbFile&&<button className='download-link' onClick={handleClearBigItem}>Clear</button>}
                                                    {bigItemFile&&<button className='download-link' onClick={handleClearBigAbs}>Clear</button>}
                                            </div>}
                        {items?.length>0?
                        
                        <div className='submission-auth-uploader-container'>
                       <div className='submission-auth-uploader-container-inner-2'>
                       <span className='submission-auth-uploader-container-title-2'>Select Item Type</span>
                        <select  className='editorial-login-down-menu-selection-inner-2' onChange={e=>{setTitlePage(e.target.value);setTitlePageDescription(e.target.value)}}>
                            <option value='Title Page'>*Title Page</option>
                            <option value='Blinded Manuscript'>*Blinded Manuscript</option>
                            <option value='LaTex Supporting Files'>LaTex Supporting Files (if applicable; *.sty, *.bib, *.bbl, *nls etc.)</option>
                            <option value='Table'>Table</option>
                            <option value='Figure'>Figure</option>
                            <option value='Supplementary Information'>Supplementary Information</option>
                            <option value='Link(s) to supporting data'>Link(s) to supporting data</option>
                        </select>
                        <span className='submission-auth-uploader-container-title-2'>Description</span>
                        <input type='text' value={titlePageDescription} placeholder={titlePageDescription} className='file-input-show'/>
                       </div>
                        <input type='file' id='file' draggable='true' className='file-input' onChange={e=>handleFile(e)}/>
                        <label for='file' className='submission-auth-uploader-browse-item'>Browse...</label><span className='submission-auth-uploader-item'>Or</span>
                        <div className='submission-auth-uploader-inner-wrapper'>
                        <img className='submission-auth-uploader-inner-icon' onClick={()=>setShowMore(!showMore)} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1ElEQVR4nO2aP0sDMRjGuzq5OnWwiouIo+Dg4Kari+Kik4PQTTq4uvgdXF3cxK1D7fu8/QeCSq9JilK/ikRUznK93uUuaW3zwDPcccnbH0/yQq5XKHh5eWWWItroMe/l6aBWW3EPAlwLYJCzXwPmzVkAGTiHUSEQCbQF0b2pJdD/A0P0opfuJEDKWeaSAE0sGWUfxA2MsgSimC8EIJ3BKHuJbAmiHWcwyiKIvucMRlkGcQajLIH0iLoSeP6xIHqz2podda1I69oexGoizGui0ViPNXA19YkkkQTKHiROc5PIR6ezFABFE+uxUwMigQfTM4ke60HSam4SyVvSg4zR3CQiiG4k8GhiPTYORAKns7DZK7lBTBCkkitEEhB9klNE2yaOOgUGQDF83WUuSeDIOohN9YFlydz6qs189i9B+iGI3/pZYMZ2LeD8+zA02vX6fpqaXeaSADpR+6pn2sny2OwSuMyShBiezyQZlyD9BBDGMGO7FtGJfv0Z5x7RbpblJEY41TJzsdnTJCFMk3EB0mw2F56q1cVhK+bj0I++i3pGe2pARikgOgjVvs002ayCtLP89ZbQh9ZBXFiGUvcgrj4YiPN7q7VqJZFJKvAgU6Zg3hL5BCFfbei/2jHwAAAAAElFTkSuQmCC"></img>
                        <span className='submission-auth-uploader-drag-item'>Drag and drop files</span>
                            </div>
                        </div>
                        : <div className='submission-auth-uploader-container'>
                            <input type='file' id='file' draggable='true' className='file-input' onChange={e=>handleFile(e)}/>
                            <label for='file' className='submission-auth-uploader-browse-item'>Browse...</label><span className='submission-auth-uploader-item'>Or</span>
                            <div className='submission-auth-uploader-inner-wrapper'>
                            <img className='submission-auth-uploader-inner-icon' onClick={()=>setShowMore(!showMore)} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB1ElEQVR4nO2aP0sDMRjGuzq5OnWwiouIo+Dg4Kari+Kik4PQTTq4uvgdXF3cxK1D7fu8/QeCSq9JilK/ikRUznK93uUuaW3zwDPcccnbH0/yQq5XKHh5eWWWItroMe/l6aBWW3EPAlwLYJCzXwPmzVkAGTiHUSEQCbQF0b2pJdD/A0P0opfuJEDKWeaSAE0sGWUfxA2MsgSimC8EIJ3BKHuJbAmiHWcwyiKIvucMRlkGcQajLIH0iLoSeP6xIHqz2podda1I69oexGoizGui0ViPNXA19YkkkQTKHiROc5PIR6ezFABFE+uxUwMigQfTM4ke60HSam4SyVvSg4zR3CQiiG4k8GhiPTYORAKns7DZK7lBTBCkkitEEhB9klNE2yaOOgUGQDF83WUuSeDIOohN9YFlydz6qs189i9B+iGI3/pZYMZ2LeD8+zA02vX6fpqaXeaSADpR+6pn2sny2OwSuMyShBiezyQZlyD9BBDGMGO7FtGJfv0Z5x7RbpblJEY41TJzsdnTJCFMk3EB0mw2F56q1cVhK+bj0I++i3pGe2pARikgOgjVvs002ayCtLP89ZbQh9ZBXFiGUvcgrj4YiPN7q7VqJZFJKvAgU6Zg3hL5BCFfbei/2jHwAAAAAElFTkSuQmCC"></img>
                            <span className='submission-auth-uploader-drag-item'>Drag and drop files</span>
                                </div>
                            </div>}
                          
                        </form>
                                            <hr className='submission-horizontal-line'/>
                                            
                                          
                                        </div>
                                        </div>
                                        {
                                            items?.length>0&&<div className='submission-auth-main-container-bottom'>
                                            <p className='submission-auth-main-container-bottom-title'>The order in which the attached items appear is the order established by this publication. You may re-order any items of the same type manually if necessary.</p>
                                            <div className='submission-auth-main-container-bottom-top'>
                                                <div className='submission-auth-main-container-bottom-left'>
                                                    <span className='submission-auth-main-container-bottom-caption'>Change Item Type of all</span>
                                                    <input type='file' className='submission-auth-main-container-bottom-input' />
                                                    <span className='submission-auth-main-container-bottom-caption'>files to</span>
                                                    <input type='file' className='submission-auth-main-container-bottom-input' />
                                                    <button disabled className='disabled-bottom-btn'>Change now</button>
                                                </div>
                                                <div className='submission-auth-main-container-bottom-right'>
                                                <a  className='clear-check' onClick={()=>setMarked(items)}>Check all</a>
                                                    <a  className='clear-check'  
                                                    onClick={()=>{setItems([]);localStorage.removeItem('items')}}>Clear all</a>
                                                </div>
                                            </div>
                                            <div className='submission-auth-main-container-bottom-down'>
                 <table>
                    <thead>
                        <tr>
                            <td className='table-head-td'>Order</td>
                            <td className='table-head-td'>Item</td>
                            <td className='table-head-td'>Description</td>
                            <td className='table-head-td'>File Name</td>
                            <td className='table-head-td'>Size</td>
                            <td className='table-head-td'>last Modified</td>
                            <td className='table-head-td'>Actions</td>
                            <td className='table-head-td'>Select</td>
                        </tr>
                        {
                           items?.map((item,index)=>{
                                return(
                                    <tr  key={index}>
                            <td style={{borderLeft:'8px dashed var(--secondary-color)'}} className='table-body-td'>{index+1}</td>
                            <td className='table-body-td'><select className='submission-auth-container-selection' onChange={e=>setTitlePage(e.target.value)}>
                                <option value='*Title Page'>*Title Page</option>
                                <option value='*Blinded Manuscript'>*Blinded Manuscript</option>
                                <option value='Table'>Table</option>
                                <option value='Figure'>Figure</option>
                                <option value='Supplementary Information'>Supplementary Information</option>
                                </select></td>
                        <td className='table-body-td'><input type='text' placeholder={item.title} className='submission-auth-main-container-bottom-input' onChange={e=>setTitlePageDescription(e.target.value)}/></td>
                        <td className='table-body-td'>{item?.file.name}</td>
                        <td className='table-body-td'>{item?.file.size}</td>
                        <td className='table-body-td'>{moment(item?.file.lastModified).format('LL')}</td>
                        <td className='table-body-td'><a className='clear-check' href={`${item?.file.name}`} download>Download</a></td>
                        <td className='table-body-td'><input type='radio' onClick={()=>markedhandler(item)} /></td>
                    
                        </tr>
                                )
                            })
                        }
                    </thead>
                    <tbody>
                    <tr style={{width:'50%'}} className='table-row'>
                            <td>
                                {
                                    items?.length>1?<button className='bottom-btn' onClick={sortItems} >Update file order</button>:<button className='disabled-bottom-btn' >Update file order</button>
                                }
                            </td>
                            {marked?<td><span className='bottom-btn'  onClick={handleRemoveItem}>Remove</span></td>:<td><span className='disabled-bottom-btn' >Remove</span></td>}
                        </tr>
                    </tbody>
                 </table>
                 <div className='submission-auth-main-container-bottom-right'>
                                                    <a  className='clear-check' onClick={()=>setMarked(items)}>Check all</a>
                                                <a  className='clear-check' onClick={()=>{setItems([]);localStorage.removeItem('items')}}>Clear all</a>
                                                </div>
                                                <div className='submission-auth-main-container-bottom-bottom'>
<button className='submission-auth-main-container-bottom-bottom-back-btn' onClick={()=>{setLevel('article-selection');localStorage.setItem('level',JSON.stringify('article-selection'))}}><FaArrowLeft  />Back</button>
<button className='submission-auth-main-container-bottom-bottom-proceed-btn' onClick={handleFileAttached}>Proceed <FaArrowRight /></button>
                                                    </div>
                                            </div>
                                        </div>
                                        }
                                        
                    </div>:
                    level==='review-preferences'?
                    <div className='submission-item-body'>
            
                                        <div className='submission-auth-main-container'>
                                        <div className="submission-auth-main-inner-2">
                                        <li>
                                        Please provide the requested information.
                                        </li>
                                            </div>
                                        <div className='submission-auth-container-3'>
                                        <div className='submission-auth-container-3-top'>
                                            {reviewerOpen?<span className='submission-auth-container-3-top-item' style={{cursor:'pointer'}} onClick={()=>setReviewerOpen(!reviewerOpen)}>-</span>:
                                            <span className={reviewerOpen?'submission-auth-container-3-top-item-open':'submission-auth-container-3-top-item-close'} style={{cursor:'pointer'}} onClick={()=>setReviewerOpen(!reviewerOpen)}>+</span>}
                                            <span className={reviewerOpen?'submission-auth-container-3-top-item-open':'submission-auth-container-3-top-item-close'}>Oppose Reviewers</span>
                                            </div>
                                           {
                                            reviewerOpen&& <div className='submission-auth-container-3-bottom'>
<p className='submission-auth-container-3-bottom-item-content'>
Please identify anyone who you would prefer not to review this submission. Fill in as much contact information as possible to allow us to identify the person in our records, and provide specific reasons why each person should not review your submission in their comments box. Please note that we may need to use a reviewer that you identify here, but will try to accommodate author's wishes when we can.
</p>
<div className='submission-auth-container-opposed'>
<div className='submission-auth-container-opposed-title-container'>
<span className='submission-auth-container-opposed-title'>Currently Opposed Reviewers List</span>
<div className='submission-auth-container-opposed-title-container-right' style={{cursor:'pointer'}} onClick={()=>setOpenNewReviewer(true)}>
        <span className='submision-text-content'>+</span><span className='submision-text-content'>Add Opposed Reviewers</span>
    </div>
</div>
    {opposers.length>0?
    <ul className='submission-auth-container-3-bottom-opposed-list'>
{
    opposers?.map((item,index)=>{
        return(<li key={index} className='opposers-detail'>
                <img src={edit} className='opposers-edit-icon' onClick={()=>setOpenNewReviewer(true)}/>
            <span className='opposers-item' style={{cursor:'pointer'}} onClick={()=>toDelete(item)}><FaTrash /></span>
            <h1 className='opposers-item'>{`${item.firstName} ${item.middleName.length>0&&item.middleName} ${item.lastName}`}
            
            </h1>
            {
                item?.degree?.length>0&&<h1 className='opposers-item'>{item?.degree}</h1>
            }
            {
                item?.institution?.length>0&&<h1 className='opposers-item'>{item?.institution}</h1>
            }
            
        </li>)
    })
}
    </ul>:
    <>
    <p className='submission-auth-container-3-bottom-item-content-down'>There are currently no Opposed Reviewers in the list.</p>
    <div className='submission-auth-container-3-bottom-item-content-bottom'>
        <div className='submission-auth-container-3-bottom-item-content-bottom-left' style={{cursor:'pointer'}}  onClick={()=>setOpenNewReviewer(true)}>
        <span style={{fontSize:'20px'}}>+</span><span className='submision-text-content'>Add Opposed Reviewers</span>
        </div>
        <div className='submission-auth-container-3-bottom-item-content-bottom-right'>
l
        </div>
        
    </div>
    </>
    
    }
    
    </div>
    </div>
                                           }
                                            </div>
                                        </div>
                                        <div className='submission-auth-btn-group-5'>
            <button className='submission-auth-btn-left-wrapper' onClick={()=>{setLevel('file-attachment');localStorage.setItem('level',JSON.stringify('review-preferences'))}}>
                <FaArrowLeft/>Back
            </button>
            <button className='submission-auth-btn-right-wrapper' onClick={()=>{setLevel('additional-information');setConfirmedOpposers(true);localStorage.setItem('level',JSON.stringify('additional-information'))}}>
                Proceed
                <FaArrowRight/>
            </button>
        </div>  
                    </div>:
                    level==='additional-information'?
                    <div className='submission-item-body'>
            
                                        <div className='submission-auth-main-container'>
                                        <div className="submission-auth-main-inner-2">
                                        <li>
                                        Please provide the requested information.
                                        </li>
                                            </div>
                                        <div className='submission-auth-container-3'>
                                        <div className='submission-auth-container-3-top'>
                                            <span className='submission-auth-container-3-top-item-open'>Additional Information</span>
                                            </div>
                                             <div className='submission-auth-container-4-bottom'>
<div className='submission-auth-container-4-bottom-card'>
    <h1 className='submission-auth-container-4-bottom-card-title'>Covering letter concerning your manuscript</h1>
    <a href='about:blank#blocked' className='submission-auth-container-4-bottom-card-instructions'>Instructions</a>
    <div className='submission-auth-container-4-bottom-card-bottom'>
        <span className='submission-auth-container-4-bottom-card-required'>Answer Required:</span>
<div className='submission-auth-container-4-bottom-card-form-container'>
    <h1 className={answerRequired.length>3000?'submission-auth-container-4-bottom-card-title-over':'submission-auth-container-4-bottom-card-title'}>Character Count: {answerRequired.length}</h1>
<form className='submission-auth-container-4-bottom-card-form'>
            <textarea onChange={e=>setAnswerRequired(e.target.value)} className='submission-auth-container-4-bottom-card-bottom-textarea' />
            <p className='submission-auth-container-4-bottom-card-form-content'>
Limit 300 characters
</p>
        </form>
        </div>
    </div>
    </div>

    <div className='submission-auth-container-4-bottom-card'>
    <h1 className='submission-auth-container-4-bottom-card-title'>Declarations</h1>
    <p className='submission-auth-container-4-bottom-card-info'>Have you included a 'Declarations' section in your manuscript including all of the subheadings listed below and the relevant information under each?</p>
    <ul className='submission-auth-container-4-bottom-card-guide-container'>
<li className='submission-auth-container-4-bottom-card-guide-item'>Availability of data and material</li>
<li className='submission-auth-container-4-bottom-card-guide-item'>Competing interests</li>
<li className='submission-auth-container-4-bottom-card-guide-item'>Funding</li>
<li className='submission-auth-container-4-bottom-card-guide-item'>Authors' contributions</li>
<li className='submission-auth-container-4-bottom-card-guide-item'>Acknowledgements</li>
    </ul>
  <div className='submission-auth-container-4-bottom-card-bottom-wrapper'>
  <p className='submission-auth-container-4-bottom-card-info'>
    Click <a href='https://www.springeropen.com/about/declarations'>here</a> for information on what should be included under each heading.
    </p>

<p className='submission-auth-container-4-bottom-card-info'>
Please use the 'Contact Us' link above if you require further assistance.
</p>
    </div>
    <div className='submission-auth-container-4-bottom-card-bottom'>
        <span className='submission-auth-container-4-bottom-card-required'>Answer Required:</span>
<div className='submission-auth-container-4-bottom-card-form-container'>
<div className='submission-auth-container-4-bottom-card-form-bottom'>
    <select  onChange={e=>setResponse(e.target.value)} className='submission-auth-container-4-bottom-card-form-bottom-select'>
        <option>Please select a response</option>
        <option value="I confirm I have provided a complete 'Declarations' section in my manuscript">I confirm I have provided a complete 'Declarations' section in my manuscript</option>
    </select>
    {
        response.length<1&&<p className='submission-auth-container-4-bottom-card-form-content'>
        Please select a response.
        </p>
    }

        </div>
        </div>
    </div>
    </div>

    <div className='submission-auth-container-4-bottom-card'>
    <h1 className='submission-auth-container-4-bottom-card-title'>Open Access</h1>
    <p className='submission-auth-container-4-bottom-card-info'>This journal is an open access journal that levies an article processing charge (APC) for articles accepted for publication. View the APC charges for this journal (opens in a new window).
If your article is accepted you will need to pay the APC. You have some options to help you with the cost.</p>
    <ul className='submission-auth-container-4-bottom-card-guide-container'>
<li className='submission-auth-container-4-bottom-card-guide-item'>The APC for your article may be covered by one of Springer Nature’s fully open access agreements or by a journal partner. We will check this for you if your article is accepted for publication.</li>
<li className='submission-auth-container-4-bottom-card-guide-item'>If your research is funded by a grant, check with your research funder to see if you already have funds for APC in your grant.</li>
<li className='submission-auth-container-4-bottom-card-guide-item'>Springer Nature can help you look for funding to pay an APC.</li>
    </ul>
  <div className='submission-auth-container-4-bottom-card-bottom-wrapper'>
  <p className='submission-auth-container-4-bottom-card-info'>
    Click <a href='https://www.springeropen.com/about/declarations'>here</a> for information on what should be included under each heading.
    </p>

<p className='submission-auth-container-4-bottom-card-info'>
Read more about getting help with APCs in our <a href='https://www.springernature.com/gp/open-research/funding'>guide to funding and support services (opens in a new window).</a>
</p>
    </div>
    <div className='submission-auth-container-4-bottom-card-bottom'>
        <span className='submission-auth-container-4-bottom-card-required'>Answer Required:</span>
<div className='submission-auth-container-4-bottom-card-form-container'>
<form className='submission-auth-container-4-bottom-card-form-bottom'>
<label className='ratio-input-label' >
<input type='radio' checked={response.length<1?'false':'true'} /><span className='submision-text-content'>Please select a response</span>
</label>
<label className='ratio-input-label' onClick={()=>setAgreement(true)}>
    <input type='radio' checked={!agreement?'false':'true'} /><span className='submision-text-content'>By submitting my article I agree to pay the APC in full if my article is accepted for publication (unless it is covered by an institutional agreement or journal partner, or a full waiver has been granted).</span>
    </label>
    
    {
        response.length<1&&<p className='submission-auth-container-4-bottom-card-form-content'>
        Please select a response.
        </p>
    }

        </form>
        </div>
    </div>
    </div>
    <div className='submission-auth-container-4-bottom-card'>
    <h1 className='submission-auth-container-4-bottom-card-title'>Discounts or waivers in cases of financial hardship or if you are from a low or lower-middle-income country</h1>
    <p className='submission-auth-container-4-bottom-card-info'>
    If no funding is available to you, the corresponding author may apply for a discount or waiver. They will have the opportunity to request a discount shortly after you complete your submission by indicating it below. Discount and waiver requests must be made at the point of submission. The journal’s editorial team will not have access to your discount request and it will not play any part in the editorial decision made on your article. The corresponding author will be asked to provide evidence to support their request, including details of any funding to date (this does not apply to authors covered by our <a href='https://www.springernature.com/gp/open-research/policies/journal-policies/apc-waiver-countries'>policies for low- and lower-middle income countries (opens in a new window).</a>
    </p>
    <div className='submission-auth-container-4-bottom-card-bottom'>
        <span className='submission-auth-container-4-bottom-card-required'>Answer Required:</span>
<div className='submission-auth-container-4-bottom-card-form-container'>
<form className='submission-auth-container-4-bottom-card-form-bottom'>
<select onChange={e=>setPolicy(e.target.value)} className='submission-auth-container-4-bottom-card-form-bottom-select'>
    <option value='Please select a response'>Please select a response</option>
    <option value="I want to learn more about Springer Nature's APC discount or waiver policies">I want to learn more about Springer Nature's APC discount or waiver policies</option>
    <option value="I do not want to learn more about Springer Nature's APC discount or waiver policies">I do not want to learn more about Springer Nature's APC discount or waiver policies</option>
</select>
{
    policy==='Please select a response'?<p className='submission-auth-container-4-bottom-card-form-content'>Please select a response</p>:
    policy==="I do not want to learn more about Springer Nature's APC discount or waiver policies"||policy==="I want to learn more about Springer Nature's APC discount or waiver policies"
}

        </form>
        </div>
    </div>
    </div>
                         </div>                   
                         <div className='submission-auth-btn-group-5'>
            <button className='submission-auth-btn-right-wrapper' onClick={()=>{setLevel('review-preferences');localStorage.setItem('level',JSON.stringify('review-preferences'))}}>
                <FaArrowLeft/>Back
            </button>
            <button className='submission-auth-btn-right-wrapper' onClick={()=>{setLevel('manuscript-data');localStorage.setItem('level',JSON.stringify('manuscript-data'))}}>
                Proceed<FaArrowRight/>
            </button>
        </div>       
                         </div>
                         
                                        </div>
                          
                    </div>:
                    level==='manuscript-data'&&!nexted?
                    <form className='submission-item-body' onSubmit={handleSubmitManuscript}>
                                        <div className='submission-auth-main-container-5'>
                                        <div className="submission-auth-main-inner-2">
                                        <li>
                                        When possible these fields will be populated with information collected from your uploaded submission file. Steps requiring review will be marked with a warning icon. Please review these fields to be sure we found the correct information and fill in any missing details.
                                        </li>
                                            </div>
                                       <div className='submission-auth-container-5-main'>
                                       <div className='submission-auth-container-3'>
                                       {newState.data.length>0&&<p className={newState.status==='success'?'submission-success':'submission-falied'}>{newState.data}</p>}
                                        <div className={manuscriptOpen1?'submission-auth-container-5-top':'submission-auth-container-5-top-closed'}  onClick={()=>setManuscriptOpen1(!manuscriptOpen1)}>
                                            {manuscriptOpen1?<span className='submission-auth-container-3-top-item' style={{cursor:'pointer'}}>-</span>:
                                            <span className={manuscriptOpen1?'submission-auth-container-3-top-item-open':'submission-auth-container-3-top-item-close'} style={{cursor:'pointer'}} onClick={()=>setManuscriptOpen1(!manuscriptOpen1)}>+</span>}
                                            <span className={manuscriptOpen1?'submission-auth-container-3-top-item-open':'submission-auth-container-3-top-item-close'}>Title</span>
                                            </div>
                                           {
                                            manuscriptOpen1&&
                                            <div className='submission-auth-container-3-bottom'>
<div className='submission-auth-container-opposed'>
    {opposers.length>0?
    <ul className='submission-auth-container-3-bottom-opposed-list'>
        <div className='manuscript-form'>
            <div className='input-wrapper'>
            <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',gap:'5px'}}><h1 style={{textAlign:'left',fontSize:'16px',fontWeight:'bold'}}>Full Title</h1><p style={{fontSize:'12px'}}>(required)</p><span style={{color:'var(--red-color)',fontSize:'14px'}}>*</span></div>
            <textarea className='input-wrapper-field' type='text' value={manuscriptTitle} onChange={saveManuscriptTitle} />
            <button className='submission-auth-btn-right-wrapper' style={{alignSelf:'flex-end'}} onClick={()=>{setManuscriptOpen1(false);setManuscriptOpen2(true)}}>Next</button>
            </div>
            
        </div>
    </ul>:
    null
    
    }
    
    </div>
    </div>
                                           }
                                            </div>
                                            <div className='submission-auth-container-3'>
                                        <div className={manuscriptOpen2?'submission-auth-container-5-top':'submission-auth-container-5-top-closed'} onClick={()=>setManuscriptOpen2(!manuscriptOpen2)}>
                                            {manuscriptOpen2?<span className='submission-auth-container-3-top-item' style={{cursor:'pointer'}}>-</span>:
                                            <span className={manuscriptOpen2?'submission-auth-container-3-top-item-open':'submission-auth-container-3-top-item-close'} style={{cursor:'pointer'}} onClick={()=>setManuscriptOpen2(!manuscriptOpen2)}>+</span>}
                                            <span className={manuscriptOpen2?'submission-auth-container-3-top-item-open':'submission-auth-container-3-top-item-close'}>Abstract</span>
                                            </div>
                                           {
                                            manuscriptOpen2&&
                                            <div className='submission-auth-container-3-bottom'>
<div className='submission-auth-container-opposed'>
    {opposers.length>0?
    <ul className='submission-auth-container-3-bottom-opposed-list'>
        <div className='manuscript-form'>
            <div className='input-wrapper'>
            <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',width:'100%',gap:'5px'}}><h1 style={{textAlign:'left',fontSize:'16px',fontWeight:'bold'}}>Abstract</h1><p style={{fontSize:'12px'}}>(required)</p><span style={{color:'var(--red-color)',fontSize:'14px'}}>*</span></div>
            <input className='input-wrapper-field-abstract' type='file' value={manuscriptAbstract.name} onChange={saveManuscriptAbstract} />
            <button className='submission-auth-btn-right-wrapper' style={{alignSelf:'flex-end'}} onClick={()=>{setManuscriptOpen2(false);setManuscriptOpen3(true)}}>Next</button>
            </div>
        </div>
    </ul>:
    null
    
    }
    
    </div>
    </div>
                                           }
                                            </div>
                                            <div className='submission-auth-container-3'>
                                            <div className={manuscriptOpen3?'submission-auth-container-5-top':'submission-auth-container-5-top-closed'} onClick={()=>setManuscriptOpen3(!manuscriptOpen3)}>
                                            {manuscriptOpen3?<span className='submission-auth-container-3-top-item' style={{cursor:'pointer'}}>-</span>:
                                            <span className={manuscriptOpen3?'submission-auth-container-3-top-item-open':'submission-auth-container-3-top-item-close'} style={{cursor:'pointer'}} onClick={()=>setManuscriptOpen3(!manuscriptOpen3)}>+</span>}
                                            <span className={manuscriptOpen3?'submission-auth-container-3-top-item-open':'submission-auth-container-3-top-item-close'}>Authors</span>
                                            </div>
                                           {
                                            manuscriptOpen3&&
                                            <div className='submission-auth-container-3-bottom'>
                                                <div className='submission-auth-container-opposed'>
    {opposers.length>0?
    <ul className='submission-auth-container-3-bottom-opposed-list'>
        <div className='manuscript-form'>
            <div className='input-wrapper'>
            <textarea className='input-wrapper-field' type='text' value={manuscriptAuthors} onChange={saveManuscriptAuthors} />
            <button className='submission-auth-btn-right-wrapper' style={{alignSelf:'flex-end'}} onClick={()=>{setManuscriptOpen3(false);setManuscriptOpen4(true)}}>Next</button>
            </div>
        </div>
    </ul>:
    null
    
    }
    
    </div>
    </div>
                                           }
                                            </div>

                                            <div className='submission-auth-container-3'>
                                            <div className={manuscriptOpen4?'submission-auth-container-5-top':'submission-auth-container-5-top-closed'} onClick={()=>setManuscriptOpen4(!manuscriptOpen4)}>
                                            {manuscriptOpen4?<span className='submission-auth-container-3-top-item' style={{cursor:'pointer'}}>-</span>:
                                            <span className={manuscriptOpen4?'submission-auth-container-3-top-item-open':'submission-auth-container-3-top-item-close'} style={{cursor:'pointer'}} onClick={()=>setManuscriptOpen4(!manuscriptOpen4)}>+</span>}
                                            <span className={manuscriptOpen4?'submission-auth-container-3-top-item-open':'submission-auth-container-3-top-item-close'}>Funding Information</span>
                                            </div>
                                           {
                                            manuscriptOpen4&&
                                            <div className='submission-auth-container-3-bottom'>
   <div className='submission-auth-container-opposed'>
    {opposers.length>0?
    <ul className='submission-auth-container-3-bottom-opposed-list'>
        <div className='manuscript-form'>
            <div className='input-wrapper'>
            <textarea className='input-wrapper-field' type='text' value={manuscriptFunding} onChange={saveManuscriptFunding} />
            <button className='submission-auth-btn-right-wrapper' style={{alignSelf:'flex-end'}} onClick={()=>{setManuscriptOpen4(false);}}>Next</button>
            </div>
        </div>
    </ul>:
    null
    
    }
    
    </div>
    </div>
    
                                           }
                                            </div>
                                        </div>
                                            
                                        </div>
                                       {
                                        loading?<div className='submission-auth-btn-group-5'><FaSpinner/></div>:
                                        <div className='submission-auth-btn-group-5'>
                                        <button className='submission-auth-btn-right-wrapper' onClick={()=>{setLevel('additional-information');localStorage.setItem('level',JSON.stringify('review-preferences'))}}>
                                            <FaArrowLeft className='confirmation-body-inner-top-icon'/>Back
                                        </button>
                                        <button className='submission-auth-btn-right-wrapper' onClick={handleSaveManuscript}>
                                            Save & Submit Later
                                        </button>
                                        <button className='submission-auth-btn-right-wrapper' type='submit' >
                                            Build PDF for Approval
                                            <FaArrowRight className='confirmation-body-inner-top-icon'/>
                                        </button>
                                    </div>  
                                       }
                    </form>:
                       <div className='pdf-container'>
                        {loadingManuscript?<FaSpinner/>:
                        <div className='pdf-container-inner-wrapper'>
                        <a className='download-link' onClick={()=>setNexted(false)}>Back</a>
                       {
                        !send?<button className='download-link' onClick={()=>{setSend(true)}}>Preview</button>:
                        <a href={pdfInfo} download='PDF File' className='download-link' onClick={createManuscript}>Download PDF & Continue</a>
                       }
                        </div>}
   
       <iframe title="test-frame" src={pdfInfo} type="application/pdf" className='pdf-iframe' />
                       </div>
                    }
                   
                
        </div>
</>

}
SubmissionOne.propTypes = {};

export { SubmissionOne };