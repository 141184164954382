import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './StepFour.css'
import siteInfo from '../../../shopping/images/springer-ecomm-header-logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { url2 } from '../../../Redux/Api';
import { FaAngellist } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { EditorialHeader } from './EditorialHeader';


const StepFour = props => {
const[firstName, setFirstName] = useState('')
const[lastName, setLastName] = useState('')
const[email, setEmail] = useState('')
const[sent, setSent] = useState(false)
const[logger, setLogger] = useState('');
const[error, setError] = useState({err:'',hint:''});
const[captured, setCaptured] = useState('');
const[lastMailTried, setLastMailTried] = useState('');
const[isLoading, setIsLoading] = useState(false);
const[passwordReset, setPasswordReset] = useState('');
const[newState, setNewState] = useState({status:'',data:''});

const[tokenValue, setTokenValue] = useState('')
const[mail, setMail] = useState('')

const navigate = useNavigate()
    
    const handlePasswordReset = async(e)=>{
    e.preventDefault()
    setSent(true)
    setIsLoading(true)
   await fetch(`${url2}/password-reset`,{
    mode:'cors',
        method:'post',
        headers:{
            'Content-Type':'application/json'
        },
        body:JSON.stringify({
            email
        })
    }).then(res=>res.json())
    .then((resp)=>{
        if(resp.mailData){
            setMail(resp.mailData)
            setIsLoading(false)
            setNewState({status:'success',data:`Complete this process by clicking the link sent to ${email}.`})
        }else if(resp.err){
            setNewState({status:'failed',data:resp.err})
            setIsLoading(false)
        }
    }).catch(err=>{
        setNewState({status:'failed',data:err.message})
        setIsLoading(false)
    })

    }
    const {pub} = useParams();
const currentItem = useSelector(state=>state.journal.journal)

return(
    <>
    <div className='editorial-register-main'>
    <EditorialHeader/>

{
    passwordReset?
    <div className='editorial-step-four-site-body'>
    <div className='editorial-step-four-form'>
    <div className='choose-register-method-top'>
    <span className='step-four-text-method-top'>Choose a Registration Method</span>
    </div>
    <div className='choose-register-method'>
    <span className='step-four-text-method'>Choose a Registration Method</span>
    </div>
    <div className='step-four-input-inner-wrapper'>
    <div className='step-four-input-and-text-wrapper'>
    <div className='step-four-input-wrapper'><div className='step-four-text-input-wrapper'><span className='step-four-required-text'>E-mail Address*</span></div><input type='email' onChange={(e)=>setEmail(e.target.value)} className='pre-editorial-login-input' /></div>
    </div>
    <div className='step-four-btn-container'>
    {
        lastMailTried.length>0?<button onClick={()=>navigate(`/${currentItem?.acronym?.toLowerCase()}/default.aspx/login`)} className='step-four-form-btn' >Login instead</button>:<button  className='step-four-form-btn' onClick={handlePasswordReset}><span>Reset password</span> <span>>></span></button>
    }
    </div>
    </div>
</div>
<div className='editorial-register-site-body-right'></div>
</div>
    :
    <div className='editorial-step-four-site-body'>
        <h2 className='reg-complete-title'>Registration Complete</h2>

    <div className='editorial-step-four-form'>
    
<hr style={{color:'red'}} />
<div className='step-four-info-wrapper'>
    <p className='step-three-info'>Check your email for a message to confirm your registration. Note that editorial manager may have assigned a different Username if the one  you selected is already in use.</p>
</div>
<hr style={{color:'red'}} />
<div className='step-four-btn-group'>
    <button  className='step-four-form-btn' onClick={()=>navigate(`/${currentItem?.acronym?.toLowerCase()}/default.aspx/login`)}>Go to Login Page</button>
</div>
</div>
</div>
}
    </div>
    </>
    
    );
};

StepFour.propTypes = {};

export { StepFour };