import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Link} from 'react-router-dom';

const Page404 = props => {
    return(
        <div className='editorial-404-main' style={{display:'flex',background:'#eee',justifyContent:'flex-start',alignItems:'center',flexDirection:'column',height:'100vh',width:'100%',margin:'auto'}}>
            <span style={{color:'#fff',backgroundColor:'#333',padding:'1px 4px',width:'100%'}}>Server error</span>
       <div style={{backgroundColor:'#fff',padding:'25px',width:'95%'}}>
       <div className='editorial-404-inner'style={{border:'thin solid var(--primary-color)',width:'100%',margin:'auto',display:'flex',justifyContent:'center',gap:'0px',alignItems:'flex-start',flexDirection:'column',padding:'5px'}}>
            <p style={{fontSize:'14px',fontWeight:'bold',color:'var(--red-color)',wordSpacing:0,fontWeight:'bolder',lineHeight:1}}>404 - File or directory not found.</p>
            <p style={{fontSize:'14px',fontWeight:'bold',color:'var(--primary-color)',wordSpacing:0,lineHeight:1}}>The resources you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
        </div>
       </div>
        </div>
    );
};

Page404.propTypes = {};

export { Page404 };