import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    journal:localStorage.getItem('journal')&&JSON.parse(localStorage.getItem('journal')),
    status:''
}
const journalSlice = createSlice({
    name:'journal',
    initialState,
    reducers:{
        loadJournal(state,action){
            const item = action.payload;
            if(item){
                localStorage.setItem('journal',JSON.stringify(item))
                return {
                    journal:item,
                    loadJournal:true
                }
            }

        }
    }
})

export const {loadJournal} = journalSlice.actions;
export default journalSlice.reducer