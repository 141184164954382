export const url = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_Springer_RemoteServer : process.env.REACT_APP_Springer_LocalServer;
export const url1 = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_Springernature_RemoteServer : process.env.REACT_APP_Springernature_LocalServer;
export const url2 = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_Editorial_RemoteServer : process.env.REACT_APP_Editorial_LocalServer;

export const clientUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_Springer_RemoteClient : process.env.REACT_APP_Springer_LocalClient;
export const clientUrl1 = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_Springernature_RemoteClient : process.env.REACT_APP_Springernature_LocalClient;
export const clientUrl2 = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_Editorial_RemoteClient : process.env.REACT_APP_Editorial_LocalClient;
export const publicKey = process.env.REACT_APP_PublicKey;
export const secreteKey = process.env.REACT_APP_SecreteKey;


export const setHeader = () => {
    const headers = {
        headers: { 'Content-Type': 'application/json' }
    }
    return headers
}

export const setAuthHeader = () => {
    const headers = {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')) }
    }
    return headers
}