import {Routes, BrowserRouter, Route, Navigate, useParams} from 'react-router-dom'
//import Home from './Pages/Home/Home'
// import { Shopping } from './shopping/Shopping'
// import { CartItem } from './shopping/CartItem'
// import { Cart } from './shopping/Cart'
//import { Checkout } from './shopping/Checkout'
import { Dashboard } from './shopping/dashboard'
import { EditorialLogin } from './Pages/Home/editorial/editorialLogin'
import { UserDashboard } from './Pages/Home/editorial/UserDashboard'
//import { SecondEditorialLogin } from './Pages/Home/editorial/SecodnEditorialLogin'
//import { EditorialRegister } from './Pages/Home/editorial/EditorialRegister'
//import { EditorialJournalHome } from './Pages/Home/editorial/EditorialJournalHome'
//import { EditorialLoginOptions } from './Pages/Home/editorial/EditorialLoginOptions'
import { EditorialPreRegister } from './Pages/Home/editorial/EditorialPreregister'
import { MainPreRegister } from './Pages/Home/editorial/MainRegister'
import { Submission } from './Pages/Home/editorial/Submission'
import { EditorialPasswordReset } from './Pages/Home/editorial/EditorialPasswordReset'
import { url2 } from './Redux/Api'
import { useEffect, useState } from 'react'
import { StepThree } from './Pages/Home/editorial/StepThree'
import { StepFour } from './Pages/Home/editorial/StepFour'
import { Page404 } from './Pages/Home/editorial/404'
import { AltContactInfo } from './Pages/Home/editorial/AltContactInfo'
import { SubmissionOne } from './Pages/Home/editorial/SubmissionOne'
import { UpdateInfo } from './Pages/Home/editorial/UpdateInfo';
import { useSelector } from 'react-redux'
import { ConfirmationDashboard } from './Pages/Home/editorial/ConfirmationDashboard'
import { WaitingApprovals } from './Pages/Home/editorial/WatingApprovals'
import { PasswordReset } from './Pages/Home/editorial/Password/PasswordReset'
import { PasswordChange } from './Pages/Home/editorial/Password/PasswordChange'
import { Incomplete } from './Pages/Home/editorial/Incomplete'
import { RejectedSubmissions } from './Pages/Home/editorial/RejectedSubmissions'
import PDFPage from './PDFpage'
import {Testing} from './Testing'
import { StepFive } from './Pages/Home/editorial/StepFive'
import { SubmissionOneEdit } from './Pages/Home/editorial/SubmissionOneEdit';
import { Merger } from './Tester'
import { ProcessedManuscripts } from './Pages/Home/editorial/ProcessedManuscripts'
import { ConfirmationPrompt } from './Pages/Home/editorial/ConfirmationPrompt'
import { DecisionManuscripts } from './Pages/Home/editorial/DecisionManuscripts'
import { ProductionManuscripts } from './Pages/Home/editorial/ProductionManuscript'


const App = ()=>{
  const user = useSelector(state=>state.auth.user);
  const currentItem = useSelector(state=>state.journal.journal);
  const {acronym} = useParams()
  const[error, setError] = useState({err:'',hint:''});

  useEffect(()=>{
    async function name() {
        await fetch(`${url2}/books`,{
            mode:'cors',
            method:'get',
            headers:{
                'Content-Type':'application/json'
            }
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                localStorage.setItem('books',JSON.stringify(resp.resp));
            }
        }).catch(err=>setError(err.message))
    }
    name(); 
    },[]);

  return(
    <BrowserRouter>
    <Routes>
    <Route path= '/:acronym' element={<UserDashboard/>} />
    <Route path= '/:acronym/default.aspx/login' element={!user?<EditorialLogin/>:<Navigate to={`/${currentItem?.acronym}`} />} />
    <Route path= '/:acronym/default.aspx' element={!user?<Navigate to={`/${currentItem?.acronym}/default.aspx/login`} />:<SubmissionOne/>} />
    <Route path= '/:acronym/:manuscriptIdInit/resume-submission' element={!user?<Navigate to={`/${currentItem?.acronym}/default.aspx/login`} />:<SubmissionOneEdit/>} />
    <Route path="/:acronym/default.aspx/register" element={!user?<EditorialPreRegister/>:<Navigate to={`/${currentItem?.acronym}`}/>} />
    <Route path="/:acronym/default.aspx/checked" element={!user?<StepThree/>:<Navigate to={`/${currentItem?.acronym}`}/>} />
    <Route path="/:acronym/default.aspx2/checked" element={!user?<StepFive/>:<Navigate to={`/${currentItem?.acronym}`}/>} />
    <Route path="/:acronym/default.aspx-complete" element={<StepFour/>}/>
    <Route path="/dashboard" element={user?.isAdmin?<Dashboard/>:<Navigate to={`/${currentItem?.acronym}/default.aspx/login`}/>}/>
    <Route path="/:acronym/rejected-submissions" element={user?<RejectedSubmissions/>:<Navigate to={`/${currentItem?.acronym}/default.aspx/login`}/>}/>
    <Route path="/:acronym/incomplete-submission" element={user?<Incomplete/>:<Navigate to={`/${currentItem?.acronym}/default.aspx/login`}/>}/>
    <Route path="/:acronym/decision-manuscripts" element={user?<DecisionManuscripts/>:<Navigate to={`/${currentItem?.acronym}/default.aspx/login`}/>}/>
    <Route path="/:acronym/processed-manuscripts" element={user?<ProcessedManuscripts/>:<Navigate to={`/${currentItem?.acronym}/default.aspx/login`}/>}/>
    <Route path="/:acronym/production-manuscripts" element={user?<ProductionManuscripts/>:<Navigate to={`/${currentItem?.acronym}/default.aspx/login`}/>}/>
    <Route path="/:acronym/confirmation" element={user?<ConfirmationDashboard />:<Navigate to={`/${currentItem?.acronym}/default.aspx/login`}/>}/>
    {/* <Route path="/:acronym/:manuid/approvals" element={user?<WaitingApprovals/>:<Navigate to={`/${currentItem?.acronym}/default.aspx/login`}/>}/> */}
    <Route path="/:acronym/:title/approvals-prompt" element={!user?<ConfirmationPrompt/>:<Navigate to={`/${currentItem?.acronym}/default.aspx/login`}/>}/>
    <Route path="/:acronym/default.aspx/:email/register" element={!user?<MainPreRegister/>:<Navigate to={`/${currentItem?.acronym}`}/>} />
    <Route path="/:acronym/l.asp" element={!user?<PasswordReset/>:<Navigate to={`/${currentItem?.acronym}`}/>} />
    <Route path="/:acronym/:email/:pub/:token/default.aspx/rp" element={!user?<PasswordChange/>:<Navigate to={`/${currentItem?.acronym}`}/>} />
    <Route path= '/:acronym/contact-info' element={user?<AltContactInfo/>:<Navigate to={`/${currentItem?.acronym}/default.aspx/login`}/>}/>
    <Route path= '/manuscript-services/:acronym' element={user?<Page404/>:<Navigate to={`/${currentItem?.acronym}/default.aspx/login`}/>}/>
    <Route path= '/:acronym/info_update.asp' element={user?<UpdateInfo/>:<Navigate to={`/${currentItem?.acronym}/default.aspx/login`}/>}/>
    <Route path= '/download' element={<PDFPage/>}/>
    <Route path='/test' element={<Testing />} />
    <Route path='/test/merge' element={<Merger />} />
    </Routes>
    </BrowserRouter>
  )
}
export default App