import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './PublishersDashboard.css'
import { FaArrowLeft, FaBars, FaBoxOpen, FaPlus, FaTimes } from 'react-icons/fa';
import { url } from '../Redux/Api';
import { useSelector } from 'react-redux';

const UsersDashboard = props => {
    const users = useSelector(state=>state.users?.items);
    const[newState, setNewState] = useState({status:'',data:''})
    const[title, setTitle] = useState('')
    const[description, setDescription] = useState('')
    const[overView, setOverview] = useState('')
    const[model, setModel] = useState('')
    const[type, setType] = useState('')
    const[impactFactor, setImpactFactor] = useState('')
    const[impactYear, setImpactYear] = useState('')
    const[submission, setSubmission] = useState('')
    const[downloads, setDownloads] = useState('')
    const[printISN, setPrintISN] = useState('')
    const[electronicISN, setElectronicISN] = useState('')


    const handleDelete = async(item)=>{
        async function fetchData(){
            await fetch(`${url}/delete-user/${item._id}`,{
                method:'delete',
                mode:'cors',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    setNewState({status:'success',data:resp.resp})
                return window.location.reload();
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }
    const handleUnpublish = async(item)=>{
        async function fetchData(){
            await fetch(`${url}/unpublish-manuscript/${item._id}`,{
                method:'put',
                mode:'cors',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    setNewState({status:'success',data:resp.resp})
                return window.location.reload();
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }
    const handlePublish = async(item)=>{
        async function fetchData(){
            await fetch(`${url}/publish-manuscript/${item._id}`,{
                method:'put',
                mode:'cors',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    setNewState({status:'success',data:resp.resp})
                return window.location.reload();
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }


    const approve = async(item)=>{
        async function fetchData(){
            await fetch(`${url}/approve-manuscript/${item._id}`,{
                method:'put',
                mode:'cors',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    setNewState({status:'success',data:resp.resp})
                return window.location.reload();
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }
    const disApprove = async(item)=>{
        async function fetchData(){
            await fetch(`${url}/disapprove-manuscript/${item._id}`,{
                method:'put',
                mode:'cors',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    setNewState({status:'success',data:resp.resp})
                return window.location.reload();
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }

    const[publisher, setPublisher] = useState(null)

    return <div className='admin-dashboard-child-container'>
        <span className={newState.status==='success'?'publisher-detail-success':'publisher-detail-error'}>{newState.data}</span>
{
    publisher?
    <div className='publisher-detail'>
    <div className='publishers-main-dashboard-detail'>
                    <div className='author-container-dashboard'>
                    <h1 className={publisher.approved==='approved'?'approved':publisher.approved==='pending'?'pending':'rejected'}>
                    {publisher.approved==='approved'?'Approved':publisher.approved==='pending'?'Pending':publisher.approved==='rejected'?'Rejected':''}
                    </h1>
                    <h1 className='author-title-dashboard'>{publisher.firstName}</h1>
                    <h1 className='author-city-dashboard'>{publisher.institution}</h1>
                    <h1 className='author-city-dashboard'>{publisher.position}</h1>
                    <h1 className='author-city-dashboard'>{publisher.email}</h1>
                    </div>
                    <div className='book-container-dashboard'>
                    <FaTimes className='icon' onClick={()=>setPublisher(null)}/>
                    <button className='action-btn' onClick={()=>handleDelete(publisher)}>Delete {publisher.userName}</button>
                    </div>
                </div>
</div>:
<div className='publishers-main'>
    <h2 className='publishers-page-title'>{users?.length}{users?.length>1?' Users':' User'}</h2>
    <div className='main-items-container'>
    {
        users?.length>0&&users?.map((item,index)=>{
            return(
                <div className='publishers-main-dashboard' key={index}>
                    <div className='author-container-dashboard'>
                    <h1 className='author-city-dashboard'>{`${item.lastName} ${item.firstName}`}</h1>
                    <h1 className='author-city-dashboard'>{`${item.country.label}`}</h1>
                    </div>
                    <div className='book-container-dashboard'>
                    <FaBars className='icon' onClick={()=>setPublisher(item)}/>
                    </div>
                </div>

            )
        })
    }
    </div>
    </div>
}
    </div>

};

UsersDashboard.propTypes = {};

export { UsersDashboard };