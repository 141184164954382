import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios'
import { setAuthHeader } from "../Api";
import { url2 } from "../Api";

const initialState = {
    item:null,
    status:'',
    error:''
}

export const createNewManuscript = createAsyncThunk(
    'manuscript/createNewManuscript',
    async (data,{rejectWithValue})=>{
        try {
            const response = await axios.post(`${url2}/create-manuscript`,setAuthHeader(),{
            data:data.data,
            upload:data.upload
            })
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
);
const manuscriptSlice = createSlice({
    name:'manuscript',
    initialState,
    reducers:{
        loadManuscript(state,action){
            const item = action.payload;
            if(item){
                return {
                    ...state,
                    item:item,
                    loadManuscript:true
                }
            }

        }
    },
    extraReducers:(builder)=>{
        builder.addCase(createNewManuscript.pending,(state,action)=>{
            return {...state,status:'pending'}
        })
        builder.addCase(createNewManuscript.fulfilled,(state,action)=>{
            if(action.payload){
                return {...state,item:action.payload,status:'success'}
            }else{
                return state
            }
        })
        builder.addCase(createNewManuscript.rejected,(state,action)=>{
            return {...state,status:'rejected',error:action.payload}
        })
        builder.addCase(userApproveManuscript.pending,(state,action)=>{
            return {...state, status:'pending'}
        })
        builder.addCase(userApproveManuscript.fulfilled,(state,action)=>{
            if(action.payload){
                return {...state,item:action.payload,status:'success'}
            }else{
                return state
            }
        })
    }
})
export const userApproveManuscript = createAsyncThunk(
    'manuscript/approveManuscript',
    async(data,{rejectWithValue})=>{
        try {
            const response = await axios.post(`${url2}/approve-manuscript/${data._id}`,setAuthHeader(),{
                manuId:data.manuId,
            })
             return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
);
export const adminApproveManuscript = createAsyncThunk(
    'manuscript/adminApproveManuscript',
    async(data,{rejectWithValue})=>{
        try {
            const response = await axios.post(`${url2}/user-confirming-submission`,setAuthHeader(),{
                manuId:data.manuId,
            })
             return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
);
export const adminPublishManuscript = createAsyncThunk(
    'manuscript/adminApproveManuscript',
    async(data,{rejectWithValue})=>{
        try {
            const response = await axios.post(`${url2}/user-confirming-submission`,setAuthHeader(),{
                manuId:data.manuId,
            })
             return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
);

export const {loadManuscript} = manuscriptSlice.actions;
export default manuscriptSlice.reducer;