import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { setHeader, url } from "../Api"

const initialState = {
    items:[],
    status:''
}

export const getUsers = createAsyncThunk(
    'users/getUsers',
    async()=>{
        try {
            const response = await axios.get(`${url}/users`,setHeader())
            if(response){
                return response.data.resp
            }else{
                return 
            }
        } catch (error) {
            return
        }
    }
)
const usersSlice = createSlice({
    name:'users',
    initialState,
    reducers:{
        loadUsers(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:{
        [getUsers.pending]:(state,action)=>{state.status='pending'},
        [getUsers.fulfilled]:(state,action)=>{state.items=action.payload;state.status = 'fulfilled'},
        [getUsers.rejected]:(state,action)=>{state.status = 'rejected'}
    }
})

export const{loadUsers} = usersSlice.actions;
export default usersSlice.reducer;