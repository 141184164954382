import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './RejectedSubmissions.css'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { url2 } from '../../../Redux/Api';
import { FaArrowLeft, FaFunnelDollar, FaPlusSquare, FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { loadJournal } from '../../../Redux/Slices/journalSlice';
import { loadMyManuscripts } from '../../../Redux/Slices/myManuscriptsSlice';
import moment from 'moment';
import { ManuscriptHeader } from './ManuscriptHeader';
import { EditorialHeader } from './EditorialHeader';

const RejectedSubmissions = props => {
const{acronym,manuid} = useParams();
const manuscripts = useSelector(state=>state.manuscripts.items)
const navigate = useNavigate()
const user = useSelector(state=>state.auth.user);
const myManuscripts = JSON.parse(localStorage.getItem('my-manuscripts'))
const[page, setPage] = useState(1)
const[pages, setPages] = useState(1)
const[stateData, setStateData] = useState([]);
const[currentPageManuscripts, setCurrentPageManuscripts] = useState([]);

useEffect(()=>{
    const a = myManuscripts.filter(fil=>fil?.incompleteSubmission===false&&fil?.approved==='rejected')
    setCurrentPageManuscripts(a)
},[myManuscripts])

useEffect(()=>{
    setStateData([])
    let a = [];
    currentPageManuscripts.forEach((el,ind) => {
      if(a.length<10){
        if(ind<=page*10&&!ind<page*10){
             a.push(el)
        }
      }
    });
    setStateData(a)
},[pages,page,currentPageManuscripts])
const handleNext = ()=>{
    setStateData([])
    setPage(page+1)
}
const handlePrev = ()=>{
    setStateData([])
    setPage(page-1)
}
useEffect(()=>{
    const a = myManuscripts.filter(fil=>fil?.incompleteSubmission===false&&fil?.approved==='rejected').length;
    let b = a/10
    let d = a%10;
   if(a>10){
    if(d===0){
    setPages(b)
    }else{
        let c = a - d
        setPages((c/10)+1)
    }
   }else{
    setPages(1)
   }
},[currentPageManuscripts])

const[showUserCard, setShowUserCard] = useState(false);
const[newState, setNewState] = useState({status:'',data:''});

const dispatch = useDispatch()

const books = useSelector(state=>state.journals.items);
const[currentId, setCurrentId] = useState(false)
const currentItem = useSelector(state=>state.journal.journal);

const handleManuscriptRemoval = async(e)=>{
    await fetch(`${url2}/remove-manuscript`,{
        method:'post',
        headers:{
            'Content-Type':'application/json',
            'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
        },
        body:JSON.stringify({manuId:e})
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            setNewState({status:'success',data:resp.resp})
            window.location.reload();
            navigate(`/${currentItem?.acronym?.toLowerCase()}`)
        }else if(resp.err){
            setNewState({status:'failed',data:resp.err})
        }else{
            setNewState({status:'failed',data:'Sorry, something went wrong.'})
        }
    }).catch(err=>{
        setNewState({status:'failed',data:err.message})
    })
}
useEffect(()=>{
        if(!currentItem?.acronym||!user){
            const interval = setInterval(() => {
                window.location.reload()
            }, 1000);
            return ()=>clearInterval(interval)
        }
},[user])

useEffect(()=>{
    if(currentItem?.pub?.toString()===user?.pub?.toString()){
            return 
    }else{
        return navigate(`/${currentItem?.acronym?.toLowerCase()}/default.aspx/login`)
    }
},[user])
  const[showManuscriptStatus, setShowManuscriptStatus] = useState(false);

const closer = ()=>{
    if(showManuscriptStatus){
        setShowManuscriptStatus(false)
    }
    if(showUserCard){
        setShowUserCard(false)
    }
}
const handleSubmitManuscript = async(e)=>{
    await fetch(`${url2}/user-confirming-submission`,{
        method:'post',
        headers:{
            'Content-Type':'application/json',
            'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
        },
        body:JSON.stringify({manuId:e})
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            setNewState({status:'success',data:resp.resp})
            window.location.reload();
            navigate(`/${currentItem?.acronym?.toLowerCase()}`)
        }else if(resp.err){
            setNewState({status:'failed',data:resp.err})
        }else{
            setNewState({status:'failed',data:'Sorry, something went wrong.'})
        }
    }).catch(err=>{
        setNewState({status:'failed',data:err.message})
    })
}

    return(
        <div className='editorial-overview-main' onClick={closer}>
        <EditorialHeader />

            <div className='editorial-login-header-wrapper'>
        <div className='editorial-login-header-bottom'>
            <div className='auth-wrapper' onClick={()=>navigate()}>
            <span className='anouncement'><span className='anouncement-text' >Announcement</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="#fff" d="M449.07 399.08L278.64 82.58c-12.08-22.44-44.26-22.44-56.35 0L51.87 399.08A32 32 0 0 0 80 446.25h340.89a32 32 0 0 0 28.18-47.17m-198.6-1.83a20 20 0 1 1 20-20a20 20 0 0 1-20 20m21.72-201.15l-5.74 122a16 16 0 0 1-32 0l-5.74-121.95a21.73 21.73 0 0 1 21.5-22.69h.21a21.74 21.74 0 0 1 21.73 22.7Z"/></svg>
            </span>
            <div className='user-auth-wrapper' onClick={()=>setShowUserCard(!showUserCard)}>
            <span className='user-auth'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" d="m7 10l5 5l5-5"/></svg><FaUser /></span>
            </div>
            {/* {
                !myManuscripts.submitted&&
                myManuscripts.map((item,index)=>{
                    return(
                        <div className='user-auth-wrapper' onClick={()=>handleSubmitManuscript(item)} key={index}>
                <button className='bottom-btn'>Submit {item?.title} now</button>
                </div>
                    )
                })
            } */}
            </div>
            {showUserCard&&<ul className='user-auth-wrapper-card'>
            <li className='user-auth-wrapper-card-items'><span className='user-auth-wrapper-card-item'>Username</span>
            <span className='user-auth-wrapper-card-item'>{user?.userName}</span>
            </li>
            <li className='user-auth-wrapper-card-items'><span className='user-auth-wrapper-card-item'>Role</span>
            <span className='user-auth-wrapper-card-item'>{JSON.parse(localStorage.getItem('logger'))}</span>
            </li>
            <li className='user-auth-wrapper-card-items'><span className='user-auth-wrapper-card-item'>Site Language</span>
            <span className='user-auth-wrapper-card-item'>English</span>
            </li>
            {user?.isAdmin&&<a href={`/dashboard`} className='user-auth-wrapper-card-item'>Admin Dashboard</a>}
            <a href={`/${currentItem?.acronym?.toLowerCase()}/info_update.asp`} className='user-auth-wrapper-card-item'>Update My Information</a>
            </ul>}
        </div>
        </div>

        <div className='editorial-overview-body'>
        <div className='confirmation-body'>
            <div className='confirmation-body-top'>
                <div className='confirmation-body-inner-top' onClick={()=>navigate(`/${currentItem?.acronym?.toLowerCase()}`)}>
                <FaArrowLeft className='confirmation-body-inner-top-icon'/><span className='confirmation-body-inner-top-text'>Rejected Submissions Submissions</span>
                </div>
                <div className='confirmation-body-inner-bottom'>
                <p className='confirmation-body-top-content'>The 'Edit Submission' link allows you to fix or alter your submision. Please use Edit Submission to make changes to the meta-data and to remove and upload new files that make up your submision.</p>
                </div>
            </div>
            {
                newState.data?.length>0&&
                <span className={newState.status==='success'?'success':'error'}>{newState.data}</span>
            }
            <div className='confirmation-body-bottom'>
            <div className='pagination-container-top'>
<div className='pagination-item'>
<p className='pagination-left'>
Page: {page} of {pages} ({`${currentPageManuscripts.length} total completed submissions`})
                </p>

</div>
<div className='pagination-item'>
<p className='pagination-right'>
Results per page 10
                </p>

</div>
               </div>
                <table className='dashboard-table'>
                    <thead>
                        <tr>
                    <th>
                                <div className='table-action-head'>
                                <span>Action</span>
                    <span className='table-head-icons'><FaPlusSquare /><FaFunnelDollar/></span>
                                </div></th>
                            <th>Manuscript<br/> Number</th>

<th>Title</th>
                        <th>Initial<br/>Date<br/>Submitted</th>
                        <th>Current<br/>Status</th>
                        <th>Date of <br/>Final Decision</th>
                        <th>Decision</th>
                            </tr>
                    </thead>
                    <tbody>
                       {
                        myManuscripts?.length>0&&
                        myManuscripts.filter(item=>!item.submitted&&item.approved==='pending').map((item,index)=>{
                            return(
                                <tr key={index}>
                                <td>
                                {
                    showManuscriptStatus&&item?._id===currentId?
                    <ul className='table-card'>
                    <li><a href={`/${currentItem?.acronym.toLowerCase()}/${item?._id}/resume-submission`} style={{color:'var(--secondary-color)',textDecoration:'none'}}>Edit Submission</a></li>
                    <li><a href={`/${currentItem?.acronym?.toLowerCase()}/default.aspx`} style={{color:'var(--secondary-color)',textDecoration:'none'}}>Approve Submission</a></li>
                    <li><a href='mailto:em@editorialmanager.uk' style={{color:'var(--secondary-color)',textDecoration:'none'}}>Send E-mail</a></li>
                </ul>:
                                    <a style={{textDecoration:'none',cursor:'pointer'}} onClick={()=>{setShowManuscriptStatus(!showManuscriptStatus);setCurrentId(item._id)}}>Action Links</a>

                }
                                    </td>
                                <td></td>
                                <td>{item?.manuscript?.title}</td>
                                <td>{moment(item?.submissionDate?.toString()).format('MMM DD, yyyy')}</td>
                                <td>{item?.approved?'Accepted for Publication':'Net yet accepted'}</td>
                                <td>{moment(item?.decisionDate?.toString()).format('MMM DD, yyyy')}</td>
                                <td>{item?.submitted?'Approved':'Needs Approval'}</td>
                                {
                    showManuscriptStatus&&
                    <ul className='table-card'>
                    <li><a href={`/${currentItem?.acronym.toLowerCase()}/${item?._id}/resume-submission`} style={{color:'var(--secondary-color)',textDecoration:'none'}}>Edit Submission</a></li>
                    <li><a href={`/${currentItem?.acronym?.toLowerCase()}/default.aspx`} style={{color:'var(--secondary-color)',textDecoration:'none'}}>Approve Submission</a></li>
                    <li><a href='mailto:em@editorialmanager.uk' style={{color:'var(--secondary-color)',textDecoration:'none'}}>Send E-mail</a></li>
                </ul>
                }
                                </tr>
                            )
                        })
                       }
                    </tbody>
                </table>
                <div className='pagination-container-bottom'>
<div className='pagination-item'>
<p className='pagination-left'>
Page: {page} of {pages} ({`${currentPageManuscripts.length} total completed submissions`})
                </p>
</div>
<div className='pagination-item'>
<p className='pagination-right'>
Results per page 10
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512" {...props}><path fill="none" stroke="#1588f4" strokeLinecap="square" strokeMiterlimit={10} strokeWidth={48} d="m112 184l144 144l144-144"></path></svg>
                {
    pages>1&&<div>
    {
        page<pages?
        <div className='page-control' onClick={handleNext}>Next<svg fill="rgb(36, 116, 255)" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 52 52" enable-background="new 0 0 52 52" transform="rotate(270)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M47.6,17.8L27.1,38.5c-0.6,0.6-1.6,0.6-2.2,0L4.4,17.8c-0.6-0.6-0.6-1.6,0-2.2l2.2-2.2 c0.6-0.6,1.6-0.6,2.2,0l16.1,16.3c0.6,0.6,1.6,0.6,2.2,0l16.1-16.2c0.6-0.6,1.6-0.6,2.2,0l2.2,2.2C48.1,16.3,48.1,17.2,47.6,17.8z"></path> </g></svg>
        </div>:
        <div className='page-control' onClick={handlePrev}>
        <svg fill="rgb(36, 116, 255)" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 52 52" enable-background="new 0 0 52 52" transform="rotate(90)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M47.6,17.8L27.1,38.5c-0.6,0.6-1.6,0.6-2.2,0L4.4,17.8c-0.6-0.6-0.6-1.6,0-2.2l2.2-2.2 c0.6-0.6,1.6-0.6,2.2,0l16.1,16.3c0.6,0.6,1.6,0.6,2.2,0l16.1-16.2c0.6-0.6,1.6-0.6,2.2,0l2.2,2.2C48.1,16.3,48.1,17.2,47.6,17.8z"></path> </g></svg>
        Prev
        </div>
    }
    </div>
}
</div>
               </div>
            </div>
        </div>
        </div>

        </div>
    );
};

RejectedSubmissions.propTypes = {};

export { RejectedSubmissions };