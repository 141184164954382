import React, {useEffect, useInsertionEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import './PasswordChange.css'
import siteInfo from '../../../../shopping/images/springer-ecomm-header-logo.png'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaInfoCircle, FaSpinner, FaTimes } from 'react-icons/fa';
import { url, url2 } from '../../../../Redux/Api';
import { useSelector } from 'react-redux';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { EditorialHeader } from '../EditorialHeader';


const PasswordChange = props => {
const[password, setPassword] = useState('')
const[confirmedPassword, setConfirmedPassword] = useState('')
const[newState, setNewState] = useState({status:'',data:''});
const[loading, setLoading] = useState(false);
const[passEq, setPassEq] = useState(false);
const[institutions, setInstitutions] = useState([]);
const navigate = useNavigate();

const currentItem = useSelector(state=>state.journal.journal);
const[isLoading, setIsLoading] = useState(false);
const[missMatch, setMissMatch] = useState(false);

const{pub,email,token,acronym} = useParams();

const handleChange = async(e)=>{
    e.preventDefault()
   if(password===confirmedPassword){
    setIsLoading(true)
    await fetch(`${url2}/create-new-password`,{
mode:'cors',
method:'put',
headers:{
    'Content-Type':'application/json'
},
body:JSON.stringify({
    email,pub,password,confirmedPassword,token
})
}).then(res=>res.json())
.then(resp=>{
if(resp.resp){
    return navigate(`/${acronym?.toLowerCase()}`)
}else if(resp.err){
  setIsLoading(false)
  return setNewState({status:'failed',resp:resp.err});
}else{
setIsLoading(false)
return setNewState({status:'failed',data:'Something went wrong, please try again.'});
}
}).catch(err=>{
setNewState({status:'failed',data:err.message})
return setIsLoading(false);
})
   }else{
    return setMissMatch(true)
   }
}

return(
    <>
    <EditorialHeader/>
    <div className='editorial-register-main'>
        <div className='editorial-login-down-menu'>
                <ul className='editorial-login-down-menu-items'>
                    <li className='editorial-login-down-menu-link'>Home</li>
                    <li className='editorial-login-down-menu-link'>Submit a manuscript</li>
                    <li className='editorial-login-down-menu-select-container'>
                        <select className='editorial-login-down-menu-selection' title='About'>
                            <option className='editorial-login-down-menu-link'>Journal overview</option>
                            <option className='editorial-login-down-menu-link'>Instructions for Authors</option>
                            <option className='editorial-login-down-menu-link'>Privacy Policy</option>
                        </select>
                    </li>
                    <li className='editorial-login-down-menu-select-container'><select className='editorial-login-down-menu-selection' title='Help'>
                            <option className='editorial-login-down-menu-link'>Journal overview</option>
                            <option className='editorial-login-down-menu-link'>Instructions for Authors</option>
                            <option className='editorial-login-down-menu-link'>Privacy Policy</option>
                        </select></li>
                </ul> 
            </div>
        <div className='main-register-site-body-container'>
        <div className='password-change-format'>
<h1 className='password-formatting-title'>Change Password</h1>
<div className='change-password-form'>
    {
        newState?.data?.length>0&&<p className={newState.status==='success'?'success-message':'error-message'}>{newState?.data}</p>
    }
<p className='password-change-format-info' style={{border:'none'}}>Pease enter your new password. If this publication enforces special password guidelines, they will be listed below.</p>
    <div className='change-password-form-inner-wrapper'>
    <span className='password-change-format-info-text'>New Password</span><input type='password' className='password-change-input' onChange={e=>setPassword(e.target.value)} />
    </div>
    <div className='change-password-form-inner-wrapper'>
    <span className='password-change-format-info-text'>Re-type New Password</span><input type='password' className='password-change-input' onChange={e=>setConfirmedPassword(e.target.value)} />
    </div>
</div>
<div className='password-change-bottom-container'>
    {
        missMatch&&
        <div className='password-formatting-bottom-card'>
<p className='password-formatting-bottom-content'>Please Re-type the password exactly you entered in the password box.</p>
<button onClick={()=>setMissMatch(!missMatch)} className='password-formatting-btn'>Ok</button>
        </div>
    }
    <span className='password-rules-title'>Password Rules</span>
    <p className='password-formatting-bottom-content'>Password cannot be the same as the username.</p>
    <p className='password-formatting-bottom-content'>Password cannot be the same as the user's first or last name.</p>
    <p className='password-formatting-bottom-content'>Passwords must be a minimum of <b>10</b> characters.</p>
    <p className='password-formatting-bottom-content'>Passwords must be alpha-numeric (i.e., must contain both letters and numbers.)</p>
    <p className='password-formatting-bottom-content'>Passwords must be mixed case (i.e., must contain both upper and lower case letters.)</p>
</div>
<div className='password-formatting-btn-wrapper'>
<button onClick={()=>navigate(-1)} className='password-formatting-btn'>Cancel</button>
<button onClick={handleChange} className='password-formatting-btn'>Submit</button>
</div>
    </div>
        </div>
    </div>
    </>
    )
};

PasswordChange.propTypes = {};

export { PasswordChange };