import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './PasswordReset.css'
import siteInfo from '../../../../shopping/images/springer-ecomm-header-logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { url2 } from '../../../../Redux/Api';
import { useSelector } from 'react-redux';
import { EditorialHeader } from '../EditorialHeader';
import { FaSpinner } from 'react-icons/fa';


const PasswordReset = props => {
    const navigate = useNavigate()
    const[firstName, setFirstName] = useState('')
const[lastName, setLastName] = useState('')
const[email, setEmail] = useState('')
const[sent, setSent] = useState(false)
const[logger, setLogger] = useState('');
const[error, setError] = useState({err:'',hint:''});
const[captured, setCaptured] = useState('')
const[lastMailTried, setLastMailTried] = useState('')
const[isLoading, setIsLoading] = useState(false)
const[passwordReset, setPasswordReset] = useState('')
const[newState, setNewState] = useState({status:'',data:'The e-mail address you entered was not found in the system.'});
const[tokenValue, setTokenValue] = useState('');
const[mail, setMail] = useState('')
const{acronym}= useParams();
const[mailNotFound, setMailNotFound] = useState(false);
const currentItem = useSelector(state=>state.journal.journal);
    
const handleSendMail = async(e)=>{
        e.preventDefault()
        setIsLoading(true)
                await fetch(`${url2}/password-reset`,{
            mode:'cors',
            method:'post',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                email,pub:currentItem?.pub
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setIsLoading(false);
                return setSent(true);
            }else if(resp.err){
              setIsLoading(false)
              return setNewState({status:'failed',resp:resp.err});
            }else if(resp.notFound){
                setIsLoading(false);
                setNewState({status:'failed',resp:resp.notFound});
               return setMailNotFound(true)
            }else{
            setIsLoading(false)
            return setNewState({status:'failed',data:'Something went wrong, please try again.'});
          }
        }).catch(err=>{
            setNewState({status:'failed',data:err.message})
            return setIsLoading(false)
        })
    }

    const token = ()=>{
        setTokenValue(setTimeout(()=>{
            Math.floor(Math.random() * 300)
        },3000))
    }
    
return(
    <>
    <EditorialHeader/>
    <div className='editorial-register-main'>
    <div className='editorial-pre-register-site-body'>
    <div className='editorial-register-site-body-left'>
   <h1 className='editorial-register-site-body-left-title'>Account Founder</h1>
   {!sent&&<p className='editorial-register-site-body-left-content'>
   Enter your e-mail address in the box below. If an account exists with this e-mail address, your Username and Password will be e-mailed to you.
   </p>}
    </div>
   {
    !sent?
    <form className='editorial-pre-register-form' onSubmit={handleSendMail}>
    <div className='choose-register-method-top'>
    <span className='pre-register-text-method-top'>Please Enter The Following</span>
    </div>
    {
        mailNotFound&&
        <div className='password-prompt-card'>
            <p className='password-prompt-error-message'>{newState.data}</p>
<button onClick={()=>setMailNotFound(mailNotFound)} className='password-formatting-btn'>Ok</button>
        </div>
    }
    <div className='password-reset-form-inner'>
    <div className='password-reset-form-inner-input-wrapper'>
        <span className='pre-register-required-text'>E-mail*</span>
        <input type='email' onChange={(e)=>setEmail(e.target.value)} className='pre-editorial-login-input' />
    </div>
    {isLoading?<FaSpinner />:<div className='password-reset-form-inner-btn-wrapper'>
    <button  className='password-reset-form-btn' onClick={()=>navigate(-1)}>{'<< Cancel'}</button>
    <button type='submit' className='password-reset-form-btn' >Send Login Details</button>
    </div>}
    <a className='link-to-login' href={`/${currentItem?.acronym?.toLowerCase()}/default.aspx/login`}>Go to Login Page</a>
    </div>
</form>
    :
<div className='mail-sent-container'>
<div className='mail-sent-card'>
    <p className='mail-sent-card-content'>An e-mail has been sent to {email} with a username and a link to change your password.</p>
</div>
<a className='link-to-login' href={`/${currentItem?.acronym.toLowerCase()}/default.aspx/login`}>Return to login page</a>
</div>
   }
   </div>
    </div>
    </>
    );
};

PasswordReset.propTypes = {};

export { PasswordReset };