import React, { useEffect, useState } from 'react';
// import PdfGenerator from './pdfGenerator';
// import { FaDownload } from 'react-icons/fa';
// import { toBlobURL } from "blob-to-url";
// import { toDataURI } from "blob-to-url";

const PDFPage = () => {
const _blob = 'blob:http://localhost:3002/54798a84-0e96-4c17-8a13-578cb3c0ef86'  
  const[data,setData] = useState('');

useEffect(()=>{
  const blob = URL.createObjectURL(_blob)
  // const _blobUrl = toBlobURL(_blob);
  console.log('Blobs',blob); // converted blob url.
  // _blobUrl.revoke(); // Revoke blob url.
  
  // const _dataURI = toDataURI(_blob);
  // console.log(_dataURI); // translated base64-encoded Blob|File object.
},[data])
  // // Function to generate PDF when the button is clicked
  // const generatePdf = () => {
  //   PdfGenerator();
  // }

  // Render the main content
  return (
    <div style={{ justifyContent: "center", display: "flex", alignItems: "center", height: "100vh" }}>
      <div>
        <p>Click here to download the PDF file.</p>
        <div style={{display:"flex",justifyContent:"center"}}>
          {/* <button
            onClick={generatePdf}
            icon={<FaDownload />}
            type="button"
            shape="rectangle"
            size="small"
          > */}
          {/* </button> */}
        </div>
      </div>
    </div>
  )
}

export default PDFPage