import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import { url2 } from '../../../Redux/Api';
import { EditorialHeader } from './EditorialHeader';


const ConfirmationPrompt = props => {
    const navigate = useNavigate()
    const{title} = useParams();
    const[firstName, setFirstName] = useState('')
const[lastName, setLastName] = useState('')
const[email, setEmail] = useState('')
const[sent, setSent] = useState(false)
const[logger, setLogger] = useState('');
const[error, setError] = useState({err:'',hint:''});
const[captured, setCaptured] = useState('')
const[lastMailTried, setLastMailTried] = useState('')
const[isLoading, setIsLoading] = useState(false)
const[passwordReset, setPasswordReset] = useState('')
const[newState, setNewState] = useState({status:'',data:'The e-mail address you entered was not found in the system.'});
const[tokenValue, setTokenValue] = useState('');
const[mail, setMail] = useState('')
const{acronym}= useParams();
const[mailNotFound, setMailNotFound] = useState(false);
const currentItem = useSelector(state=>state.journal.journal);
    
const handleSendMail = async(e)=>{
        e.preventDefault()
        setIsLoading(true)
                await fetch(`${url2}/password-reset`,{
            mode:'cors',
            method:'post',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                email,pub:currentItem?.pub
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setIsLoading(false);
                return setSent(true);
            }else if(resp.err){
              setIsLoading(false)
              return setNewState({status:'failed',resp:resp.err});
            }else if(resp.notFound){
                setIsLoading(false);
                setNewState({status:'failed',resp:resp.notFound});
               return setMailNotFound(true)
            }else{
            setIsLoading(false)
            return setNewState({status:'failed',data:'Something went wrong, please try again.'});
          }
        }).catch(err=>{
            setNewState({status:'failed',data:err.message})
            return setIsLoading(false)
        })
    }

    const token = ()=>{
        setTokenValue(setTimeout(()=>{
            Math.floor(Math.random() * 300)
        },3000))
    }
    
return(
    <>
    <div className='editorial-register-main'>
    <EditorialHeader/>
    <div className='editorial-pre-register-site-body'>
    <div className='editorial-register-site-body-left'>
   <h1 className='editorial-register-site-body-left-title'>Decision</h1>
   <p className='editorial-register-site-body-left-content'>Thank you for approving {title}</p>
    </div>
<div className='mail-sent-container'>
<div className='mail-sent-card'>
    <p className='mail-sent-card-content'>Thank you for approving "{title}".</p>
</div>
</div>
   </div>
    </div>
    </>
    );
};

ConfirmationPrompt.propTypes = {};

export { ConfirmationPrompt };