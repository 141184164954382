import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './editorialLogin.css'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { url2 } from '../../../Redux/Api';
import { loadUser, loginUser } from '../../../Redux/Slices/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import { EditorialHeader } from './EditorialHeader';

const EditorialLogin = props => {
const[userName, setUserName] = useState('');
const[password, setPassword] = useState('');
const[logger, setLogger] = useState('');
const[loading, setLoading] = useState(false);
const[error, setError] = useState({err:'',hint:''});

const{acronym,pub} = useParams();
const currentItem = useSelector(state=>state.journal.journal);
// console.log('acronym',acronym)
// useEffect(()=>{
//     async function name() {
//         await fetch(`${url}/books`,{
//             mode:'cors',
//             method:'get',
//             headers:{
//                 'Content-Type':'application/json'
//             }
//         }).then(res=>res.json())
//         .then(resp=>{
//             if(resp.resp){
//                 const currentPub = JSON.parse(localStorage.getItem('currentPub'))?JSON.parse(localStorage.getItem('currentPub')):resp.resp[0]?.pub
//                 const book = resp.resp.filter(item=>item.acronym===acronym.toString())
//                 setCurrentItem(book[0])
//             }
//         }).catch(err=>setError(err.message))
//     }
//     name();

// },[])
const navigate = useNavigate()
const user = useSelector(state=>state.auth.user);

const dispatch = useDispatch();
useEffect(()=>{
    if(user){
        return navigate(`/${currentItem?.acronym}`)
    }else{
        return
    }
},[])
const login = async(e)=>{
    await fetch(`${url2}/login`,{
        mode:'cors',
        method:'Post',
        headers:{
            'Content-Type':'application/json',
        },
        body:JSON.stringify({
            email:userName,password,acronym:currentItem?.acronym?.toLowerCase(),pub:currentItem?.pub
        })
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.user){
            setError({err:"",hint:""})
            localStorage.setItem('user',JSON.stringify(resp.user))
            localStorage.setItem('token',JSON.stringify(resp.token))
            localStorage.setItem('logger',JSON.stringify(e))
            setLogger(e)
            dispatch(loadUser({user:resp.user,token:resp.token}))
            setLoading(false)
            window.location.reload()
        }else if(resp.err){
            setLoading(false)
            return setError({err:resp.err,hint:resp.hint})
        }
    }).catch(err=>{setLoading(false);setError({err:err.message})})
}
    useEffect(()=>{
        if(user){
            return navigate(`/${currentItem?.acronym?.toLowerCase()}`)
        }
    },[user])

return(<>
<div className='editorial-login-main'>
<EditorialHeader/>
            <div className='editotrial-login-container'>
           <div className='editotrial-login-container-top'>
           <div className='editotrial-login-main-left'>
            {
                error.err?.length>0&&<div style={{marginTop:'25px',display:'flex',justifyContent:'center',alignItems:'flex-start',gap:'0px',lineHeight:'1.2',flexDirection:'column'}}>
                <span style={{color:'var(--red-color)',padding:'5px',fontSize:'12px',fontWeight:'500'}}>Error: {error.err}</span>
                <span style={{color:'var(--red-color)',padding:'5px',fontSize:'12px',fontWeight:'500'}}>Hint: {error.hint}</span>
            </div>
        
            }
            {/* <picture style={{padding:'20px', borderRadius:'4px'}}>
                <source width="158" height="210" srcset={`https://media.springernature.com/w158/springer-static/cover-hires/journal/${currentItem?.pub}?as=webp, https://media.springernature.com/w316/springer-static/cover-hires/journal/${currentItem?.pub}?as=webp 2x`} />
                <img width="88"  style={{borderRadius:'6px'}} height="117" data-test="darwin-journal-cover" src={`https://media.springernature.com/w88/springer-static/cover-hires/journal/${currentItem?.pub}?as=webp" srcset="https://media.springernature.com/w176/springer-static/cover-hires/journal/${currentItem?.pub}?as=webp 2x`} alt="" />
        </picture> */}
            </div>
       <div className='editotrial-login-main'>
<div className='editotrial-login-main-form'>
<div className='choose-register-method'>
    <span className='pre-register-text-method'>Please enter the following</span>
    </div>
    <div className='choose-register-method-top'>
    <span className='pre-register-text-method-top'>Insert special character</span>
    </div>
    <p className='editotrial-login-form-title'>{currentItem?.title}</p>
<div className='editorial-login-main-input-wrapper'><span  className='editorial-login-main-input-caption'>E-mail</span><input type='text' placeholder='Enater user name' onChange={(e)=>setUserName(e.target.value)} className='editorial-login-main-input' /></div>
    <div className='editorial-login-main-input-wrapper'><span  className='editorial-login-main-input-caption'>Password</span><input type='password' placeholder='Enater password' onChange={(e)=>setPassword(e.target.value)} className='editorial-login-main-input' /></div>
{loading?<FaSpinner/>:<div className='editorial-login-main-btn-group'>
<button onClick={(e)=>{e.preventDefault();login('author')}} type='submit' className='editorial-login-main-btn'>Author Login</button>
<button onClick={(e)=>{e.preventDefault();login('reviewer')}} type='submit' className='editorial-login-main-btn'>Reviewer Login</button>
<button onClick={(e)=>{e.preventDefault();login('editor')}} type='submit' className='editorial-login-main-btn'>Editor Login</button>
<button onClick={(e)=>{e.preventDefault();login('publisher')}} type='submit' className='editorial-login-main-btn'>Publisher Login</button>
</div>}
{
    user&&<button onClick={(e)=>navigate(-1)} type='submit' className='editorial-login-main-btn'>Continue</button>
}
<div className='editorial-alternate-login'>
    <span  className='editorial-alternate-login-caption'>Or Login via</span>
    <span  className='editorial-alternate-login-caption'>ORCID</span>
    <span  className='editorial-alternate-login-caption'>What is ORCID?</span>
</div>
<div className='editorial-alternate-login'>
    <Link to={`/${currentItem?.acronym?.toLowerCase()}/l.asp`} className='editorial-alternate-login-caption'>Send login details</Link>
    <Link to={`/${currentItem?.acronym?.toLowerCase()}/default.aspx/register`} className='editorial-alternate-login-caption'>Register New</Link>
    <Link to={`https://www.editorialmanager.com/jays/loginHelp.aspx`} className='editorial-alternate-login-caption'>Login help</Link>
    <Link to={`https://www.editorialmanager.com/homepage/ms/springer-msservices.html`} className='editorial-alternate-login-caption'>Manuscript Services</Link>
</div>
<div className='editorial-alternate-login-caption-right-wrapper'>
<p className='editorial-alternate-login-caption-right'>Software copyright &copy; 2024</p>
<span className='editorial-alternate-login-caption-policy'>Aries Privacy Policy|Data Privacy Policy</span>
</div>

</div>
       </div>
       <div className='editorial-login-main-right'>
<Link to={`https://www.editorialmanager.com/aiso/redirectToBanner.aspx?defaultTarget=journal_overview.html`} className='editorial-login-main-right-link'>About this Publication</Link>
<Link to={`https://www.editorialmanager.com/${currentItem?.acronym}/redirectToBanner.aspx?defaultTarget=AuthInstr.html`} className='editorial-login-main-right-link'>Instructions For Authors</Link>
<Link to={`https://www.editorialmanager.com/homepage/DOCS/Author_Tutorial.pdf`} className='editorial-login-main-right-link'>Author Tutorial</Link>
<Link to={`https://www.editorialmanager.com/homepage/DOCS/Reviewer_Tutorial.pdf`} className='editorial-login-main-right-link'>Reviewer Tutorial</Link>
<Link to={`https://www.editorialmanager.com/homepage/faq.html#faq11`} className='editorial-login-main-right-link'>System Requirements</Link>
<Link to={`/${currentItem?.acronym}/preregistration.aspx`} className='editorial-login-main-right-link'>Register</Link>
<a href={`mailto:${currentItem?.email}`} className='editorial-login-main-right-link'>Contact Us</a>
       </div>
           </div>
       
<div className='editorial-login-warning-container'>
<div className='editorial-login-warning-inner-wrapper'>
<span className='editorial-login-warning-content-bold-red'>New Authors: </span>
    <span className='editorial-login-warning-content'>
    Please click the 'Register' link from the menu above and enter the requested information. Upon successful registration you will be sent an email with instructions on how to verify your registration.</span>
</div>
    
<div className='editorial-login-warning-inner-wrapper'>
<span className='editorial-login-warning-bold-black'>Note: </span>
<span className='editorial-login-warning-content'>If you have received an email from us with an assigned user ID and password, DO NOT REGISTER AGAIN. Just log in to the system as 'Author'.</span>
</div>
<div className='editorial-login-warning-inner-wrapper'>
<span className='editorial-login-warning-content-bold-red'>Authors: </span>
<span className='editorial-login-warning-content'>Please refer to the Instructions for Authors (follow the ‘Instructions for Authors’ link in the menu above) for details and additional information on how to prepare your manuscript to meet the journal's requirements. Please log in to the system as 'Author'. Then submit your manuscript and track its progress through the system.</span>
</div>
<div className='editorial-login-warning-inner-wrapper'>
<span className='editorial-login-warning-bold-black'>Note: </span>
<span className='editorial-login-warning-content'>All source files you upload will be automatically compiled into a single PDF file to be APPROVED by you at the end of the submission process. While the compiled PDF will be used for peer-review purposes, your uploaded source files will be transferred to the publisher for publication upon acceptance. For further information about requested file formats for text and illustrations please refer to the Instructions for Authors. You can also contact the Editorial Office via the ‘Contact Us’ link.</span>
</div>
<div className='editorial-login-warning-inner-wrapper'>
<span className='editorial-login-warning-content-bold-red'>Returning Author: </span>
<span className='editorial-login-warning-content'>Please use the provided username and password and log in as 'Author' to track your manuscript or to submit a NEW manuscript. (Do not register again as you will then be unable to track your manuscript).</span>
</div>
<div className='editorial-login-warning-inner-wrapper'>
<span className='editorial-login-warning-content-bold-red'>Reviewers: </span>
<span className='editorial-login-warning-content'>Please log in to the system as 'Reviewer'. You may view and/or download manuscripts assigned to you for review, submit your comments for the editors and the authors, and track the progress of your manuscripts through the system.</span>
</div>
<div className='editorial-login-warning-inner-wrapper'>
<span className='editorial-login-warning-bold-black'>Note: </span>
<span className='editorial-login-warning-content'>Please click the 'Accept' or 'Decline' button as soon as possible after receipt of the email asking you to review a manuscript.</span>
</div>
<div className='editorial-login-warning-inner-wrapper'>
<span className='editorial-login-warning-content-bold-red'>To Change Your Username And Password:</span>
<span className='editorial-login-warning-content'>Log in to the system and select 'Update My Information' from the menu above. At the top of the Update My Information screen, click the 'Change Password' button and follow the directions.</span>
</div>
<div className='editorial-login-warning-inner-wrapper'>
<span className='editorial-login-warning-content-bold-red'>Forgot Your Password?</span>
<span className='editorial-login-warning-content'>If you have forgotten your password, please click 'Send Login Details' in the frame above and follow the directions.</span>
</div>
<div className='editorial-login-warning-inner-wrapper'>
<span className='editorial-login-warning-content-bold-red'>Any further Questions?</span>
<span className='editorial-login-warning-content'>Please click the 'Contact Us' link.</span>
</div>
</div>
            </div>
    </div>
</>
   
    );
};

EditorialLogin.propTypes = {};

export { EditorialLogin };