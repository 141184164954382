import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './ConfirmationDashboard.css'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { url2 } from '../../../Redux/Api';
import { FaArrowLeft, FaArrowRight, FaFunnelDollar, FaMinus, FaPlus, FaPlusSquare, FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { loadJournal } from '../../../Redux/Slices/journalSlice';
import { loadMyManuscripts } from '../../../Redux/Slices/myManuscriptsSlice';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment';
import { EditorialHeader } from './EditorialHeader';
import { getManuscripts } from '../../../Redux/Slices/manuscriptsSlice';


const ConfirmationDashboard = props => {
const dispatch = useDispatch()
useEffect(()=>{
    dispatch(getManuscripts())
},[])
const myManuscripts = useSelector(state=>state.myManuscripts.items)
const[page, setPage] = useState(1)
const[pages, setPages] = useState(1)
const[stateData, setStateData] = useState([]);
const[currentPageManuscripts, setCurrentPageManuscripts] = useState([]);

useEffect(()=>{
    const a = myManuscripts.filter(fil=>fil?.incompleteSubmission===false&&!fil?.submitted)
    setCurrentPageManuscripts(a)
},[myManuscripts])

useEffect(()=>{
    setStateData([])
    let a = [];
    currentPageManuscripts.forEach((el,ind) => {
      if(a.length<10){
        if(ind<=page*10&&!ind<page*10){
             a.push(el)
        }
      }
    });
    setStateData(a)
},[pages,page,currentPageManuscripts])
const handleNext = ()=>{
    setStateData([])
    setPage(page+1)
}
const handlePrev = ()=>{
    setStateData([])
    setPage(page-1)
}
useEffect(()=>{
    const a = currentPageManuscripts.filter(fil=>fil?.incompleteSubmission===false&&!fil?.submitted).length;
    let b = a/10
    let d = a%10;
   if(a>10){
    if(d===0){
    setPages(b)
    }else{
        let c = a - d
        setPages((c/10)+1)
    }
   }else{
    setPages(1)
   }
},[currentPageManuscripts])

const navigate = useNavigate()
const[currentId, setCurrentId] = useState(false)
const user = useSelector(state=>state.auth.user);
const[showUserCard, setShowUserCard] = useState(false);
const[newState, setNewState] = useState({status:'',data:''});
const[manuscriptData, setManuscriptData] = useState(localStorage.getItem('manuscript-data')?JSON.parse(localStorage.getItem('manuscript-data')):null);
const manuscripts = useSelector(state=>state.manuscripts?.items);

useEffect(()=>{
    dispatch(loadMyManuscripts())
},[manuscripts])
const updateManuscript = async(e)=>{
    await fetch(`${url2}/pdf-download/${e._id}`,{
        method:'put',
        headers:{
            'Content-Type':'application/json',
            'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
        }
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            setNewState({status:'success',data:resp.resp})
            // window.location.reload();
            // return navigate(`/${currentItem?.acronym?.toLowerCase()}`)
        }else if(resp.err){
            return setNewState({status:'failed',data:resp.err})
        }else{
            return setNewState({status:'failed',data:'Sorry, something went wrong.'})
        }
    }).catch(err=>{
        return setNewState({status:'failed',data:err.message})
    })
}

const currentItem = useSelector(state=>state.journal.journal);

const handleManuscriptRemoval = async(e)=>{
    await fetch(`${url2}/remove-manuscript`,{
        method:'post',
        headers:{
            'Content-Type':'application/json',
            'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
        },
        body:JSON.stringify({manuId:e})
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            setNewState({status:'success',data:resp.resp})
            window.location.reload();
            navigate(`/${currentItem?.acronym?.toLowerCase()}`)
        }else if(resp.err){
            setNewState({status:'failed',data:resp.err})
        }else{
            setNewState({status:'failed',data:'Sorry, something went wrong.'})
        }
    }).catch(err=>{
        setNewState({status:'failed',data:err.message})
    })
}
const[detail, setDetail] = useState(null);
const[pdfInfo, sePdfInfo] = useState('')

const actionHandler = (e)=>{
    setShowManuscriptStatus(!showManuscriptStatus)
    setDetail(e)
}
useEffect(()=>{
        if(!currentItem?.acronym||!user){
            const interval = setInterval(() => {
                window.location.reload()
            }, 1000);
            return ()=>clearInterval(interval)
        }
},[user])

useEffect(()=>{
    if(currentItem?.pub?.toString()===user?.pub?.toString()){
            return 
    }else{
        return navigate(`/${currentItem?.acronym?.toLowerCase()}/default.aspx/login`)
    }
},[user])
  const[showManuscriptStatus, setShowManuscriptStatus] = useState(false);

const closer = ()=>{
    if(showManuscriptStatus){
        setShowManuscriptStatus(false)
    }
    if(showUserCard){
        setShowUserCard(false)
    }
}

const handleSubmitManuscript = async(e)=>{
    await fetch(`${url2}/user-confirming-submission`,{
        method:'post',
        headers:{
            'Content-Type':'application/json',
            'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
        },
        body:JSON.stringify({manuId:e})
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            dispatch(getManuscripts())
            navigate(`/${currentItem?.acronym?.toLowerCase()}/${e?.manuscript?.title}/approvals-prompt`)
        }else if(resp.err){
            setNewState({status:'failed',data:resp.err})
        }else{
            setNewState({status:'failed',data:'Sorry, something went wrong.'})
        }
    }).catch(err=>{
        setNewState({status:'failed',data:err.message})
    })
}
    return(
        <div className='editorial-overview-main' onClick={closer}>
        <EditorialHeader/>
        <div className='editorial-overview-body'>
        <div className='confirmation-body'>
            <div className='confirmation-body-top'>
                <div className='confirmation-body-inner-top' onClick={()=>navigate(`/${currentItem?.acronym?.toLowerCase()}`)}>
                <FaArrowLeft className='confirmation-body-inner-top-icon'/><span className='confirmation-body-inner-top-text'>Submissions Waiting for Approval by Author</span>
                </div>
                <div className='confirmation-body-inner-bottom'>
                <p className='confirmation-body-top-content'>If no Actions appear for your submissiom, please wait a few minutes for your PDF to be built. The Actions appear automatically when your PDF is available.</p>
                <p className='confirmation-body-top-content'>The 'Edit Submission' link allows you to fix or alter your submision. Please use Edit Submission to make changes to the meta-data and to remove and upload new files that make up your submision.</p>
                <p className='confirmation-body-top-content'>The 'Remove Submission' link removes your submision from the system. Please use this ONLY if you would like to permanently remove this submision from the system.</p>
                </div>
            </div>
            {
                newState.data?.length>0&&
                <span className={newState.status==='success'?'success':'error'}>{newState.data}</span>
            }
            <div className='confirmation-body-bottom'>
            <div className='pagination-container-top'>
<div className='pagination-item'>
<p className='pagination-left'>
Page: {page} of {pages} ({`${currentPageManuscripts.length} total completed submissions`})
                </p>

</div>
<div className='pagination-item'>
<p className='pagination-right'>
Results per page 10
                </p>

</div>
               </div>
                <table className='dashboard-table'>
                    <thead>
                        <tr>
                            <th>
                                <div className='table-action-head'>
                                <span>Action</span>
                    <span className='table-head-icons'><FaPlusSquare /><FaFunnelDollar/></span>
                                </div></th>
                            <th>Manuscript<br/> Number</th>

<th>Title</th>
                        <th>Date<br/> Submission<br/> Began</th>
                        <th>Status <br/>Date</th>
                        <th>Current <br/>Status</th>
                            </tr>
                    </thead>
                    <tbody>
                       {
                        stateData?.length>0&&
                        stateData.map((item,index)=>{
                            return(
                                <tr key={index}>
                                <td>
                                {
                    showManuscriptStatus&&item?._id===currentId?
                    <ul className='table-card'>
                    <li>
                        <a href={item?.upload} download='PDF File'>View Submission</a>
                        </li>
                    {
                        item?.submitted&&<li><a href={`/${currentItem?.acronym.toLowerCase()}/${item?._id}/resume-submission`} style={{color:'var(--secondary-color)',textDecoration:'none'}}>Edit Submission</a></li>
                    }
                    {!item?.submitted&&<li onClick={()=>handleSubmitManuscript(item?._id)}>Approve Submission</li>}
                    {!item?.submitted&&<li onClick={()=>handleManuscriptRemoval(item?._id)}>Remove Submission</li>}
                    <li><a href='mailto:em@editorialmanager.uk' style={{color:'var(--secondary-color)',textDecoration:'none'}}>Send E-mail</a></li>
                </ul>:
                                    <a style={{textDecoration:'none',cursor:'pointer'}} onClick={()=>{setShowManuscriptStatus(!showManuscriptStatus);setCurrentId(item._id)}}>Action Links</a>

                }
                                </td>
                                <td>{item?.manuN}</td>
                                <td>{item?.manuscript?.title}</td>
                                <td>{moment(item?.date).format('MMM DD, yyyy')}</td>
                                <td>{moment(item?.date).format('MMM DD, yyyy')}</td>
                                <td>{item?.submitted?'New Submission':'Needs Approval'}</td>
                                </tr>
                            )
                        })
                       }
                    </tbody>
                </table>
                <div className='pagination-container-bottom'>
<div className='pagination-item'>
<p className='pagination-left'>
Page: {page} of {pages} ({`${currentPageManuscripts.length} total completed submissions`})
                </p>
</div>
<div className='pagination-item'>
<p className='pagination-right'>
Results per page 10
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512" {...props}><path fill="none" stroke="#1588f4" strokeLinecap="square" strokeMiterlimit={10} strokeWidth={48} d="m112 184l144 144l144-144"></path></svg>
                {
    pages>1&&<div>
    {
        page<pages?
        <div className='page-control' onClick={handleNext}>Next<svg fill="rgb(36, 116, 255)" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 52 52" enable-background="new 0 0 52 52" transform="rotate(270)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M47.6,17.8L27.1,38.5c-0.6,0.6-1.6,0.6-2.2,0L4.4,17.8c-0.6-0.6-0.6-1.6,0-2.2l2.2-2.2 c0.6-0.6,1.6-0.6,2.2,0l16.1,16.3c0.6,0.6,1.6,0.6,2.2,0l16.1-16.2c0.6-0.6,1.6-0.6,2.2,0l2.2,2.2C48.1,16.3,48.1,17.2,47.6,17.8z"></path> </g></svg>
        </div>:
        <div className='page-control' onClick={handlePrev}>
        <svg fill="rgb(36, 116, 255)" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 52 52" enable-background="new 0 0 52 52" transform="rotate(90)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M47.6,17.8L27.1,38.5c-0.6,0.6-1.6,0.6-2.2,0L4.4,17.8c-0.6-0.6-0.6-1.6,0-2.2l2.2-2.2 c0.6-0.6,1.6-0.6,2.2,0l16.1,16.3c0.6,0.6,1.6,0.6,2.2,0l16.1-16.2c0.6-0.6,1.6-0.6,2.2,0l2.2,2.2C48.1,16.3,48.1,17.2,47.6,17.8z"></path> </g></svg>
        Prev
        </div>
    }
    </div>
}
</div>
               </div>
            </div>
        </div>
        </div>

        </div>
    );
};

ConfirmationDashboard.propTypes = {};

export { ConfirmationDashboard };