import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logOut } from '../../../Redux/Slices/AuthSlice';
import './EditorialHeader.css';
import PropTypes from 'prop-types';
import { FaArrowDown, FaBars, FaTimes } from 'react-icons/fa';

const EditorialHeader = (props) => {
    const currentItem = useSelector(state=>state.journal.journal);
    const user = useSelector(state=>state.auth.user);
    const[mobile, setMobile] = useState(false);
    const[show1, setShow1] = useState(false);
    const[showUserCard, setShowUserCard] = useState(false);
    const[show2, setShow2] = useState(false);
    const[width, setWidth] = useState(window.innerWidth);
    const[menu, setMenu] = useState(false);
    const closer = ()=>{
        if(showUserCard){
           return setShowUserCard(false)
        }
    }
    useEffect(()=>{
        if(width<=500){
            setMobile(true)
        }else{
            setMobile(false)
        }
    },[width]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    return <>
    <div className='header-login-header' onMouseEnter={()=>{setShow1(false);setShow2(false);}}>
            <div className='header-login-header-left'>
            <div className='header-login-header-left-wrapper'>
            <div className='header-logo-wrapper'>
            <span className='header-login-header-left-left-tilte'>em</span>
            </div>
            {/* <span className='header-login-header-left-left-separator'>|</span> */}
            <picture style={{padding:'20px', borderRadius:'4px'}}>
                {/* <source className='header-img-src' srcset={`https://media.springernature.com/w88/springer-static/cover-hires/journal/${currentItem?.pub}?as=webp, https://media.springernature.com/w316/springer-static/cover-hires/journal/${currentItem?.pub}?as=webp 2x`} /> */}
                <img className='header-img'  style={{borderRadius:'6px',height:"15"}} data-test="darwin-journal-cover" src={`https://media.springernature.com/w88/springer-static/cover-hires/journal/${currentItem?.pub}?as=webp" srcset="https://media.springernature.com/w176/springer-static/cover-hires/journal/${currentItem?.pub}?as=webp 2x`} alt="" />
        </picture>
            </div>
            <span className='header-login-header-left-right-title'>{currentItem?.title}</span>
            </div>
            <div className='header-login-header-right'>
            {!user?[<Link to={`/${currentItem?.acronym?.toLowerCase()}/default.aspx/login`} className='header-login-header-right-title'>Login</Link>,
                <span  className='header-login-header-right-separator'>|</span>,
                <Link to={`/${currentItem?.acronym?.toLowerCase()}/default.aspx/register`} className='header-login-header-right-title'>Register</Link>]:
                [<svg xmlns="http://www.w3.org/2000/svg" style={{cursor:'pointer'}} width="1em" height="1em" viewBox="0 0 512 512" {...props} onClick={()=>setShowUserCard(!showUserCard)}>
                    <path fill="none" stroke="#1588f4" strokeLinecap="square" strokeMiterlimit={10} strokeWidth={48} d="m112 184l144 144l144-144"></path></svg>,
                <div className='header-login-header-right-wrapper'>{`${user?.firstName} ${user?.lastName}`}</div>,
                <Link className='header-login-header-right-title' onClick={()=>dispatch(logOut())}>Logout</Link>]}
            </div>
        </div>
        {
            mobile&&<span className='header-menu-wrapper' onClick={()=>setMenu(!menu)}>
            {menu?<FaTimes/>:<FaBars/>}
            </span>
        }
        <div className={!menu?'mobile-nav-down-menu':'nav-down-menu'} onMouseEnter={()=>{setShow1(false);setShow2(false);}}>
        
                <ul className={!menu?'mobile-nav-down-menu-items':'nav-down-menu-items'}>
                    <li className='nav-down-menu-link' style={{marginRight:'30px'}} onClick={()=>navigate(`/${currentItem?.acronym?.toLowerCase()}`)}>Home</li>
                    <li className='nav-down-menu-link' style={{marginRight:'30px'}} onClick={()=>navigate(`/${currentItem?.acronym?.toLowerCase()}/default.aspx`)}>Submit a manuscript</li>
                    <li className='nav-down-menu-link' onClick={()=>window.location.replace(`https://springer.com/journal/${currentItem?.pub}`)}  onMouseEnter={()=>{setShow1(!show1);setShow2(false);}}>About<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512" ><path fill="none" stroke="#1588f4" strokeLinecap="square" strokeMiterlimit={10} strokeWidth={48} d="m112 184l144 144l144-144"></path></svg> </li>
                    
                    <li className='nav-down-menu-select-container'>
                        {show1&&
                            <div onMouseLeave={()=>setShow1(!show1)} className='nav-down-menu-selection-1' title='About'>
                            <span className='nav-down-menu-link' onClick={()=>window.location.replace(`https://springer.com/journal/${currentItem?.pub}`)}>Journal overview</span>
                            <span className='nav-down-menu-link' onClick={()=>window.location.replace(`https://springer.com/journal/${currentItem?.pub}`)}>Instructions for Authors</span>
                            <a href={`mailto:em@editorialmanager.uk?text=${currentItem?.title}`} rel='noreferrer' target="_blank" className='nav-down-menu-link'>Contact</a>
                            
                            <a href={`https://www.editorialmanager.com/${currentItem?.acronym?.toLowerCase()}/PrivacyPolicy.aspx`} rel='noreferrer' target="_blank" className='nav-down-menu-link'>Privacy Policy</a>
                            <span className='nav-down-menu-link' onClick={()=>window.location.replace(`https://springer.com/journal/${currentItem?.pub}`)}></span>
                        </div>}
                    </li>
                    <li className='nav-down-menu-link' onClick={()=>window.location.replace(`https://springer.com/journal/${currentItem?.pub}`)}  onMouseEnter={()=>{setShow2(!show2);setShow1(false)}}>Help<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512" ><path fill="none" stroke="#1588f4" strokeLinecap="square" strokeMiterlimit={10} strokeWidth={48} d="m112 184l144 144l144-144"></path></svg> 
</li>
                    <li className='nav-down-menu-select-container'>
                    {show2&&
                            <div onMouseLeave={()=>{setShow2(!show2)}} className='nav-down-menu-selection-2' title='About'>
                            <span className='nav-down-menu-link' onClick={()=>window.location.replace(`https://www.editorialmanager.com/robohelp/17.0/index.htm`)}
                            >System Help</span>
                            <span className='nav-down-menu-link' onClick={()=>window.location.replace(`https://www.ariessys.com/views-and-press/resources/video-library/`)}>Video Tutorials</span>
                        </div>}
                        </li>
                </ul>
            </div>
            <div className='editorial-login-header-bottom' onClick={closer} onMouseEnter={()=>{setShow1(false);setShow2(false);}}>
            <div className='auth-wrapper'>
            {currentItem?.announcement?.status&&<span className='anouncement'><span className='anouncement-text' >Announcement</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="#fff" d="M449.07 399.08L278.64 82.58c-12.08-22.44-44.26-22.44-56.35 0L51.87 399.08A32 32 0 0 0 80 446.25h340.89a32 32 0 0 0 28.18-47.17m-198.6-1.83a20 20 0 1 1 20-20a20 20 0 0 1-20 20m21.72-201.15l-5.74 122a16 16 0 0 1-32 0l-5.74-121.95a21.73 21.73 0 0 1 21.5-22.69h.21a21.74 21.74 0 0 1 21.73 22.7Z"/></svg>
            </span>}
            <div className='user-auth-wrapper' onClick={()=>setShowUserCard(!showUserCard)}>
            <span className='user-auth'>
                <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" viewBox="0 0 24 24"><path fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" d="m7 10l5 5l5-5"/></svg>
                </span>
            </div>
            </div>
            {showUserCard&&<ul className='user-auth-wrapper-card'>
            <li className='user-auth-wrapper-card-items'><span className='user-auth-wrapper-card-item'>Username</span>
            <span className='user-auth-wrapper-card-item'>{user?.userName}</span>
            </li>
            <li className='user-auth-wrapper-card-items'><span className='user-auth-wrapper-card-item'>Role</span>
            <span className='user-auth-wrapper-card-item'>{JSON.parse(localStorage.getItem('logger'))}</span>
            </li>
            <li className='user-auth-wrapper-card-items'><span className='user-auth-wrapper-card-item'>Site Language</span>
            <span className='user-auth-wrapper-card-item'>English</span>
            </li>
            {user?.isAdmin&&<a href={`/dashboard`} className='user-auth-wrapper-card-item'>Admin Dashboard</a>}
            <a href={`/${currentItem?.acronym?.toLowerCase()}/info_update.asp`} className='user-auth-wrapper-card-item'>Update My Information</a>
            </ul>}
        </div>
    </>;
};
EditorialHeader.propTypes = {};
export { EditorialHeader };