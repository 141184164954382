import {PDFDocument, degrees,rgb, StandardFonts} from 'pdf-lib'
import { useEffect, useState } from 'react';
export const Testing = () => {
  const [pdfInfo, setPdfInfo] = useState([]);
  const[data, setData] = useState('');
  const[filePath, setFilePath] = useState('');
  const[title, setTitle] = useState('')
  const[description, setDescription] = useState('')
  const[send, setSend] = useState(false)

useEffect(()=>{
  if(filePath.length>0&&title.length>0&&description.length>0){
    modifyPdf();
  }
},[send])

  const modifyPdf = async () => {
    // const existingPdfBytes = await fetch(
    //   "https://res.cloudinary.com/xfindx/image/upload/v1717329647/dan-editor-abstract/n65e4wpqlamtewsjea9j.pdf"
    // ).then((res) => res.arrayBuffer());

    // const existingPdfBytes2 = await fetch(
    //   "https://res.cloudinary.com/xfindx/image/upload/v1717329647/dan-editor-abstract/n65e4wpqlamtewsjea9j.pdf"
    // ).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(data);
    const pdfBytes = await pdfDoc.save();
    const docUrl = URL.createObjectURL(new Blob([pdfBytes], {type: 'application/pdf'}))
    setPdfInfo(docUrl);
  };
  const handleClientFile = async(e)=>{
    let a = e.target.files[0]
    setFilePath(a.name)
    const unit8 = new Uint8Array(await a.arrayBuffer());
    setData(unit8)

    // const uint8Array = fs.readFileSync('/path/to/myFile.pdf')
    // const pdfDoc3 = await PDFDocument.load(uint8Array)
  }
  return (
    <>
    <a href={pdfInfo} download='PDF File'>Download</a>
    <iframe title="test-frame" src={pdfInfo} type="application/pdf" />
    <input type='file' onChange={handleClientFile}/>
    <input type='text' onChange={a=>setTitle(a.target.value)}/>
    <input type='text' onChange={a=>setDescription(a.target.value)}/>
    <button onClick={()=>setSend(true)}>Send</button>
    </>
  );
};
