import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { url2 } from '../../../Redux/Api';
import { FaArrowLeft, FaFunnelDollar, FaPlusSquare, FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { loadJournal } from '../../../Redux/Slices/journalSlice';
import { loadMyManuscripts } from '../../../Redux/Slices/myManuscriptsSlice';
import moment from 'moment';
import { EditorialHeader } from './EditorialHeader';


const DecisionManuscripts = props => {
const{acronym,manuid} = useParams();
const[currentId, setCurrentId] = useState(false)
const manuscripts = useSelector(state=>state.manuscripts.items)
const navigate = useNavigate()
const user = useSelector(state=>state.auth.user);
const myManuscripts = JSON.parse(localStorage.getItem('my-manuscripts'))
const[page, setPage] = useState(1)
const[pages, setPages] = useState(1)
const[stateData, setStateData] = useState([]);
const[currentPageManuscripts, setCurrentPageManuscripts] = useState([]);

useEffect(()=>{
    const a = myManuscripts.filter(fil=>fil?.incompleteSubmission===false&&fil?.submitted&&fil?.approved==='approved')
    setCurrentPageManuscripts(a)
},[myManuscripts])

useEffect(()=>{
    setStateData([])
    let a = [];
    currentPageManuscripts.forEach((el,ind) => {
      if(a.length<10){
        if(ind<=page*10&&!ind<page*10){
             a.push(el)
        }
      }
    });
    setStateData(a)
},[pages,page,currentPageManuscripts])
const handleNext = ()=>{
    setStateData([])
    setPage(page+1)
}
const handlePrev = ()=>{
    setStateData([])
    setPage(page-1)
}
useEffect(()=>{
    const a = myManuscripts.filter(fil=>fil?.incompleteSubmission===false&&fil?.submitted&&fil?.approved==='approved').length
    let b = a/10
    let d = a%10;
   if(a>10){
    if(d===0){
    setPages(b)
    }else{
        let c = a - d
        setPages((c/10)+1)
    }
   }else{
    setPages(1)
   }
},[currentPageManuscripts])

const[showUserCard, setShowUserCard] = useState(false);
const[newState, setNewState] = useState({status:'',data:''});

const dispatch = useDispatch()

const books = useSelector(state=>state.journals.items);

const currentItem = useSelector(state=>state.journal.journal);

const handleManuscriptRemoval = async(e)=>{
    await fetch(`${url2}/remove-manuscript`,{
        method:'post',
        headers:{
            'Content-Type':'application/json',
            'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
        },
        body:JSON.stringify({manuId:e})
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            setNewState({status:'success',data:resp.resp})
            window.location.reload();
            navigate(`/${currentItem?.acronym?.toLowerCase()}`)
        }else if(resp.err){
            setNewState({status:'failed',data:resp.err})
        }else{
            setNewState({status:'failed',data:'Sorry, something went wrong.'})
        }
    }).catch(err=>{
        setNewState({status:'failed',data:err.message})
    })
}
const approve = async(item)=>{
    async function fetchData(){
        await fetch(`${url2}/approve-manuscript/${item._id}`,{
            mode:'cors',
            method:'put',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            }
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                 setNewState({status:'success',data:resp.resp})
                return window.location.reload()
            }else if(resp.err){
                return setNewState({status:'failed',data:resp.err})
            }else{
                return setNewState({status:'failed',data:'Something went wrong.'})
            }
        }).catch(err=>setNewState({status:'failed',data:err.message}))
    }
    fetchData()
}
useEffect(()=>{
        if(!currentItem?.acronym||!user){
            const interval = setInterval(() => {
                window.location.reload()
            }, 1000);
            return ()=>clearInterval(interval)
        }
},[user])

useEffect(()=>{
    if(currentItem?.pub?.toString()===user?.pub?.toString()){
            return 
    }else{
        return navigate(`/${currentItem?.acronym?.toLowerCase()}/default.aspx/login`)
    }
},[user])
  const[showManuscriptStatus, setShowManuscriptStatus] = useState(false);

const closer = ()=>{
    if(showManuscriptStatus){
        setShowManuscriptStatus(false)
    }
    if(showUserCard){
        setShowUserCard(false)
    }
}
const handleSubmitManuscript = async(e)=>{
    await fetch(`${url2}/user-confirming-submission`,{
        method:'post',
        headers:{
            'Content-Type':'application/json',
            'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
        },
        body:JSON.stringify({manuId:e})
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            setNewState({status:'success',data:resp.resp})
            window.location.reload();
            navigate(`/${currentItem?.acronym?.toLowerCase()}`)
        }else if(resp.err){
            setNewState({status:'failed',data:resp.err})
        }else{
            setNewState({status:'failed',data:'Sorry, something went wrong.'})
        }
    }).catch(err=>{
        setNewState({status:'failed',data:err.message})
    })
}

    return(
        <div className='editorial-overview-main' onClick={closer}>
            <EditorialHeader />
        <div className='editorial-overview-body'>
        <div className='confirmation-body'>
            <div className='confirmation-body-top'>
                <div className='confirmation-body-inner-top' onClick={()=>navigate(`/${currentItem?.acronym?.toLowerCase()}`)}>
                <FaArrowLeft className='confirmation-body-inner-top-icon' /><span className='confirmation-body-inner-top-text'>Submissions with an Editorial Office Decision for Author</span>
                </div>
                <div className='confirmation-body-inner-bottom' style={{backgroundColor:'white',color:'white',height:'0px'}}>
                <p className='confirmation-body-top-content'>The 'Edit Submission' link allows you to fix or alter your submision. Please use Edit Submission to make changes to the meta-data and to remove and upload new files that make up your submision.
                The 'Remove Submission' link removes your submision from the system. Please use this ONLY if you would like to permanently remove this submision from the system.</p>
                </div>
            </div>
            {
                newState.data?.length>0&&
                <span className={newState.status==='success'?'success':'error'}>{newState.data}</span>
            }
            <div className='confirmation-body-bottom'>
            <div className='pagination-container-top'>
<div className='pagination-item'>
<p className='pagination-left'>
Page: {page} of {pages} ({`${currentPageManuscripts.length} total completed submissions`})
                </p>

</div>
<div className='pagination-item'>
<p className='pagination-right'>
Results per page 10
                </p>

</div>
               </div>
                <table className='dashboard-table'>
                    <thead>
                        <tr>
                        <th>
                                <div className='table-action-head'>
                                <span>Action</span>
                    <span className='table-head-icons'><FaPlusSquare /><FaFunnelDollar/></span>
                                </div></th>
                            <th>Manuscript<br/> Number</th>

<th>Title</th>
                        <th>Initial Date <br/>Submitted</th>
                        <th>Current <br/>Status</th>
                        <th>Date of <br/>Final Decision</th>
                        <th>Decision</th>
                            </tr>
                    </thead>
                    <tbody>
                       {
                        myManuscripts?.length>0&&
                        myManuscripts.filter(item=>item.submitted&&item.approved==='approved'&&!item.published).map((item,index)=>{
                            return(
                                <tr key={index}>
                                <td>
                                {
                    showManuscriptStatus&&item?._id===currentId?
                    <ul className='table-card'>
                    <li><a href='mailto:em@editorialmanager.uk' style={{color:'var(--secondary-color)',textDecoration:'none'}}>Send E-mail</a></li>
                </ul>:
                                    <a style={{textDecoration:'none',cursor:'pointer'}} onClick={()=>{setShowManuscriptStatus(!showManuscriptStatus);setCurrentId(item._id)}}>Action Links</a>

                }
                                </td>
                                <td>{item?.manuN}</td>
                                <td>{item?.manuscript?.title}</td>
                                <td>{moment(item?.submissionDate?.toString()).format('MMM DD, yyyy')}</td>
                                <td>Approved</td>                                
                                <td>{moment(item?.decisionDate?.toString()).format('MMM DD, yyyy')}</td>                                
                                <td>Accepted</td>
                                </tr>
                            )
                        })
                       }
                    </tbody>
                </table>
                <div className='pagination-container-bottom'>
<div className='pagination-item'>
<p className='pagination-left'>
Page: {page} of {pages} ({`${currentPageManuscripts.length} total completed submissions`})
                </p>
</div>
<div className='pagination-item'>
<p className='pagination-right'>
Results per page 10
                </p>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512" {...props}><path fill="none" stroke="#1588f4" strokeLinecap="square" strokeMiterlimit={10} strokeWidth={48} d="m112 184l144 144l144-144"></path></svg>
                {
    pages>1&&<div>
    {
        page<pages?
        <div className='page-control' onClick={handleNext}>Next<svg fill="rgb(36, 116, 255)" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 52 52" enable-background="new 0 0 52 52" transform="rotate(270)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M47.6,17.8L27.1,38.5c-0.6,0.6-1.6,0.6-2.2,0L4.4,17.8c-0.6-0.6-0.6-1.6,0-2.2l2.2-2.2 c0.6-0.6,1.6-0.6,2.2,0l16.1,16.3c0.6,0.6,1.6,0.6,2.2,0l16.1-16.2c0.6-0.6,1.6-0.6,2.2,0l2.2,2.2C48.1,16.3,48.1,17.2,47.6,17.8z"></path> </g></svg>
        </div>:
        <div className='page-control' onClick={handlePrev}>
        <svg fill="rgb(36, 116, 255)" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 52 52" enable-background="new 0 0 52 52" transform="rotate(90)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M47.6,17.8L27.1,38.5c-0.6,0.6-1.6,0.6-2.2,0L4.4,17.8c-0.6-0.6-0.6-1.6,0-2.2l2.2-2.2 c0.6-0.6,1.6-0.6,2.2,0l16.1,16.3c0.6,0.6,1.6,0.6,2.2,0l16.1-16.2c0.6-0.6,1.6-0.6,2.2,0l2.2,2.2C48.1,16.3,48.1,17.2,47.6,17.8z"></path> </g></svg>
        Prev
        </div>
    }
    </div>
}
</div>
               </div>
            </div>
        </div>
        </div>

        </div>
    );
};

DecisionManuscripts.propTypes = {};

export { DecisionManuscripts };