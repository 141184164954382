import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './PublishersDashboard.css'
import { FaArrowLeft, FaBars, FaBoxOpen, FaPlus, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { clientUrl, url2 } from '../Redux/Api';
import { useSelector } from 'react-redux';

const BooksDashboard = props => {
    const books = useSelector(state=>state.journals.items);
    const currentItem = useSelector(state=>state.journal.journal);
    const[bookForm, setBookForm] = useState(false);
    const[newState, setNewState] = useState({status:'',data:''});
    const[title, setTitle] = useState('')
    const[pub, setPub] = useState('')
    const[description, setDescription] = useState('')
    const[overView, setOverview] = useState('')
    const[model, setModel] = useState('')
    const[type, setType] = useState('')
    const[impactFactor, setImpactFactor] = useState('')
    const[impactYear, setImpactYear] = useState('')
    const[submission, setSubmission] = useState('')
    const[downloads, setDownloads] = useState('')
    const[printISN, setPrintISN] = useState('')
    const[electronicISN, setElectronicISN] = useState('')
    const[art1Content,setart1Content] = useState('')
const[art1Title,setart1Title] = useState('')
const[art1Year,setart1Year] = useState('')
const[art2Content,setart2Content] = useState('')
const[art2Title,setart2Title] = useState('')
const[art2Year,setart2Year] = useState('')
const[art3Content,setart3Content] = useState('')
const[art3Title,setart3Title] = useState('')
const[art3Year,setart3Year] = useState('')
const[art4Content,setart4Content] = useState('')
const[art4Title,setart4Title] = useState('')
const[art4Year,setart4Year] = useState('')
const[art5Content,setart5Content] = useState('')
const[art5Title,setart5Title] = useState('')
const[art5Year,setart5Year] = useState('')
const[art1TitleLink,setart1TitleLink] = useState('')
const[art2TitleLink,setart2TitleLink] = useState('')
const[art3TitleLink,setart3TitleLink] = useState('')
const[art4TitleLink,setart4TitleLink] = useState('')
const[art5TitleLink,setart5TitleLink] = useState('')
const[allArticlesLink,setallArticlesLink] = useState('')
const[journalInfo1,setjournalInfo1] = useState('')
const[journalInfo10,setjournalInfo10] = useState('')
const[journalInfo11,setjournalInfo11] = useState('')
const[journalInfo12,setjournalInfo12] = useState('')
const[journalInfo13,setjournalInfo13] = useState('')
const[journalInfo14,setjournalInfo14] = useState('')
const[journalInfo15,setjournalInfo15] = useState('')
const[journalInfo16,setjournalInfo16] = useState('')
const[journalInfo17,setjournalInfo17] = useState('')
const[journalInfo18,setjournalInfo18] = useState('')
const[journalInfo19,setjournalInfo19] = useState('')
const[journalInfo2,setjournalInfo2] = useState('')
const[journalInfo20,setjournalInfo20] = useState('')
const[journalInfo21,setjournalInfo21] = useState('')
const[journalInfo22,setjournalInfo22] = useState('')
const[journalInfo23,setjournalInfo23] = useState('')
const[journalInfo24,setjournalInfo24] = useState('')
const[journalInfo25,setjournalInfo25] = useState('')
const[journalInfo26,setjournalInfo26] = useState('')
const[journalInfo27,setjournalInfo27] = useState('')
const[journalInfo28,setjournalInfo28] = useState('')
const[journalInfo29,setjournalInfo29] = useState('')
const[journalInfo3,setjournalInfo3] = useState('')
const[journalInfo30,setjournalInfo30] = useState('')
const[journalInfo4,setjournalInfo4] = useState('')
const[journalInfo5,setjournalInfo5] = useState('')
const[journalInfo6,setjournalInfo6] = useState('')
const[journalInfo7,setjournalInfo7] = useState('')
const[journalInfo8,setjournalInfo8] = useState('')
const[journalInfo9,setjournalInfo9] = useState('')
const[journalUpdate1Content,setjournalUpdate1Content] = useState('')
const[journalUpdate2Content,setjournalUpdate2Content] = useState('')
const[journalUpdate3Content,setjournalUpdate3Content] = useState('')
const[journalUpdateCaption1,setjournalUpdateCaption1] = useState('')
const[journalUpdateCaption2,setjournalUpdateCaption2] = useState('')
const[journalUpdateCaption3,setjournalUpdateCaption3] = useState('')
const[journalUpdateLink1,setjournalUpdateLink1] = useState('')
const[journalUpdateLink2,setjournalUpdateLink2] = useState('')
const[journalUpdateLink3,setjournalUpdateLink3] = useState('')
const[openAccessNo,setopenAccessNo] = useState('')

const[name, setName] = useState('')
const[city, setCity] = useState('')
const[address, setAddress] = useState('')
const[country, setCountry] = useState('')
const[institutionForm, setInstitutionForm] = useState(false);

const handleNewInstitution = async(e)=>{
    e.preventDefault()
    async function fetchData(){
        await fetch(`${url2}/new-institution`,{
            mode:'cors',
            method:'post',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({
                name,country,city,address
            })
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                return setNewState({status:'success',data:resp.resp})
            }else if(resp.err){
                return setNewState({status:'failed',data:resp.err})
            }else{
                return setNewState({status:'failed',data:'Something went wrong.'})
            }
        }).catch(err=>setNewState({status:'failed',data:err.message}))
    }
    fetchData()
}
    const handleAddBook = async(e)=>{
        e.preventDefault()
        async function fetchData(){
            await fetch(`${url2}/add-book`,{
                mode:'cors',
                method:'post',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                },body:JSON.stringify({
                    pub,title,description,overView,model,type,impactFactor,impactYear,
                submission,downloads,printISN,electronicISN,art1Content,
                art1Title,art1Year,art2Content,art2Title,art2Year,art3Content,art3Title,art3Year,art4Content,art4Title,art4Year,art5Content,art5Title,art5Year,art1TitleLink,art2TitleLink,art3TitleLink,art4TitleLink,art5TitleLink,allArticlesLink,journalInfo1,journalInfo10,journalInfo11,journalInfo12,journalInfo13,
                journalInfo14,journalInfo15,journalInfo16,journalInfo17,journalInfo18,journalInfo19,journalInfo2,journalInfo20,journalInfo21,journalInfo22,journalInfo23,journalInfo24,journalInfo25,journalInfo26,journalInfo27,journalInfo28,journalInfo29,journalInfo3,journalInfo30,journalInfo4,journalInfo5,journalInfo6,
                journalInfo7,journalInfo8,journalInfo9,journalUpdate1Content,journalUpdate2Content,journalUpdate3Content,journalUpdateCaption1,journalUpdateCaption2,journalUpdateCaption3,journalUpdateLink1,journalUpdateLink2,journalUpdateLink3,openAccessNo,
                })
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    return setNewState({status:'success',data:resp.resp})
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }



    const handleDelete = async(item)=>{
        async function fetchData(){
            await fetch(`${url2}/delete-manuscript/${item._id}`,{
                mode:'cors',
                method:'delete',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    return setNewState({status:'success',data:resp.resp})
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }
    const handleUnpublish = async(item)=>{
        async function fetchData(){
            await fetch(`${url2}/unpublish-manuscript/${item._id}`,{
                mode:'cors',
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    return setNewState({status:'success',data:resp.resp})
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }
    const handlePublish = async(item)=>{
        async function fetchData(){
            await fetch(`${url2}/publish-manuscript/${item._id}`,{
                mode:'cors',
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    return setNewState({status:'success',data:resp.resp})
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }


    const approve = async(item)=>{
        async function fetchData(){
            await fetch(`${url2}/approve-manuscript/${item._id}`,{
                mode:'cors',
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    return setNewState({status:'success',data:resp.resp})
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }
    const disApprove = async(item)=>{
        async function fetchData(){
            await fetch(`${url2}/disapprove-manuscript/${item._id}`,{
                mode:'cors',
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    return setNewState({status:'success',data:resp.resp})
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }
    
    const[publisher, setPublisher] = useState(null)

    return <div className='admin-dashboard-child-container'>
        <span className={newState.status==='success'?'publisher-detail-success':'publisher-detail-error'}>{newState.data}</span>
{
    publisher?
    <div className='publisher-detail'>
    <div className='publishers-main-dashboard-detail'>
                    <div className='author-container-dashboard'>
                    <h1 className={publisher.approved==='approved'?'approved':publisher.approved==='pending'?'pending':'rejected'}>
                    {publisher.approved==='approved'?'Approved':publisher.approved==='pending'?'Pending':publisher.approved==='rejected'?'Rejected':''}
                    </h1>
                    <button className={publisher.approved==='approved'?'approved-btn':publisher.approved==='pending'?'pending-btn':'rejected-btn'}>
                    {publisher.approved==='approved'?'cancel':publisher.approved==='pending'?'Cancel':publisher.approved==='rejected'?'Approve':''}
                    </button>
                    {

                    }
                    </div>
                    <div className='book-container-dashboard'>
                    <p className='book-info'>Journal ID: {publisher.pub} edition</p>
                    <p className='book-info'>Title {publisher.tile}</p> 
                    <p className='book-info'>Model {publisher.model}</p>
                    <p className='book-info'>Price {publisher.price}</p>
                    <FaTimes className='icon' onClick={()=>setPublisher(null)}/>
                    <button className='action-btn' onClick={()=>handleDelete(publisher)}>Delete</button>
                    </div>
                </div>
</div>:<div className='publishers-main'>
    <h2 className='publishers-page-title'>{books?.length}{books?.length>1?' Journals':' Journal'}</h2>
    <div className='add-new-book-container'>
    <div className='add-new-book-item-container'>
    <div className='add-new-book-item-wrapper' onClick={()=>setBookForm(!bookForm)}>
    {!bookForm?<FaPlus className='add-new-book-item-icon' />:<FaArrowLeft className='add-new-book-item-icon' />}{!bookForm?<span className='add-new-book-item-text'>New Jornal</span>:<span className='add-new-book-item-text'>Close Form</span>}
    </div>
    <div className='add-new-book-item-wrapper' onClick={()=>setInstitutionForm(!institutionForm)}>
    {!institutionForm?<FaPlus className='add-new-book-item-icon' />:<FaArrowLeft className='add-new-book-item-icon' />}{!institutionForm?<span className='add-new-book-item-text'>New Institution</span>:<span className='add-new-book-item-text'>Close Form</span>}
    </div>
    </div>
    {
        institutionForm&&
        <form onSubmit={handleNewInstitution} className='add-book-form'>
            <div className='add-book-form-container'>
            <input type='text' className='add-book-input' placeholder='Name of Institution' onChange={e=>setName(e.target.value)}/>
            <input type='text' className='add-book-input' placeholder='City' onChange={e=>setCity(e.target.value)}/>
            <input type='text' placeholder='Country' className='add-book-input' onChange={e=>setCountry(e.target.value)}/>
            <input type='text' placeholder='Address' className='add-book-input' onChange={e=>setAddress(e.target.value)}/>
    
    </div>
            <button type='submit' className='add-book-btn'>Add Institution</button>
        </form>
    }
    {
        bookForm&&
        <form onSubmit={handleAddBook} className='add-book-form'>
            <div className='add-book-form-container'>
            <textarea type='text' placeholder='Book Title' className='add-book-text-area' onChange={e=>setTitle(e.target.value)}/>
            <textarea type='text' placeholder='Overview' className='add-book-text-area' onChange={e=>setOverview(e.target.value)}/>
            <textarea type='text' placeholder='Description' className='add-book-text-area' onChange={e=>setDescription(e.target.value)}/>
            <input type='number' step='any' placeholder='Book ID' className='add-book-input' onChange={e=>setPub(e.target.value)}/>
            <input type='text' placeholder='Book Type' className='add-book-input' onChange={e=>setType(e.target.value)}/>
            <input type='text' placeholder='Model' className='add-book-input' onChange={e=>setModel(e.target.value)}/>
            <input type='text' placeholder='Impact Factor' className='add-book-input' onChange={e=>setImpactFactor(e.target.value)}/>
            <input type='text' placeholder='Impact Year' className='add-book-input' onChange={e=>setImpactYear(e.target.value)}/>
            <input type='text' placeholder='Submission' className='add-book-input' onChange={e=>setSubmission(e.target.value)}/>
            <input type='text' placeholder='Downloads' className='add-book-input' onChange={e=>setDownloads(e.target.value)}/>
            <input type='text' placeholder='Print ISSN' className='add-book-input' onChange={e=>setPrintISN(e.target.value)}/>
            <input type='text' placeholder='Electronic ISSN' className='add-book-input' onChange={e=>setElectronicISN(e.target.value)}/>
<input type='text' placeholder='art1Content' className='add-book-input' onChange={e=>setart1Content(e.target.value)}/>
<input type='text' placeholder='art1Title' className='add-book-input' onChange={e=>setart1Title(e.target.value)}/>
<input type='text' placeholder='art1Year' className='add-book-input' onChange={e=>setart1Year(e.target.value)}/>
<input type='text' placeholder='art2Content' className='add-book-input' onChange={e=>setart2Content(e.target.value)}/>
<input type='text' placeholder='art2Title' className='add-book-input' onChange={e=>setart2Title(e.target.value)}/>
<input type='text' placeholder='art2Year' className='add-book-input' onChange={e=>setart2Year(e.target.value)}/>
<input type='text' placeholder='art3Content' className='add-book-input' onChange={e=>setart3Content(e.target.value)}/>
<input type='text' placeholder='art3Title' className='add-book-input' onChange={e=>setart3Title(e.target.value)}/>
<input type='text' placeholder='art3Year' className='add-book-input' onChange={e=>setart3Year(e.target.value)}/>
<input type='text' placeholder='art4Content' className='add-book-input' onChange={e=>setart4Content(e.target.value)}/>
<input type='text' placeholder='art4Title' className='add-book-input' onChange={e=>setart4Title(e.target.value)}/>
<input type='text' placeholder='art4Year' className='add-book-input' onChange={e=>setart4Year(e.target.value)}/>
<input type='text' placeholder='art5Content' className='add-book-input' onChange={e=>setart5Content(e.target.value)}/>
<input type='text' placeholder='art5Title' className='add-book-input' onChange={e=>setart5Title(e.target.value)}/>
<input type='text' placeholder='art5Year' className='add-book-input' onChange={e=>setart5Year(e.target.value)}/>
<input type='text' placeholder='art1TitleLink' className='add-book-input' onChange={e=>setart1TitleLink(e.target.value)}/>
<input type='text' placeholder='art2TitleLink' className='add-book-input' onChange={e=>setart2TitleLink(e.target.value)}/>
<input type='text' placeholder='art3TitleLink' className='add-book-input' onChange={e=>setart3TitleLink(e.target.value)}/>
<input type='text' placeholder='art4TitleLink' className='add-book-input' onChange={e=>setart4TitleLink(e.target.value)}/>
<input type='text' placeholder='art5TitleLink' className='add-book-input' onChange={e=>setart5TitleLink(e.target.value)}/>
<input type='text' placeholder='allArticlesLink' className='add-book-input' onChange={e=>setallArticlesLink(e.target.value)}/>
<input type='text' placeholder='journalInfo1' className='add-book-input' onChange={e=>setjournalInfo1(e.target.value)}/>
<input type='text' placeholder='journalInfo10' className='add-book-input' onChange={e=>setjournalInfo10(e.target.value)}/>
<input type='text' placeholder='journalInfo11' className='add-book-input' onChange={e=>setjournalInfo11(e.target.value)}/>
<input type='text' placeholder='journalInfo12' className='add-book-input' onChange={e=>setjournalInfo12(e.target.value)}/>
<input type='text' placeholder='journalInfo13' className='add-book-input' onChange={e=>setjournalInfo13(e.target.value)}/>
<input type='text' placeholder='journalInfo14' className='add-book-input' onChange={e=>setjournalInfo14(e.target.value)}/>
<input type='text' placeholder='journalInfo15' className='add-book-input' onChange={e=>setjournalInfo15(e.target.value)}/>
<input type='text' placeholder='journalInfo16' className='add-book-input' onChange={e=>setjournalInfo16(e.target.value)}/>
<input type='text' placeholder='journalInfo17' className='add-book-input' onChange={e=>setjournalInfo17(e.target.value)}/>
<input type='text' placeholder='journalInfo18' className='add-book-input' onChange={e=>setjournalInfo18(e.target.value)}/>
<input type='text' placeholder='journalInfo19' className='add-book-input' onChange={e=>setjournalInfo19(e.target.value)}/>
<input type='text' placeholder='journalInfo2' className='add-book-input' onChange={e=>setjournalInfo2(e.target.value)}/>
<input type='text' placeholder='journalInfo20' className='add-book-input' onChange={e=>setjournalInfo20(e.target.value)}/>
<input type='text' placeholder='journalInfo21' className='add-book-input' onChange={e=>setjournalInfo21(e.target.value)}/>
<input type='text' placeholder='journalInfo22' className='add-book-input' onChange={e=>setjournalInfo22(e.target.value)}/>
<input type='text' placeholder='journalInfo23' className='add-book-input' onChange={e=>setjournalInfo23(e.target.value)}/>
<input type='text' placeholder='journalInfo24' className='add-book-input' onChange={e=>setjournalInfo24(e.target.value)}/>
<input type='text' placeholder='journalInfo25' className='add-book-input' onChange={e=>setjournalInfo25(e.target.value)}/>
<input type='text' placeholder='journalInfo26' className='add-book-input' onChange={e=>setjournalInfo26(e.target.value)}/>
<input type='text' placeholder='journalInfo27' className='add-book-input' onChange={e=>setjournalInfo27(e.target.value)}/>
<input type='text' placeholder='journalInfo28' className='add-book-input' onChange={e=>setjournalInfo28(e.target.value)}/>
<input type='text' placeholder='journalInfo29' className='add-book-input' onChange={e=>setjournalInfo29(e.target.value)}/>
<input type='text' placeholder='journalInfo3' className='add-book-input' onChange={e=>setjournalInfo3(e.target.value)}/>
<input type='text' placeholder='journalInfo30' className='add-book-input' onChange={e=>setjournalInfo30(e.target.value)}/>
<input type='text' placeholder='journalInfo4' className='add-book-input' onChange={e=>setjournalInfo4(e.target.value)}/>
<input type='text' placeholder='journalInfo5' className='add-book-input' onChange={e=>setjournalInfo5(e.target.value)}/>
<input type='text' placeholder='journalInfo6' className='add-book-input' onChange={e=>setjournalInfo6(e.target.value)}/>
<input type='text' placeholder='journalInfo7' className='add-book-input' onChange={e=>setjournalInfo7(e.target.value)}/>
<input type='text' placeholder='journalInfo8' className='add-book-input' onChange={e=>setjournalInfo8(e.target.value)}/>
<input type='text' placeholder='journalInfo9' className='add-book-input' onChange={e=>setjournalInfo9(e.target.value)}/>
<input type='text' placeholder='journalUpdate1Content' className='add-book-input' onChange={e=>setjournalUpdate1Content(e.target.value)}/>
<input type='text' placeholder='journalUpdate2Content' className='add-book-input' onChange={e=>setjournalUpdate2Content(e.target.value)}/>
<input type='text' placeholder='journalUpdate3Content' className='add-book-input' onChange={e=>setjournalUpdate3Content(e.target.value)}/>
<input type='text' placeholder='journalUpdateCaption1' className='add-book-input' onChange={e=>setjournalUpdateCaption1(e.target.value)}/>
<input type='text' placeholder='journalUpdateCaption2' className='add-book-input' onChange={e=>setjournalUpdateCaption2(e.target.value)}/>
<input type='text' placeholder='journalUpdateCaption3' className='add-book-input' onChange={e=>setjournalUpdateCaption3(e.target.value)}/>
<input type='text' placeholder='journalUpdateLink1' className='add-book-input' onChange={e=>setjournalUpdateLink1(e.target.value)}/>
<input type='text' placeholder='journalUpdateLink2' className='add-book-input' onChange={e=>setjournalUpdateLink2(e.target.value)}/>
<input type='text' placeholder='journalUpdateLink3' className='add-book-input' onChange={e=>setjournalUpdateLink3(e.target.value)}/>
<input type='text' placeholder='openAccessNo' className='add-book-input' onChange={e=>setopenAccessNo(e.target.value)}/>
    
    </div>
            <button type='submit' className='add-book-btn'>Add Journal</button>
        </form>
    }
    </div>
    <div className='main-items-container'>
    {
        books?.length>0&&books?.map((item,index)=>{
            return(
                <div className='publishers-main-dashboard' key={index}>
                    <div className='author-container-dashboard'>
                    <h1 className='author-title-dashboard'>Type {item.type}</h1>
                    <h1 className='author-city-dashboard'>Gen. Info{item.title}</h1>
                    </div>
                    <div className='book-container-dashboard'>
                    <p className='book-info'>{item.description}</p>
                    <a href={`https://wa.me/?text=${item?.title} ${clientUrl}/journal/${item?.pub}`} rel='noreferrer' target="_blank" className='action-btn'>Share {item?.acronym}</a> 
                    <FaBars className='icon' onClick={()=>setPublisher(item)}/>
                    </div>
                </div>

            )
        })
    }
    </div>
    </div>
}
    </div>

};

BooksDashboard.propTypes = {};

export { BooksDashboard };