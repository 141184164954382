import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './dashboard.css';
import { FaBars, FaBook, FaBookOpen, FaHome, FaPowerOff, FaTimes, FaUsers } from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';
import { BooksDashboard } from './BooksDashboard';
import { ManuscriptsDashboard } from './ManuscriptDashboard';
import { UsersDashboard } from './UsersDashboard';
import { logOut } from '../Redux/Slices/AuthSlice';
import { useDispatch } from 'react-redux';
import { getUsers, loadUsers } from '../Redux/Slices/usersSlice';

const Dashboard = props => {
    const[content, setContent] = useState('') 
    const navigate = useNavigate();
    const dispatch = useDispatch()
const hanldeAuth = ()=>{
    dispatch(logOut())
    return navigate(-1)
}

useEffect(()=>{
    dispatch(getUsers())
    },[])
    
const[menuOpen, setMenuOpen] = useState(false);
    
    return <div className='dashboard-main'>
        <div className='menu-wrapper'>
        {!menuOpen?<FaBars className={menuOpen?'menu-openers':'menu-opener-hiddens'} onClick={()=>setMenuOpen(!menuOpen)}/>:
        <FaTimes className={menuOpen?'menu-closer-hiddens':'menu-closers'} onClick={()=>setMenuOpen(!menuOpen)}/>}    
        </div>
        <div className={!menuOpen?'dashboard-left':'dashboard-left-mobile'}>
            <div className='link-wrapper' onClick={()=>navigate(-1)}><FaHome className='link-icon' /><span className='link-title'>Home</span></div>
            <div className='link-wrapper' onClick={()=>setContent('users')}><FaUsers className='link-icon' /><span className='link-title'>Users</span></div>
            <div className='link-wrapper' onClick={()=>setContent('books')}><FaBook className='link-icon' /><span className='link-title'>Journals</span></div>
            <div className='link-wrapper' onClick={()=>setContent('manuscripts')}><FaBookOpen className='link-icon' /><span className='link-title'>Manuscripts</span></div>
            <div className='link-wrapper' onClick={hanldeAuth}><FaPowerOff className='link-icon' /><span className='link-title'>Logout</span></div>
        </div>
        <div className='dashboard-right'>
        <div className='dashboard-bottom'>
            <div className='dashboard-content'>
                {content=='manuscripts'?<ManuscriptsDashboard />:content=='books'?<BooksDashboard />:content=='users'?<UsersDashboard/>:<ManuscriptsDashboard />}
            </div>
        </div>
        </div>
    </div>;
};

Dashboard.propTypes = {};

export { Dashboard };