import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './UserDashboard.css'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { url2 } from '../../../Redux/Api';
import { useDispatch, useSelector } from 'react-redux';
import { loadJournal } from '../../../Redux/Slices/journalSlice';
import { loadMyManuscripts } from '../../../Redux/Slices/myManuscriptsSlice';
import { EditorialHeader } from './EditorialHeader';
import { ManuscriptHeader } from './ManuscriptHeader';
import { FaUser } from 'react-icons/fa';
import { logOut } from '../../../Redux/Slices/AuthSlice';

const UserDashboard = props => {
const{acronym} = useParams();
const manuscripts = useSelector(state=>state.manuscripts?.items)
const navigate = useNavigate()
const user = useSelector(state=>state.auth.user);
const[showUserCard, setShowUserCard] = useState(false);
const[newState, setNewState] = useState({status:'',data:''});
const[myManuscripts, setMyManuscripts] = useState([])

useEffect(()=>{
    const vals = manuscripts?.filter(item=>item?.author?.toString()===user?.email?.toString())
    if(vals&&vals?.length>0){
        setMyManuscripts(vals)
    localStorage.setItem('my-manuscripts',JSON.stringify(vals))
    }
},[manuscripts])

const dispatch = useDispatch()

const books = useSelector(state=>state.journals.items);

useEffect(()=>{
const book = books?.filter(item=>item?.acronym?.toLowerCase()===acronym?.toLowerCase());
    if(book?.length>0){
        dispatch(loadJournal(book[0]));
    }else{
        return
    }
},[]);

useEffect(()=>{
    dispatch(loadMyManuscripts())
},[manuscripts])
const currentItem = useSelector(state=>state.journal.journal);

useEffect(()=>{
    const book = books?.filter(item=>item?.acronym?.toLowerCase()===acronym?.toLowerCase());
        if(book?.length>0){
            dispatch(loadJournal(book[0]));
        }
    },[]);

useEffect(()=>{
        if(currentItem?.acronym&&currentItem?.acronym?.length>0){
            if(!user){
                const interval = setInterval(() => {
                    window.location.reload()
                }, 3000);
                return ()=>clearInterval(interval)
            }
            return
        }else if(!currentItem?.acronym){
            const interval = setInterval(() => {
                window.location.reload()
            }, 3000);
            return ()=>clearInterval(interval)
        }else if(currentItem?.acronym&&user){
            return
        }

},[user,currentItem])

const handleDeleteManuscript = async(item)=>{
    async function fetchData(){
        await fetch(`${url2}/delete-manuscript/${item._id}`,{
            mode:'cors',
            method:'delete',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            }
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setNewState({status:'success',data:resp.resp})
                return window.location.reload()
            }else if(resp.err){
                return setNewState({status:'failed',data:resp.err})
            }else{
                return setNewState({status:'failed',data:'Something went wrong.'})
            }
        }).catch(err=>setNewState({status:'failed',data:err.message}))
    }
    fetchData()
}

const closer = ()=>{
    if(showUserCard){
        setShowUserCard(false)
    }
}
const[showIncompleteModal,setShowIncompleteModal] = useState(false);
const[showIncompleteModalData,setShowIncompleteModalData] = useState([]);
const InCompleteModal = <div className='incomplete-modal'>
{
showIncompleteModalData.map((item,index)=>{
    return(<>
        {item?<div className='incomplete-modal-container' key={index}>
            <h2 className='incomplete-modal-text'>{item?.manuscript?.title}</h2>
            <div className='incomplete-modal-btn-group'>
            <button className='incomplete-modal-btn' onClick={()=>handleDeleteManuscript(item)}>Close</button>
            <button className='incomplete-modal-btn' onClick={()=>{setShowIncompleteModal(false);navigate(`/${currentItem?.acronym.toLowerCase()}/${item?._id}/resume-submission`)}}>Continue</button>
            </div>
        </div>:
        null}</>
    )
})
}
</div>
const handleCompletionStatusCheck = ()=>{
    let a = myManuscripts.filter(itm=>itm.incompleteSubmission)
    if(a.length>0){
        setShowIncompleteModal(true);
        setShowIncompleteModalData(a);
    }else{
        return navigate(`/${currentItem?.acronym?.toLowerCase()}/default.aspx`)
    }
    
}

useEffect(()=>{
    const currentPub = currentItem?.pub
    const interval = setInterval(() => {
       if(currentPub){
        if(user){
            if(user?.pub?.toString()===currentPub?.toString()){
                return
            }else{
                return dispatch(logOut())
            }
        }else if(!user){
            return navigate(`/${currentItem?.acronym?.toLowerCase()}/default.aspx/login`)
        }else{
            return
        }
       }else{
        window.location.replace('https://link.sprlnger.com/gp')
       }
    }, 1000);
    return ()=>clearInterval(interval)
},[user,currentItem?.pub])

return(

<div className='editorial-overview-main' onClick={closer}>
<EditorialHeader/>
        
        {
                    showIncompleteModal?
                    InCompleteModal:
                    <div className='editorial-overview-body' onClick={()=>setShowUserCard(false)}>
        <div className='editorial-overview-body-left'>
            <h1 className='editorial-overview-body-left-title'>Author Main Menu</h1>
            <Link to={`/manuscript-services/${currentItem?.pub}`} className='editorial-overview-body-left-link'>Manuscript Services</Link>
            <Link to={`/manuscript-services/${currentItem?.pub}`} className='editorial-overview-body-left-link'>Alternate Contact Information</Link>
            <Link to={`/manuscript-services/${currentItem?.pub}`} className='editorial-overview-body-left-link'>Unavailable Dates</Link>
        </div>
       <div className='editorial-overview-body-right'>
                
                <div className='editorial-overview-sub'>
                    <h1 className='editorial-overview-title'>New submissions</h1>
                    <div className='editorial-overview-inner'>
                        <li className='editorial-overview-inner-link' style={{cursor:'pointer'}} onClick={handleCompletionStatusCheck}>Sumit new manuscript</li>
                        
                        {/* <div className='editorial-overview-inner-wrapper'>
                            {myManuscripts?.filter(fil=>fil.approved==="rejected"&&fil.submitted).length<1?
                                <>
                                <span className='editorial-overview-inner-child'>Submissions sent back to author</span><span className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>fil.approved==="rejected"&&fil.submitted).length})`}</span>
                                </>:
                                <>
                                <a href={`/${currentItem?.acronym?.toLowerCase()}/rejected-submissions`}  className='editorial-overview-inner-child'>Submissions sent back to author</a><a href={`/${currentItem?.acronym?.toLowerCase()}${myManuscripts?._id}/confirmation`}  className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>fil.approved==="rejected"&&fil.submitted).length})`}</a>
                                </>
                                }
                        </div> */}
                        <div className='editorial-overview-inner-wrapper'>
                        {myManuscripts?.filter(fil=>fil.incompleteSubmission).length<1?
                                <>
                                <span className='editorial-overview-inner-child'>Incomplete submissions</span><span className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>fil.incompleteSubmission).length})`}</span>
                                </>:
                                <>
                                <a href={`/${currentItem?.acronym?.toLowerCase()}/incomplete-submission`}  className='editorial-overview-inner-child'>Incomplete submissions</a><a href={`/${currentItem?.acronym?.toLowerCase()}${myManuscripts?._id}/true/resume-submission`}  className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>!fil.submitted&&fil.incompleteSubmission).length})`}</a>
                                </>
                                }
                            </div>
                        <div className='editorial-overview-inner-wrapper'>
                            {myManuscripts?.filter(fil=>!fil.submitted&&fil.incompleteSubmission===false).length<1?
                                <>
                                <span className='editorial-overview-inner-child'>Submissions waiting for author's approval</span><span className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>!fil.submitted&&fil.incompleteSubmission===false).length})`}</span>
                                </>:
                                <>
                                <a href={`/${currentItem?.acronym?.toLowerCase()}/confirmation`}  className='editorial-overview-inner-child'>Submissions waiting for author's approval</a><a href={`/${currentItem?.acronym?.toLowerCase()}${myManuscripts?._id}/confirmation`}  className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>!fil.submitted&&fil.incompleteSubmission===false).length})`}</a>
                                </>
                                }
                        </div>
                        <div className='editorial-overview-inner-wrapper'>
                            {myManuscripts?.filter(fil=>fil.approved==="pending"&&fil.submitted).length<1?
                                <>
                                <span className='editorial-overview-inner-child'>Submissions being processed</span><span className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>fil.approved==="pending"&&fil.submitted).length})`}</span>
                                </>:
                                <>
                                <a href={`/${currentItem?.acronym?.toLowerCase()}/processed-manuscripts`}  className='editorial-overview-inner-child'>Submissions being processed</a><a href={`/${currentItem?.acronym?.toLowerCase()}${myManuscripts?._id}/processed-manuscripts`}  className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>fil.approved==="pending"&&fil.submitted).length})`}</a>
                                </>
                                }
                        </div>
                    </div>
                </div>
                <div className='editorial-overview-sub'>
                    <h1 className='editorial-overview-title'>Revisions</h1>
                    <div className='editorial-overview-inner'>
                        <div className='editorial-overview-inner-wrapper'><span className='editorial-overview-inner-child'>Submissions Needing Revisions</span><span className='editorial-overview-inner-value'>(0)</span></div>
                        <div className='editorial-overview-inner-wrapper'><span className='editorial-overview-inner-child'>Revisions Sent Back to Author</span><span className='editorial-overview-inner-value'>(0)</span></div>
                        <div className='editorial-overview-inner-wrapper'><span className='editorial-overview-inner-child'>Revisions Waiting for Author's Approval</span><span className='editorial-overview-inner-value'>(0)</span></div>
                        <div className='editorial-overview-inner-wrapper'><span className='editorial-overview-inner-child'>Declined Revisions</span><span className='editorial-overview-inner-value'>(0)</span></div>
                    </div>
                </div>
                <div className='editorial-overview-sub'>
                    <h1 className='editorial-overview-title'>Completed</h1>
                    <div className='editorial-overview-inner'>
                    <div className='editorial-overview-inner-wrapper'>
                            {myManuscripts?.filter(fil=>fil.submitted&&fil.incompleteSubmission===false&&fil.approved==='approved'&&!fil.published).length<1?
                                <>
                                <span className='editorial-overview-inner-child'>Submissions with a Decision</span><span className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>fil.submitted&&fil.incompleteSubmission===false&&fil.approved==='approved'&&!fil.published).length})`}</span>
                                </>:
                                <>
                                <a href={`/${currentItem?.acronym?.toLowerCase()}/decision-manuscripts`}  className='editorial-overview-inner-child'>Submissions with a Decision</a><a href={`/${currentItem?.acronym?.toLowerCase()}${myManuscripts?._id}/processed-manuscripts`}  className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>fil.submitted&&fil.incompleteSubmission===false&&fil.approved==='approved'&&!fil.published).length})`}</a>
                                </>
                                }
                        </div>
                    

                    <div className='editorial-overview-inner-wrapper'>
                            {myManuscripts?.filter(fil=>fil.submitted&&fil.incompleteSubmission===false&&fil.approved==='approved'&&fil.published).length<1?
                                <>
                                <span className='editorial-overview-inner-child'>Submissions with Production Completed</span><span className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>fil.submitted&&fil.incompleteSubmission===false&&fil.approved==='approved'&&fil.published).length})`}</span>
                                </>:
                                <>
                                <a href={`/${currentItem?.acronym?.toLowerCase()}/production-manuscripts`}  className='editorial-overview-inner-child'>Submissions with Production Completed</a><a href={`/${currentItem?.acronym?.toLowerCase()}${myManuscripts?._id}/processed-manuscripts`}  className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>fil.submitted&&fil.incompleteSubmission===false&&fil.approved==='approved'&&fil.published).length})`}</a>
                                </>
                                }
                        </div>
                        
                    {/* <div className='editorial-overview-inner-wrapper'>
                            {myManuscripts?.filter(fil=>fil.approved==="pending"&&fil.submitted).length<1?
                                <>
                                <span className='editorial-overview-inner-child'>Submissions with Production Completed</span><span className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>fil.approved==="approved"&&fil.submitted).length})`}</span>
                                </>:
                                <>
                                <a href={`/${currentItem?.acronym?.toLowerCase()}/completed-manuscripts`}  className='editorial-overview-inner-child'>Submissions with Production Completed</a><a href={`/${currentItem?.acronym?.toLowerCase()}${myManuscripts?._id}/processed-manuscripts`}  className='editorial-overview-inner-value'>{`(${myManuscripts?.filter(fil=>fil.approved==="approved"&&fil.submitted).length})`}</a>
                                </>
                                }
                        </div> */}
                    </div>
                </div>
            
                    </div>
        </div>
                    
                }
        

        </div>
    );
};

UserDashboard.propTypes = {};

export { UserDashboard };