import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './PublishersDashboard.css'
import { FaBars, FaTimes } from 'react-icons/fa';
import paypal from './images/paypal.png';
import {useSelector} from 'react-redux';
import { url2 } from '../Redux/Api';

const ManuscriptsDashboard = props => {
    const manuscripts = useSelector(state=>state.manuscripts?.items);
    const[paymentData, setPaymentData] = useState({modal:false,item:null});
    const[newState, setNewState] = useState({status:'',data:''});
    const[showModal, setShowModal] = useState(false)
    const[price, setPrice] = useState(0)
    const[value, setValue] = useState(null);

    const handleDelete = async(item)=>{
        async function fetchData(){
            await fetch(`${url2}/delete-manuscript/${item._id}`,{
                mode:'cors',
                method:'delete',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    setNewState({status:'success',data:resp.resp})
                    return window.location.reload()
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }
    const handleUnpublish = async(item)=>{
        async function fetchData(){
            await fetch(`${url2}/unpublish-manuscript/${item._id}`,{
                mode:'cors',
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    setNewState({status:'success',data:resp.resp})
                    return window.location.reload()
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }
    const handlePublish = async(item)=>{
        async function fetchData(){
            await fetch(`${url2}/publish-manuscript/${item._id}`,{
                mode:'cors',
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                     setNewState({status:'success',data:resp.resp})
                    return window.location.reload()
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }

    const approve = async(item)=>{
        async function fetchData(){
            await fetch(`${url2}/approve-manuscript/${value._id}/${price}`,{
                mode:'cors',
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                     setNewState({status:'success',data:resp.resp})
                    return window.location.reload()
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }
    const disApprove = async(item)=>{
        async function fetchData(){
            await fetch(`${url2}/disapprove-manuscript/${item._id}`,{
                mode:'cors',
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                }
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                     setNewState({status:'success',data:resp.resp});
                    return window.location.reload();
                }else if(resp.err){
                    return setNewState({status:'failed',data:resp.err})
                }else{
                    return setNewState({status:'failed',data:'Something went wrong.'})
                }
            }).catch(err=>setNewState({status:'failed',data:err.message}))
        }
        fetchData()
    }

    const[publisher, setPublisher] = useState(null);
    const [paypalData, setPaypalData] = useState({paymentStatus:'failed',amount:0,date:new Date()})
    const user = JSON.parse(localStorage.getItem('user'));

    const makePayment = async(e)=>{
       if(paypalData.paymentStatus==='success'&&paypalData.amount>=e.price){
       await fetch(`${url2}/make-payment`,{
        mode:'cors',
            method:'post',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
            },body:JSON.stringify({amount:paypalData.amount,date:paypalData.date,item:paymentData?.item})
        }).then(res=>res.json())
        .then(resp=>{
            if(resp.resp){
                setNewState({status:'success',data:resp.resp})
                return window.location.reload();
            }else if(resp.err){
                return setNewState({status:'failed',data:resp.err})
            }else{
                return setNewState({status:'failed',data:'Soemthing went wrong. Try again.'})
            }
        }).catch(err=>{
            return setNewState({status:'failed',data:err.message})
        })
       }else{

       }
}

    return <div className='admin-dashboard-child-container'>
        <span className={newState.status==='success'?'publisher-detail-success':'publisher-detail-error'}>{newState.data}</span>
{
    publisher?
    <div className={paymentData?.modal?'publisher-detail-hidden':'publisher-detail'}>
    <div className='publishers-main-dashboard-detail'>
                    <div className='author-container-dashboard'>
                    <h1 className={publisher?.approved==='approved'?'approved':publisher?.approved==='pending'?'pending':'rejected'}>
                    {publisher?.approved==='approved'?'Approved':publisher?.approved==='pending'?'Pending':publisher?.approved==='rejected'?'Rejected':''}
                    </h1>
                    <button className={publisher?.approved==='approved'?'approved-btn':publisher?.approved==='pending'?'pending-btn':'rejected-btn'}>
                    {publisher?.submitted&&publisher?.approved==='approved'?'Cancel':publisher?.submitted&&publisher?.approved==='pending'||publisher?.submitted&&publisher?.approved==='rejected'?'Approve':'Not submitted'}
                    </button>
                    {

                    }
                    </div>
                    <div className='book-container-dashboard'>
                    <p className='book-info'></p>
                    <p className='book-info'>Type {publisher?.type}</p> 
                    <p className='book-info'>comment {publisher?.comment}</p>
                    <h1 className={publisher?.paid==='approved'?'approved':'pending'}>{publisher?.paid&&publisher?.paid}</h1>
                    <h1 className='author-city-dashboard'>Gen. Info{publisher?.generalInformation}</h1>
                    <FaTimes className='icon' onClick={()=>setPublisher(null)}/>
                    <button className='action-btn' onClick={()=>handleDelete(publisher)}>Delete</button>
                    </div>
                </div>
</div>:
<div className={paymentData?.modal?'publishers-main-hidden':'publishers-main'}>
    <h2 className='publishers-page-title'>{manuscripts?.length}{manuscripts?.length>1?' Manuscripts':' Manuscript'}</h2>
    <div className='add-new-book-container'>
    </div>
    {
                        showModal?<form className='admin-manuscript-approval'>
                                                <input type='number' step='any' min={0} placeholder='Price' className='add-book-input' onChange={e=>setPrice(e.target.value)}/>

                        <div className='admin-manuscript-approval-btn-group'>
                        <button className='action-btn' onClick={()=>setShowModal(false)}>Cancel</button>
                        <button type='submit' className='add-book-btn' onClick={()=>approve(value)}>Confirm</button>
                        </div>
                    </form>:<div className='main-items-container'>
    {
        manuscripts?.length>0&&manuscripts?.map((item,index)=>{
            return(
                <div className='publishers-main-dashboard' key={index}>
                    <div className='author-container-dashboard'>
                    <h1 className='author-title-dashboard'> {item.acronym}</h1>
                    <h1 className='author-title-dashboard'> ${item.price}</h1>
                    <h1 className={item.approved==='approved'?'approved':item.approved==='pending'?'pending':'rejected'}>
                    {item.approved==='approved'?'Approved':item.approved==='pending'?'Pending':item.approved==='rejected'?'Rejected':''}
                    </h1>
                    <h1 className={item.published?'approved':'pending'}>
                    {item.published?'Published':'Not yet published'}
                    </h1>
                    </div>
                   
                    <div className='book-container-dashboard'>
                    {
                        item?.submitted&&item?.approved==='approved'&&item.published?<button className='action-btn' onClick={()=>handleUnpublish(item)}>Unpublish</button>:
                        !item?.submitted?<button  onClick={()=>handlePublish(item)} className='action-btn'>Not Submitted</button>:
                        <button  onClick={()=>handlePublish(item)} className='action-btn'>Publish</button>
                    }
                    {
                        item?.submitted&&item.approved==='pending'||item?.submitted&&item.approved==='rejected'?

                        <button className='action-btn'  onClick={()=>{setShowModal(true);setValue(item)}}>Approve</button>
                        
                        :
                        item?.submitted&&item.approved==='approved'?
                        <button  onClick={()=>disApprove(item)} className='action-btn'>Reject</button>:<button className='action-btn'>Not Submitted</button>
                    }
                    {
                        user?.isAdmin&&item.submitted&&
                        <button className='action-btn' onClick={()=>setPaymentData({modal:true,item:item})}>Publish as admin</button>
                    }
                    {
                        !item.paid&&item.author===user?._id&&
                        <button className='action-btn' onClick={()=>setPaymentData({modal:true,item:item})}>Publish now</button>
                    }
                    
                    <FaBars className='icon' onClick={()=>setPublisher(item)}/>
                    </div>
                </div>

            )
        })
    }
    </div>}
    </div>
}
<div className={paymentData?.modal?'payment-modal':'payment-modal-hidden'}>
<img src={paypal} className='paypal-img'/>
<div className='payment-inner-container'>
<span>Amount ${paymentData?.item?.price}</span>
{
                        !paymentData?.item?.paid&&user?.isAdmin&&
                        <button className='action-btn' onClick={()=>setPaypalData({paymentStatus:'success',amount:paymentData?.item?.price,date:paypalData.date})}>Manually Activate</button>
}
<button onClick={()=>makePayment(paymentData?.item)} className='action-btn'>Pay with paypal</button>
<FaTimes onClick={()=>setPaymentData({modal:false,item:null})} />
</div>
    </div>
    </div>

};

ManuscriptsDashboard.propTypes = {};

export { ManuscriptsDashboard };