import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './StepThree.css'
import siteInfo from '../../../shopping/images/springer-ecomm-header-logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { url, url2 } from '../../../Redux/Api';
import { FaAngellist, FaSpinner } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { EditorialHeader } from './EditorialHeader';


const StepFive = props => {
const[firstName, setFirstName] = useState('')
const[lastName, setLastName] = useState('')
const[email, setEmail] = useState('')
const[sent, setSent] = useState(false)
const[logger, setLogger] = useState('');
const[error, setError] = useState({err:'',hint:''});
const[captured, setCaptured] = useState('')
const[lastMailTried, setLastMailTried] = useState('')
const[loading, setLoading] = useState(false)
const[passwordReset, setPasswordReset] = useState('')
const[newState, setNewState] = useState({status:'',data:''});
const currentItem = useSelector(state=>state.journal.journal);
const[tokenValue, setTokenValue] = useState('')
const[mail, setMail] = useState('')

const navigate = useNavigate()
//     const handlePreRegistration = async(e)=>{
//         e.preventDefault()
//         setLoading(true)
//         fetch(`${url2}/create-account`,{
//             mode:'cors',
//             method:'post',
//             headers:{
//                 'Content-Type':'application/json'
//             },
//             body:JSON.stringify({
//                 email:regState?.email,pub:currentItem?.pub
//             })
//         }).then(res=>res.json())
//         .then((data)=>{
//             if(data.mailData){
//                 setNewState({status:'success',data:`Kindly proceed to your email to complete the process.`});
//               setSent(true)
//             }else if(data.err){
//                 setNewState({status:'failed',data:data.err});
// setInterval(() => {
//     setNewState({status:'',data:''}) 
// }, 10000);
//                 setError(data.err)
//                 setLoading(false)
//             }
//         }).catch(err=>{
//           setLoading(false)
//           setNewState({status:'failed',data:err.message})
// setInterval(() => {
//     setNewState({status:'',data:''}) 
// }, 10000);
//         })
//     }
    const token = ()=>{
        setTokenValue(setTimeout(()=>{
            Math.floor(Math.random() * 300)
        },3000))
    }
    const handlePasswordReset = async(e)=>{
    e.preventDefault()
    setSent(true)
    token()
    setLoading(true)
   await fetch(`${url2}/password-reset`,{
    mode:'cors',
        method:'post',
        headers:{
            'Content-Type':'application/json'
        },
        body:JSON.stringify({
            email,acronym:currentItem?.acronym
        })
    }).then(res=>res.json())
    .then((resp)=>{
        if(resp.mailData){
            setMail(resp.mailData)
            setLoading(false)
            setNewState({status:'success',data:`Complete this process by clicking the link sent to ${email}.`})
        }else if(resp.err){
            setNewState({status:'failed',data:resp.err})
            setInterval(() => {
                setNewState({status:'',data:''}) 
            }, 3000);
            setLoading(false)
        }
    }).catch(err=>{
        setNewState({status:'failed',data:err.message})
        setInterval(() => {
            setNewState({status:'',data:''}) 
        }, 3000);
        setLoading(false)
        clearInterval()
    })

    }

const regState = JSON.parse(localStorage.getItem('regState'))


const handleUserUpdate = async (e)=>{
    setLoading(true)
    await fetch(`${url2}/update-user-detail`,{
        mode:'cors',
        method:'put',
        headers:{
            'Content-Type':'application/json',
        },
        body:JSON.stringify(
            {regState})
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            return navigate(`/${currentItem?.acronym?.toLowerCase()}/default.aspx/login`);
        }else if(resp.err){
            setLoading(false)
            newState({status:'failed',data:resp.err})
            return setError({err:resp.err,hint:resp.err})
        }
    }).catch(err=>{setLoading(false);setError({err:err.message})})
}
const[accepted, setAccepted] = useState(false)
return(
    <>
        <div className='editorial-register-main'>
        <EditorialHeader/>

{
    passwordReset?
    <div className='editorial-step-three-site-body'>
    <div className='editorial-register-site-body-left'>
        {
            newState.data.length>0?
            <p className={newState.status==='success'?'success-message':'error-message'}>{newState.data}</p>:lastMailTried.length>0?<p className={newState.status==='success'?'success-message':'error-message'}>{lastMailTried}</p>:''
        }
   <p className='editorial-register-site-body-left-content'>
   {newState.data}
   </p>
    </div>
    <div className='editorial-step-three-form'>
    <div className='choose-register-method-top'>
    <span className='pre-register-text-method-top'>Insert special character</span>
    </div>
    <div className='choose-register-method'>
    <span className='pre-register-text-method'>Choose a Registration Method</span>
    </div>
    <div className='step-three-input-inner-wrapper'>
    <div className='step-three-input-and-text-wrapper'>
    <div className='step-three-input-wrapper'><div className='step-three-text-input-wrapper'><span className='step-three-required-text'>E-mail Address*</span></div><input type='email' onChange={(e)=>setEmail(e.target.value)} className='pre-editorial-login-input' /></div>
    </div>
    <div className='step-three-btn-container'>
    {
        lastMailTried.length>0?<button onClick={()=>navigate(`${currentItem?.acronym?.toLowerCase()}/default.aspx/login`)} className='step-three-form-btn' >Login instead</button>:<button className='step-three-form-btn' onClick={handlePasswordReset}><span>Reset password</span> <span>{'>>'}</span></button>
    }
    </div>
    </div>
</div>
<div className='editorial-register-site-body-right'></div>
</div>
    :
    <div className='editorial-step-three-site-body'>
    <div className='editorial-step-three-form' >
    <div className='choose-register-method'>
    
    <span className='pre-register-text-method'>Please confirm the following very important information</span>
    </div>
    <div className='step-three-input-inner-wrapper'>
    <div className='step-three-input-and-text-wrapper'>
    {
            newState.data.length>0&&
            <p className={newState.status==='success'?'success-message':'error-message'}>{newState.data}</p>
        }
    <div className='step-three-input-wrapper'><div className='step-three-text-input-wrapper'><span className='step-three-required-text'>Given/First Name:</span></div><div className='step-three-right-wrapper'><span className='step-three-input-value'>{regState?.firstName}</span></div></div>
    <div className='step-three-input-wrapper'><div className='step-three-text-input-wrapper'><span className='step-three-required-text'>Family/Last Name:</span></div><div className='step-three-right-wrapper'><span className='step-three-input-value'>{regState?.lastName}</span></div></div>
    <div className='step-three-input-wrapper'><div className='step-three-text-input-wrapper'><span className='step-three-required-text'>Username:</span></div><div className='step-three-right-wrapper'><span className='step-three-input-value'>{regState?.userName}</span></div></div>
    <div className='step-three-input-wrapper'><div className='step-three-text-input-wrapper'><span className='step-three-required-text'>E-mail Address:</span></div><div className='step-three-right-wrapper'><span className='step-three-input-value'>{regState?.email}</span></div></div>
    <div className='step-three-input-wrapper'><div className='step-three-text-input-wrapper'><span className='step-three-required-text'>Country or Region:</span></div><div className='step-three-right-wrapper'><span className='step-three-input-value'>{regState?.country?.label}</span></div></div>
    </div>
    <div className='step-three-btn-container'>
    </div>
    </div>
<hr style={{color:'red'}} />
<div className='step-three-info-wrapper'>
<p className='step-three-info'>Please click on the privacy policy links below and then check the box.</p>
    <div  className='step-three-btn-radio-wrapper'>
    <div className='step-three-btn-radio-inner-wrapper'>
    <input type='radio' id='accept' onClick={()=>setAccepted(!accepted)}/>
    <span style={{color:'var(--red-color)',marginLeft:'10px'}}>*</span>
    </div>
    <p className='step-three-info'>I acknowledge that my personal information will be accessed, used and otherwise pocessed in accordance with <a>Publisher's Data Use Privacy Policy</a> and the <a>Aries Privacy Policy.</a></p>
    </div>
    <p className='step-three-info'>If the information is correct and you wish to complete this registration, click the 'Continue' button below.</p>
</div>
<hr style={{color:'red'}} />
<div className='step-three-btn-group'>
    <button  className='step-three-form-btn' onClick={()=>navigate(-1)}>{'<<'}Previous Page</button>
    {loading?<FaSpinner/>:accepted?
<button  className='step-three-form-btn' onClick={handleUserUpdate}>Continue {'>>'}</button>:
<button  className='step-three-form-btn-disabled' disabled>Continue {'>>'}</button>
    }
</div>
</div>
</div>
}
    </div>
    </>
   
    );
};

StepFive.propTypes = {};

export { StepFive };