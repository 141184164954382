import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './EditorialPreRegister.css'
import siteInfo from '../../../shopping/images/springer-ecomm-header-logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { url2 } from '../../../Redux/Api';
import { useSelector } from 'react-redux';
import { EditorialHeader } from './EditorialHeader';


const EditorialPreRegister = props => {
    const navigate = useNavigate()
    const[firstName, setFirstName] = useState('')
const[lastName, setLastName] = useState('')
const[email, setEmail] = useState('')
const[sent, setSent] = useState(false)
const[captured, setCaptured] = useState('')
const[lastMailTried, setLastMailTried] = useState('')
const[isLoading, setIsLoading] = useState(false)
const[passwordReset, setPasswordReset] = useState('')
const[newState, setNewState] = useState({status:'',data:''});
const[tokenValue, setTokenValue] = useState('');
const[mail, setMail] = useState('')
const{acronym}= useParams();
const currentItem = useSelector(state=>state.journal.journal)
    const handlePreRegistration = async(e)=>{
        e.preventDefault()
        setIsLoading(true)
                await fetch(`${url2}/check-email`,{
            mode:'cors',
            method:'post',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                email,pub:currentItem?.pub
            })
        }).then(res=>res.json())
        .then(data=>{
            if(data.resp){
                localStorage.setItem('regState',JSON.stringify({email,pub:currentItem?.pub,firstName,lastName}))
                return navigate(`/${acronym.toLowerCase()}/default.aspx/${email}/register`)
            }else if(data.err.toString()===email.toString()){
              setLastMailTried(data.err.toString())
              setIsLoading(false)
              setCaptured(data.err.toString())
            }else if(data.err&&data.err.toString()!==email.toString()){
                setIsLoading(false)
                return setNewState({status:'failed',data:data.err});
          }else{
            setIsLoading(false)
            return setNewState({status:'failed',data:'Something went FaWatchmanMonitoring, please try again.'});
          }
        }).catch(err=>{
            setNewState({status:'failed',data:err.message})
            setIsLoading(false)
        })
    }

    const token = ()=>{
        setTokenValue(setTimeout(()=>{
            Math.floor(Math.random() * 300)
        },3000))
    }
    const handlePasswordReset = async(e)=>{
    e.preventDefault()
    setSent(true)
    token()
    setIsLoading(true)
   await fetch(`${url2}/password-reset`,{
    mode:'cors',
        method:'post',
        headers:{
            'Content-Type':'application/json'
        },
        body:JSON.stringify({
            email
        })
    }).then(res=>res.json())
    .then((resp)=>{
        if(resp.mailData){
            setMail(resp.mailData)
            setIsLoading(false)
            setNewState({status:'success',data:`Complete this process by clicking the link sent to ${email}.`})
        }else if(resp.err){
            setNewState({status:'failed',data:resp.err})
            setIsLoading(false)
        }
    }).catch(err=>{
        setNewState({status:'failed',data:err.message})
        setIsLoading(false)
    })

    }
return(<>
        <div className='editorial-register-main'>
        <EditorialHeader/>
{
    passwordReset?
    <div className='editorial-pre-register-site-body'>
    <div className='editorial-preregister-site-body-left'>
        {
            newState?.data?.length>0?
            <p className={newState?.status==='success'?'success-message':'error-message'}>{newState?.data}</p>:lastMailTried.length>0&&lastMailTried.toString()===email.toString()?<p className={newState?.status==='success'?'success-message':'error-message'}>{lastMailTried}</p>:''
        }
   <p className='editorial-register-site-body-left-content'>
   {newState?.data}
   </p>
    </div>
    <div className='editorial-pre-register-form'>
    <div className='choose-register-method-top'>
    <span className='pre-register-text-method-top'>Insert special character</span>
    </div>
    <div className='choose-register-method'>
    <span className='pre-register-text-method'>Choose a Registration Method</span>
    </div>
    <div className='pre-register-input-inner-wrapper'>
    <div className='pre-register-input-and-text-wrapper'>
    <div className='pre-register-input-wrapper'><div className='pre-register-text-input-wrapper'><span className='pre-register-required-text'>E-mail Address*</span></div><input type='email' onChange={(e)=>setEmail(e.target.value)} className='pre-editorial-login-input' /></div>
    </div>
    <div className='pre-register-btn-container'>
    {
        lastMailTried.length>0&&lastMailTried.toString()===email.toString()?<button onClick={()=>navigate(`/${currentItem?.acronym}/default.aspx/login`)} className='pre-register-form-btn' >Login instead</button>:<button  className='pre-register-form-btn' onClick={handlePasswordReset}><span>Reset password</span> <span>{'>>'}</span></button>
    }
    </div>
    </div>
</div>
{/* <div className='editorial-register-site-body-right'></div> */}
</div>
    :
    <div className='editorial-pre-register-site-body'>
    <div className='editorial-register-site-body-left'>
        {
            newState?.data?.length>0?
            <p className={newState?.status==='success'?'success-message':'error-message'}>{newState?.data}</p>:lastMailTried.length>0&&lastMailTried?.toString()===email.toString()?<p className={newState?.status==='success'?'success-message':'error-message'}>{`${lastMailTried} is not available.`}</p>:''
        }
   <h1 className='editorial-register-site-body-left-title'>Pre-registration Page</h1>
   <p className='editorial-register-site-body-left-content'>
   To register to use the Editorial Manager system, please enter the requested information. Upon successful registration, you will be sent an e-mail with instructions to verify your registration.
   </p>
    </div>
    <div className='editorial-pre-register-form'>
    <div className='choose-register-method-top'>
    <span className='pre-register-text-method-top'>Insert special character</span>
    </div>
    <div className='choose-register-method'>
    <span className='pre-register-text-method'>Choose a Registration Method</span>
    </div>
    {/* <span className='pre-register-text-caption'>Retrieve your details from the ORCID registry:</span>
    <button  className='pre-register-form-btn'>
        <div className='pre-register-form-icon-wrapper'>
            <span className='pre-register-form-icon-i'>i</span>
            <span className='pre-register-form-icon-i'>D</span>
        </div>
        <span>Use my ORCID record</span></button>
    <span className='pre-register-text-caption'>Or type in your details and continue to register without using ORCID:</span> */}
    <div className='pre-register-input-inner-wrapper'>
    <div className='pre-register-input-and-text-wrapper'>
    <div className='pre-register-input-wrapper'><div className='pre-register-text-input-wrapper'><span className='pre-register-required-text'>Given/First Name*</span></div><input type='text' onChange={(e)=>setFirstName(e.target.value)} className='pre-editorial-login-input' /></div>
    <div className='pre-register-input-wrapper'><div className='pre-register-text-input-wrapper'><span className='pre-register-required-text'>Family/Last Name*</span></div><input type='text' onChange={(e)=>setLastName(e.target.value)} className='pre-editorial-login-input' /></div>
    <div className='pre-register-input-wrapper'><div className='pre-register-text-input-wrapper'><span className='pre-register-required-text'>E-mail Address*</span></div><input type='email' onChange={(e)=>setEmail(e.target.value)} className='pre-editorial-login-input' /></div>
    </div>
    <div className='pre-register-btn-container'>
    {
        lastMailTried.length>0&&lastMailTried?.toString()===email.toString()?<button onClick={()=>navigate('/login')} className='pre-register-form-btn' >Login instead</button>:<button  className='pre-register-form-btn' onClick={handlePreRegistration}>Continue{'>>'}</button>
    }
    </div>
    </div>
<hr style={{color:'red'}} />
<div className='pre-register-info-wrapper'>
    <p className='pre-register-info'>WARNING - If you think you already have an existing registration of any type (Author, Reviewer, or Editor) in this system, please DO NOT register again. This will cause delays or prevent the processing of any review or manuscript you submit. If you are unsure if you are already registered, click the 'Forgot Your Login Details?' button.</p>
    <p className='pre-register-info'>If you are registering again because you want to change your current information, changes must be made to your existing information by clicking the 'Update My Information' link on the menu bar. If you are unsure how to perform these functions, please contact the editorial office.</p>
</div>
<hr style={{color:'red'}} />
<div className='pre-register-btn-group'>
    <button  className='pre-register-form-btn'>Cancel</button>
    <button  className='pre-register-form-btn' onClick={()=>navigate(`/${currentItem?.acronym?.toLowerCase()}/l.asp`)}>Forgot your login details?</button>
</div>
</div>
<div className='editorial-register-site-body-right'></div>
</div>
}
    </div>
</>
    
    );
};

EditorialPreRegister.propTypes = {};

export { EditorialPreRegister };